import React, { useRef, useState } from 'react';
import { useMutation } from 'react-query';
import Loading from '../Loading';
import styled from 'styled-components';
import { createHands } from '@/api/query/hands';
import PopUp from '../PopUp';

import Like from '@/common/icons/like.svg';
import LikeFill from '@/common/icons/like-fill.svg';
import Dislike from '@/common/icons/dislike.svg';
import DislikeFill from '@/common/icons/dislike-fill.svg';
import { useLanguageData } from '@/common/hooks/useLanguageData';

export const HAND_DECISION = {
  UP: 'HAND_UP',
  DOWN: 'HAND_DOWN',
};

const Text2 = styled.p`
  color: #868686;
  font-family: Work Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 123.5%; /* 17.29px */
  letter-spacing: 0.28px;
`;

const Wrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  min-width: 200px;

  @media (max-width: 600px) {
    margin-top: -10px;
  }
`;

export const SOURCES_TYPES = {
  DAILY: 'DAILY',
  DAILY_V2: 'DAILY_V2',
  SCENE: 'SCENE',
  WORD: 'WORD',
  FLASH_CARD: 'FLASH_CARD',
  FLASH_CARD_NEW: 'FLASH_CARD_NEW',
  FLASH_CARD_V2: 'FLASH_CARD_V2',
  FOTO_MODE: 'FOTO_MODE',
  CALL_MODE: 'CALL_MODE',
};

const Skip = styled.button`
  color: #29185e;
  text-align: center;
  font-family: 'Work Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 19.6px */
  text-decoration-line: underline;
  background-color: white;

  cursor: pointer;
`;

const Send = styled.button`
  color: #fff;

  text-align: center;
  font-family: Work Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.8px;
  text-transform: uppercase;
  padding: 17px 74px 14px 75px;
  border-radius: 100px;
  background: var(
    --Purple,
    linear-gradient(91deg, #4f17aa 1.48%, #ac02f1 99.24%)
  );
  cursor: pointer;
  @media (max-width: 600px) {
    width: 200px;
    padding: 15px 35px 15px 35px;
  }
`;

const P = styled.p`
  color: #29185e;
  text-align: center;
  font-family: 'Work Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 19.6px */
  margin: 0;
  margin-top: 50px;
`;

const TitlePopUp = styled.h2`
  color: #29185e;
  text-align: center;
  font-family: 'Work Sans';
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 28px */
  margin: 0;

  @media (max-width: 600px) {
    max-width: 280px;
  }
`;

const SubTitlePopUp = styled.h3`
  color: #29185e;
  text-align: center;
  font-family: 'Work Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 19.6px */
  margin: 0;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin-top: 50px;

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
  }
`;

const TextArea = styled.textarea`
  width: 700px;
  height: 92px;
  padding: 16px 18px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  background: #eef2f5;
  outline: none;
  border: none;
  resize: none;

  color: #05275d;

  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 123.5%;

  @media (max-width: 600px) {
    width: 300px;
    height: 140px;
    padding: 5px;
  }
`;

const LetterCounterWrapper = styled.p`
  text-align: right;
  color: #868686;
  font-family: Work Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  margin: 0;

  @media (max-width: 600px) {
    width: 100%;
    justify-content: right;
  }
`;

const PopUpWrapper = styled.form`
  padding: 50px;

  @media (max-width: 600px) {
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const Hands = ({ type, forWhat }) => {
  const [decision, setDecision] = useState();
  const [showPopUp, setShowPopUp] = useState();
  const [lengthCounter, setLengthCounter] = useState(0);
  const { giveUsInfo, thanksForGrading, subtitle, subtitle2, skip, send } =
    useLanguageData('hands');

  const messegeRef = useRef();

  const handleAddHandMutation = useMutation({
    mutationFn: (payload) => createHands(payload),
  });

  const handleAddHand = (e) => {
    e?.preventDefault();

    const payload = {};
    setDecision(() => showPopUp);

    payload.decision = showPopUp;
    payload.type = type;
    payload.for = forWhat;
    payload.message = messegeRef?.current?.value || null;

    if (showPopUp === HAND_DECISION.UP) {
      payload.window_size = `${window?.innerWidth}x${window?.innerHeight}`;
      payload.device_description = window?.navigator?.userAgent;
    }

    handleAddHandMutation.mutate(payload);
    setShowPopUp(null);
  };

  const handleChange = (e) => {
    const message = messegeRef.current.value;

    if (message.length > 300) {
      return (messegeRef.current.value = message.slice(0, 300));
    }

    setLengthCounter(() => message.length);
  };

  return (
    <>
      {handleAddHandMutation.isLoading && <Loading />}
      <Wrapper>
        <Text2>{giveUsInfo}</Text2>
        <img
          src={decision === HAND_DECISION.UP ? LikeFill : Like}
          onClick={() => setShowPopUp(HAND_DECISION.UP)}
          style={{ width: '20px', cursor: 'pointer' }}
        />
        <img
          src={decision === HAND_DECISION.DOWN ? DislikeFill : Dislike}
          onClick={() => setShowPopUp(HAND_DECISION.DOWN)}
          style={{ width: '20px', cursor: 'pointer' }}
        />
      </Wrapper>
      {showPopUp && (
        <PopUp closeIcon={false}>
          <PopUpWrapper onSubmit={handleAddHand}>
            <TitlePopUp>{thanksForGrading}</TitlePopUp>{' '}
            <SubTitlePopUp>{subtitle}</SubTitlePopUp>
            <P>{subtitle2}</P>
            <TextArea
              ref={messegeRef}
              onChange={(e) => handleChange(e)}
              required={true}
            />
            <LetterCounterWrapper>{lengthCounter} / 300</LetterCounterWrapper>
            <ButtonsWrapper>
              <Skip type="button" onClick={() => handleAddHand()}>
                {skip}
              </Skip>
              <Send type="submit">{send}</Send>
            </ButtonsWrapper>
          </PopUpWrapper>
        </PopUp>
      )}
    </>
  );
};

export default Hands;
