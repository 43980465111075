import Android1 from './images/Android_1_150_ES.png';
import Android2 from './images/Android_Dodaj_150_ES.png';
import Ios1 from './images/IOS_1_150_ES.png';
import Ios2 from './images/IOS_Dodaj_150_ES.png';
import Last from './images/ios_android_3.png';

import VolumeUp from './images/dailyTutorial/volume-up.svg';
import HandsUp from './images/dailyTutorial/hand-up.svg';
import VolumeUpBlank from './images/dailyTutorial/volume-up-blank.svg';
import TranslationBlank from './images/dailyTutorial/icon-translate-blank.svg';
import HintBlank from './images/dailyTutorial/idea-blank.svg';
import InfoBlank from './images/dailyTutorial/information-blank.svg';
import GreenCircle from './images/dailyTutorial/green-circle.svg';
import YellowCircle from './images/dailyTutorial/yelow-circle.png';
import Microphone from './images/dailyTutorial/icon-microphone.svg';
import ScreenOne from './images/dailyTutorial/samouczek_czat_1.png';
import ScreenTwo from './images/dailyTutorial/samouczek_czat_2.png';
import ScreenThree from './images/dailyTutorial/samouczek_czat_3.png';
import ScreenFour from './images/dailyTutorial/samouczek_czat_4.png';
import Tutorial from './images/dailyTutorial/samouczek-fiolet.svg';

export default {
  passwordRestart: {
    title: 'Επαναφορά κωδικού πρόσβασης',
    password: 'Κωδικός πρόσβασης',
    repeatPassword: 'Επανάληψη κωδικού πρόσβασης',
    restartPawssord: 'Επαναφορά κωδικού πρόσβασης',
  },

  paywall: {
    title: 'Επίλεξε ένα πλάνο για να συνεχίσεις την εκμάθηση',
    buy: 'Αγορά',
    savePayment: 'Ασφαλείς πληρωμές διαχειρίζονται από',
    skip: 'Παράλειψη',
    pickPlan: 'Επίλεξε πλάνο',
    endPlanFirstPart: 'Η πρόσβασή σου θα λήξει σε',
    endPlanSecondPart: 'ημέρες.',
    endPlanAditionalPart: 'ημέρα.',
    paymentTitle: 'Περίληψη',
    product: 'Προϊόν:',
    plan: 'SynthiAi Πακέτο',
    forLong: 'Πρόσβαση για',
    data: 'δεδομένα:',
    nameAndSurname: 'Όνομα και Επώνυμο:',
    paymentForm: 'Τρόπος Πληρωμής:',
    toPay: 'Προς πληρωμή σήμερα:',
    accept: 'Αποδέχομαι',
    rules: 'τους όρους',
    payingAccept: 'Πληρώνοντας αποδέχεστε',
    paymentRules: '"Όρους πληρωμής της PayU"',
    buyAndPay: 'ΑΓΟΡΑ ΚΑΙ ΠΛΗΡΩΜΗ',
  },
  paymentStatus: {
    veryfication: 'Η πληρωμή βρίσκεται υπό επεξεργασία...',
    title: 'Η πληρωμή ολοκληρώθηκε',
    success: 'Επιτυχής!',
    failure: 'Αποτυχημένη!',
    subtitle: 'Σύντομα θα ανακατευθυνθείς στη σελίδα σύνδεσης.',
    textInfo: `Η πληρωμή σας ολοκληρώθηκε με επιτυχία. Μπορείτε τώρα να ρυθμίσετε τον κωδικό πρόσβασής σας μεταβαίνοντας στη σελίδα εγγραφής μας. Μέσα σε λίγα λεπτά θα λάβετε ένα email με έναν σύνδεσμο για να ρυθμίσετε τον κωδικό σας.
Παρακαλούμε ελέγξτε τα εισερχόμενα και το φάκελο ανεπιθύμητης αλληλογραφίας. Για να διευκολύνετε την αναζήτηση, πληκτρολογήστε "synthiai" στο πεδίο αναζήτησης. Σε περίπτωση προβλήματος, επικοινωνήστε μαζί μας στη διεύθυνση: contact@synthiaiapp.com`,
    login: 'Μετάβαση στη σύνδεση',
  },
  register: {
    steps: {
      register: 'Εγγραφή',
      package: 'Πακέτο',
      summarize: 'Σύνοψη',
      step: 'Βήμα ',
    },
    stepOne: {
      password: 'Κωδικός πρόσβασης',
      errorGoogle: 'Συνδεθείτε με το email σας',
      title: 'Εγγραφή',
      nameAndSurname: 'Όνομα και Επώνυμο',
      placeholderNameAndSurname: 'XXXXXXXXXXX',
      email: 'Ηλεκτρονικό ταχυδρομείο',
      placeholderEmail: 'π.χ. παράδειγμα@synthiaiapp.com',
      password: 'Κωδικός πρόσβασης',
      start: 'ΔΗΜΙΟΥΡΓΗΣΕ ΛΟΓΑΡΙΑΣΜΟ',
      subtitle:
        'Ο κωδικός πρόσβασης πρέπει να έχει τουλάχιστον 8 χαρακτήρες, συμπεριλαμβανομένων πεζών και κεφαλαίων γραμμάτων, καθώς και έναν αριθμό.',
      passwordError1:
        'Ο κωδικός πρόσβασης πρέπει να περιέχει τουλάχιστον 8 χαρακτήρες.',
      passwordError2:
        'Ο κωδικός πρόσβασης πρέπει να περιέχει τουλάχιστον 1 κεφαλαίο γράμμα.',
      passwordError3:
        'Ο κωδικός πρόσβασης πρέπει να περιέχει τουλάχιστον 1 πεζό γράμμα.',
      passwordError4:
        'Ο κωδικός πρόσβασης πρέπει να περιέχει τουλάχιστον 1 αριθμό.',
      passwordError5: 'Οι κωδικοί πρόσβασης πρέπει να ταιριάζουν.',
    },
    stepTwo: {
      title: 'Επίλεξε το πλάνο σου',
      month: 'μήνας',
      months: 'μήνες',
      months2: 'μήνες',
      value: '€ / μήνα',
      pick: 'επίλεξε',
      nowPay: 'Άμεση πληρωμή',
      saveOne: 'Αποθήκευση',
      buy: 'Αγορά',
      savePayment: 'Ασφαλείς πληρωμές διαχειρίζονται από',
    },
    stepThree: {
      title: 'Σύνοψη',
      product: 'Προϊόν:',
      plan: 'Πακέτο Synthi AI',
      forLong: 'Πρόσβαση για',
      data: 'ημερομηνία:',
      nameAndSurname: 'Όνομα και Επώνυμο:',
      paymentForm: 'Μέθοδος πληρωμής:',
      toPay: 'Πληρωμή:',
      accept: 'Αποδοχή',
      rules: 'όροι και προϋποθέσεις',
      payingAccept: 'Πληρώνοντας αποδέχεσαι τους',
      paymentRules: '"Όρους πληρωμής PayU"',
      buyAndPay: 'ΑΓΟΡΑ ΚΑΙ ΠΛΗΡΩΜΗ',
    },
  },
  succesPayment: {
    title: 'Η πληρωμή ήταν επιτυχής!',
    subtitle: 'Σύντομα θα ανακατευθυνθείς στη σελίδα σύνδεσης.',
  },
  firstLogin: {
    createPasswordForAcoount:
      'Δημιουργήστε έναν κωδικό πρόσβασης για τον λογαριασμό σας',
    typeEmail: 'Εισαγάγετε τη διεύθυνση email σας',
    useSameEmail: 'Χρησιμοποιήστε την ίδια διεύθυνση που δώσατε κατά την αγορά',
    typePassword: 'Εισαγάγετε τον κωδικό πρόσβασής σας',
    confirmPassword: 'Επιβεβαιώστε τον κωδικό πρόσβασής σας',
    createPassword: 'Δημιουργία κωδικού πρόσβασης',
    errorLogin: 'Ελέγξτε αν ο κωδικός πρόσβασης ή το email σας είναι σωστά',
    createdPassword: 'Έχετε ήδη δημιουργήσει κωδικό πρόσβασης;',
    clickToLogin: 'Κάντε κλικ εδώ για να συνδεθείτε',
  },
  login: {
    dontHavePassword: 'Δεν έχετε κωδικό πρόσβασης ακόμα;',
    getPassword: 'Κάντε κλικ εδώ για να αποκτήσετε πρόσβαση',
    title: 'Σύνδεση',
    email: 'Ηλεκτρονικό ταχυδρομείο',
    password: 'Κωδικός πρόσβασης',
    textSub: 'Ξέχασες τον κωδικό πρόσβασής σου; Επαναφορά',
    restart: 'εδώ',
    restartPasswordText:
      'Πληκτρολόγησε το email σου για να επαναφέρεις τον κωδικό πρόσβασής σου. Ο κωδικός θα σταλεί στα εισερχόμενά σου.',
    placeholder: 'εισάγετε το email σας...',
    resetPassword: 'επαναφορά κωδικού πρόσβασης',
    buttonText: 'Σύνδεση',
    errorLogin:
      'Παρακαλώ, έλεγξε αν ο κωδικός πρόσβασης ή το email είναι σωστά',
    passwordResterted:
      'Ο κωδικός πρόσβασής σου έχει επαναφερθεί. Έλεγξε το email σου.',
    passwordError: 'Λυπούμαστε, δεν μπορέσαμε να βρούμε το email σου.',
    mobileAppBannerText: 'Ανακαλύψτε την εφαρμογή μας!',
  },
  navBar: {
    home: 'Αρχική',
    learn: 'Μάθηση',
    profile: 'Προφίλ',
  },
  home: {
    hello: 'Γεια σου',
    question: 'Τι θα ήθελες να συζητήσεις σήμερα;',
    answer: 'Ξεκίνησε μια συζήτηση',
    forYou: 'Προτεινόμενα για εσένα',
    popularNow: 'Δημοφιλή τώρα',
    doYouKnow: 'Το ήξερες ότι...',
    funFact: 'Ενδιαφέρον γεγονός της ημέρας',
    new: 'Νέο',
    newTitle: 'Κάρτες',
    newSubtitle: 'Νέα έκδοση',
    check: 'Έλεγχος',
    yourFavorite: 'Τα αγαπημένα σου',
  },
  learn: {
    flashcardV2: {
      new: 'Νέο',
      sport: 'Αθλητισμός',
      continue: 'συνέχισε',
      nice: 'Υπέροχο!',
      congratulation: 'Συγχαρητήρια!',
      category: 'Κατηγορία',
      textAfterCategory: 'δεν έχει μυστικά για εσένα!',
      pickNewCategory: 'Επίλεξε νέα κατηγορία',
      showResult: 'Δες τη λύση',
      createWordWithLetters: 'Δημιούργησε λέξη από ανακατεμένα γράμματα',
      know: 'Ξέρω',
      dontKnow: 'Δεν ξέρω',
      metchWords: 'Σύνδεσε σε ζευγάρια',
      addNewCategory: 'Πρόσθεσε νέα κατηγορία',
      personalizeLearning:
        'Προσαρμόστε τη μάθησή σας δημιουργώντας τη δική σας κατηγορία.',
      typeNewCategory: 'Πληκτρολογήστε το όνομα της κατηγορίας σας',
      save: 'Αποθήκευση',
      weAreCreatingCategoryForYou: 'Δημιουργούμε νέα κατηγορία για εσάς:',
      start: 'ΕΝΑΡΞΗ',
      howToUseFlashcard: 'Πώς να χρησιμοποιήσετε κάρτες;',
      knowFlashcard:
        'Κάντε κλικ στο «Δεν ξέρω» ή «Ξέρω» για να αξιολογήσετε τις γνώσεις σας.',
      dontKnowFlashcard:
        'Οι κάρτες που επισημαίνονται ως «Ξέρω» δεν θα επαναλαμβάνονται.',
      clickToHear: 'Κάντε κλικ για να ακούσετε τη λέξη.',
      clickForDefinition: 'Κάντε κλικ για να δείτε τον ορισμό',
      clickForTranslation:
        'Κάντε κλικ για να δείτε τη μετάφραση και παραδείγματα',
      close: 'Κλείσιμο',
      clickCategoryToStart:
        'Κάντε κλικ στην επιλεγμένη κατηγορία για να ξεκινήσετε τη μάθηση.',
      lookAsProgressBar:
        'Η γραμμή προόδου κάτω από την κατηγορία δείχνει πόσες λέξεις έχετε ήδη κατακτήσει.',
      clickToSetupFavaroiteFlashcard:
        'Κάντε κλικ για να ορίσετε την κατηγορία ως αγαπημένη.',
      clickToCreateOwnCategory:
        'Κάντε κλικ για να δημιουργήσετε τη δική σας κατηγορία καρτών.',
      howLearningLookLike: 'Πώς μοιάζει η μάθηση;',
      restText:
        ' Η μάθηση περιλαμβάνει την προβολή καρτών και την αξιολόγηση των γνώσεών σας. Επιπλέον, θα λύνετε απλές ασκήσεις.',
    },
    chat: {
      title: 'Συνομιλία',
      subtitle: 'Συζήτηση για οποιοδήποτε θέμα.',
    },
    fotoMode: {
      title: 'Μάντεψε τη φωτογραφία',
      subtitle: 'Περιέγραψε την εικόνα όσο το δυνατόν πιο λεπτομερώς.',
    },
    scene: {
      title: 'Σκηνές',
      subtitle: 'Παίξε ρόλους με έναν AI δάσκαλο.',
    },
    word: {
      title: 'Λέξεις',
      subtitle: 'Διεύρυνε το λεξιλόγιό σου.',
    },
    flashcard: {
      title: 'Κάρτες μνήμης',
      subtitle: 'Αναθεώρησε και απομνημόνευσε λέξεις.',
    },
    seeMore: 'Δες όλα',
  },
  common: {
    description:
      'SynthiAi - Ο Έξυπνος Βοηθός σου για την Εκμάθηση Αγγλικών. Μάθε να μιλάς αγγλικά με άνεση με τη βοήθεια της τεχνητής νοημοσύνης. Ανακάλυψε μια νέα αποτελεσματική προσέγγιση για την εκμάθηση γλωσσών. Ξεκίνα σήμερα!',
    deletedFromFavorite: 'Διαγράφηκε από τα αγαπημένα',
    addedToFavorite: 'Προστέθηκε στα αγαπημένα',
    flashcards: 'Κάρτες μνήμης',
    flashcards_v2: 'Κάρτες μνήμης',
    scenes: 'Σκηνές',
    words: 'Λέξεις',
    privacyLink: 'ιδιωτικότητα',
    termsLink: 'όροι χρήσης',
    or: 'ή',
    continueWithFB: 'Συνέχεια με το Facebook',
  },
  profile: {
    days: ['Δευ', 'Τρι', 'Τετ', 'Πεμ', 'Παρ', 'Σαβ', 'Κυρ'],
    top: {
      profile: 'Προφίλ',
      accessTo: 'Πρόσβαση σε:',
      profileImage: 'Φωτογραφία προφίλ',
      nameAndSurname: 'Όνομα και Επώνυμο',
      password: 'Κωδικός πρόσβασης',
      change: 'Αλλαγή',
      changeProfileImage: 'Αλλαγή φωτογραφίας προφίλ',
      changeNameAndSurname: 'Αλλαγή ονόματος και επωνύμου',
      changeNameAndSurnamePlaceHolder: 'Εισάγετε το όνομά σας και το επώνυμο',
      save: 'Αποθήκευση',
      changePassword: 'Αλλαγή κωδικού πρόσβασης',
      passwordInfo:
        'Ο κωδικός πρόσβασης πρέπει να έχει τουλάχιστον 8 χαρακτήρες, συμπεριλαμβανομένων πεζών και κεφαλαίων γραμμάτων, και έναν αριθμό',
      repeatPassword: 'Επανάληψη κωδικού πρόσβασης',
      passwordChanged: 'Ο κωδικός πρόσβασης άλλαξε',
      error: 'Ουπς, κάτι πήγε στραβά',
      nameChanged: 'Το όνομα άλλαξε',
      profileChanged: 'Το προφίλ άλλαξε',
    },
    plan: {
      yourPlan: 'Το πλάνο σου',
      extend: 'Επέκταση του πλάνου',
      short_extend: 'Επέκταση',
      currentPlan: 'Τρέχον πλάνο:',
      activeToPlan: 'Ενεργό έως:',
      currentPlanLong: 'Το τρέχον πλάνο σου',
    },
    middle: {
      yourMedals: 'Τα επιτεύγματά σου',
      oneDay: 'ημέρα',
      twoDays: 'ημέρες',
      words: 'Λέξεις',
      learningTime: 'Χρόνος εκμάθησης',
      streak: 'Σερί',
      streakRecord: 'Ρεκόρ σερί',
      yourRecord: 'Το ρεκόρ σου:',
      currentRecord: 'Τρέχον ρεκόρ:',
      wordsInWeek: 'Λέξεις της εβδομάδας:',
    },
    bottom: {
      yourTutor: 'Ο AI δάσκαλός σου',
      change: 'Αλλαγή',
      logout: 'Αποσύνδεση',
      FAQ: 'Συχνές ερωτήσεις',
      shareOpinion: 'Μοιράσου τη γνώμη σου',
      yourPlan: 'Το πλάνο σου',
      hobby: 'Χόμπι',
      learningSetting: 'Ρυθμίσεις εκμάθησης',
      back: 'Πίσω',
      dayTarget: 'Ημερήσιος στόχος',
      languageLevel: 'Επίπεδο γλώσσας',
      changeYourLangLevel: 'Άλλαξε το επίπεδο γλώσσας.',
      pickNewTutor: 'Επίλεξε νέο δάσκαλο',
      yourDecision:
        'Η απόφασή σου θα επηρεάσει το στυλ και την ατμόσφαιρα των μαθημάτων.',
      tutroChanged: 'Ο δάσκαλος άλλαξε',
      error: 'Ουπς, κάτι πήγε στραβά',
      englishLevelChanged: 'Το επίπεδό σου άλλαξε',
      levelsOptions: [
        {
          name: 'Αρχάριος A1',
          description: 'Ξεκίνα την περιπέτειά σου με τα αγγλικά',
          value: 'basic',
          borderRadius: '8px 8px 0 0',
        },
        {
          name: 'Βασικό A2',
          description:
            'Μπορείς να κατανοήσεις βασικές πληροφορίες σε απλές και καθημερινές συνομιλίες',
          borderRadius: '0',
          value: 'beginner',
        },
        {
          name: 'Μέτριο B1',
          description:
            'Μπορείς να διεξάγεις απλές αλλά κατανοητές συνομιλίες για γνωστά θέματα.',
          borderRadius: '0',
          value: 'intermediate',
        },
        {
          name: 'Προχωρημένο B2',
          description:
            'Μπορείς να επικοινωνείς άνετα στις περισσότερες καταστάσεις',
          borderRadius: '0',
          value: 'advanced',
        },
        {
          name: 'Ειδικός C1+',
          description:
            'Μπορείς να χρησιμοποιείς τη γλώσσα αποτελεσματικά σε περίπλοκες καταστάσεις',
          value: 'expert',
          borderRadius: '0 0 8px 8px ',
        },
      ],
      changeDailyTarget: 'Άλλαξε τον ημερήσιο στόχο εκμάθησης.',
      deletedFromFavorite: 'Διαγράφηκε από τα αγαπημένα',
      addedToFavorite: 'Προστέθηκε στα αγαπημένα',
      lessonTime: [
        {
          name: 'Γρήγορο μάθημα',
          dsecription: 'Η ιδανική επιλογή για γρήγορη εκμάθηση',
          time: '5 λεπτά',
          value: 'five_minutes',
          borderRadius: '8px 8px 0 0',
        },
        {
          name: 'Τυπική συνεδρία',
          dsecription: 'Εξαιρετική επιλογή για ισορροπημένη εκμάθηση',
          time: '10 λεπτά',
          value: 'ten_minutes',
          borderRadius: '0',
        },
        {
          name: 'Εντατική πρακτική',
          dsecription: 'Η καλύτερη επιλογή για ενθουσιώδεις',
          time: '15 λεπτά',
          value: 'fifteen_minutes',
          borderRadius: '0 0 8px 8px ',
        },
      ],
      dailyTargetChanged: 'Ο ημερήσιος στόχος άλλαξε!',
      pickIntresets: 'Επίλεξε τουλάχιστον 3 χόμπι.',
      activeTo: 'Ενεργό πλάνο έως:',
      daysLeft: 'Εναπομείνασες ημέρες πλάνου:',
      opinionSubtitle:
        'Βελτιώνουμε συνεχώς την εφαρμογή μας. Μοιράσου τη γνώμη σου για το τι θα μπορούσαμε να αλλάξουμε ή τι σου αρέσει περισσότερο.',
      startTyping: 'Ξεκίνα να πληκτρολογείς',
      faq: 'Συχνές ερωτήσεις',
      send: 'Αποστολή',
      withProblems: 'Εάν έχεις ερωτήσεις/προβλήματα, επικοινώνησε μαζί μας:',
      contactMail: 'contact@synthiaiapp.com',
      faqConfig: [
        {
          id: 1,
          question: 'Ο ήχος δεν λειτουργεί.',
          answer: `Εάν αντιμετωπίζεις προβλήματα με τον ήχο, λάβε υπόψη τα παρακάτω: 
      
              Έλεγξε τις άδειες ήχου στο πρόγραμμα περιήγησης.
      
              Βεβαιώσου ότι η ένταση του ήχου της συσκευής είναι κατάλληλη.
      
              Έλεγξε αν τα ηχεία/ακουστικά είναι συνδεδεμένα σωστά.
      
              Δες τις ρυθμίσεις ήχου του συστήματος για τυχόν εφαρμογές που είναι σε σίγαση.
      
              Δοκίμασε με άλλη συσκευή ή επικοινώνησε με τεχνική υποστήριξη.
          `,
        },
        {
          id: 2,
          question: 'Δεν μπορώ να καταγράψω τη φωνή μου.',
          answer: `Εάν αντιμετωπίζεις προβλήματα με την καταγραφή ήχου, λάβε υπόψη τα παρακάτω: 
              
              Έλεγξε τις άδειες μικροφώνου στο πρόγραμμα περιήγησης.
      
              Βεβαιώσου ότι το πρόγραμμα περιήγησης έχει πρόσβαση στο μικρόφωνο.
      
              Έλεγξε αν το μικρόφωνο είναι συνδεδεμένο σωστά.
      
              Δοκίμασε με άλλη συσκευή ή επικοινώνησε με τεχνική υποστήριξη.
          `,
        },
        {
          id: 3,
          question:
            'Μπορώ να χρησιμοποιήσω την εφαρμογή σε περισσότερες από μία συσκευές;',
          answer: `Φυσικά, απλώς συνδέσου σε οποιαδήποτε συμβατή συσκευή για να απολαύσεις μια ομαλή εμπειρία μάθησης.`,
        },
        {
          id: 4,
          question:
            'Μπορώ να χρησιμοποιήσω το Synthi AI χωρίς σύνδεση στο διαδίκτυο;',
          answer: `Όχι, το Synthi AI απαιτεί πρόγραμμα περιήγησης με πρόσβαση στο διαδίκτυο.`,
        },
        {
          id: 5,
          question: 'Πώς μπορώ να αλλάξω τον AI δάσκαλό μου;',
          answer: `Πήγαινε στην καρτέλα "Δάσκαλος" στις ρυθμίσεις του Synthi AI. Εξερεύνησε και επίλεξε τον δάσκαλο που προτιμάς.`,
        },
        {
          id: 6,
          question:
            'Πώς μπορώ να ανανεώσω την πρόσβασή μου στην εφαρμογή μετά τη λήξη;',
          answer: `Μπορείς να ανανεώσεις το πλάνο σου αφού συνδεθείς μετά τη λήξη.`,
        },
        {
          id: 7,
          question: 'Μπορώ να λάβω τιμολόγιο;',
          answer: `Επικοινώνησε με το contact@synthiaiapp.com παρέχοντας το ΑΦΜ και τα στοιχεία της εταιρείας σου.`,
        },
        {
          id: 8,
          question: 'Πώς μπορώ να επικοινωνήσω με την τεχνική υποστήριξη;',
          answer: `Στείλε email στο contact@synthiaiapp.com με λεπτομέρειες για το πρόβλημα, πληροφορίες για τη συσκευή και το πρόγραμμα περιήγησης.`,
        },
      ],
    },
    rules: 'όροι και προϋποθέσεις',
    privacy: 'Πολιτική απορρήτου',
    span: ' και ',
  },
  welcome: {
    languagePicker: {
      title: 'Επίλεξε τη γλώσσα που θέλεις να μάθεις',
      next: 'Επόμενο',
      langToLearn: 'Γλώσσα εκμάθησης',
      successTitle: 'Η γλώσσα εκμάθησης έχει αλλάξει',
      config: [
        {
          label: 'Αγγλικά',
          label2: 'Αγγλικά',
          labelInside: 'αγγλικά',
          value: 'en',
        },
        {
          label: 'Γερμανικά',
          label2: 'Γερμανικά',
          labelInside: 'γερμανικά',
          value: 'de',
        },
        {
          label: 'Ισπανικά',
          label2: 'Ισπανικά',
          labelInside: 'ισπανικά',
          value: 'es',
        },
        {
          label: 'Γαλλικά',
          label2: 'Γαλλικά',
          labelInside: 'γαλλικά',
          value: 'fr',
        },
        {
          label: 'Ιταλικά',
          label2: 'Ιταλικά',
          labelInside: 'ιταλικά',
          value: 'it',
        },
      ],
    },
    zeroScreen: {
      welcomeInApp: 'Καλώς ήρθες στην εφαρμογή Synthi AI!',
      start: 'ΞΕΚΙΝΑ',
      welcomeMobile: 'Καλώς ήρθες',
      config: {
        chrome: {
          title: 'Εγκατέστησε την εφαρμογή στη συσκευή Android.',
          text1: 'Στο πρόγραμμα περιήγησης Chrome, κάνε κλικ στις',
          text1bold: ' Ρυθμίσεις',
          image1: Android1,
          text2: 'Στη συνέχεια κάνε κλικ στις',
          text2bold: ' Προσθήκη στην αρχική οθόνη',
          image2: Android2,
          text3: 'Έτοιμο! Το Synthi AI είναι πλέον στην αρχική οθόνη σου',
          image3: Last,
        },
        safari: {
          title: 'Εγκατέστησε την εφαρμογή στη συσκευή iOS.',
          text1: 'Στο πρόγραμμα περιήγησης Safari, κάνε κλικ στις',
          text1bold: ' Κοινοποίηση',
          image1: Ios1,
          text2: 'Στη συνέχεια κάνε κλικ στις',
          text2bold: ' Προσθήκη στην αρχική οθόνη',
          image2: Ios2,
          text3: 'Έτοιμο! Το Synthi AI είναι πλέον στην αρχική οθόνη σου',
          image3: Last,
        },
      },
    },
    firstScreen: {
      title: 'Επίλεξε τον δάσκαλό σου!',
      subtitle:
        'Η επιλογή σου θα επηρεάσει το στυλ και την ατμόσφαιρα των μαθημάτων.',
      next: 'ΕΠΟΜΕΝΟ',
    },
    secondScreen: {
      title: 'Ποιο είναι το τρέχον επίπεδό σου στα {language};',
      config: [
        {
          name: 'Βασικό A1',
          description: 'Ξεκίνα την περιπέτειά σου με τα {language}.',
          value: 'basic',
        },
        {
          name: 'Βασικό A2',
          description:
            'Μπορείς να κατανοήσεις βασικές πληροφορίες σε απλές και καθημερινές συνομιλίες',
          value: 'beginner',
        },
        {
          name: 'Μέτριο B1',
          description:
            'Μπορείς να διεξάγεις απλές αλλά κατανοητές συνομιλίες για γνωστά θέματα.',
          value: 'intermediate',
        },
        {
          name: 'Προχωρημένο B2',
          description:
            'Μπορείς να επικοινωνείς άνετα στις περισσότερες καταστάσεις',
          value: 'advanced',
        },
        {
          name: 'Ειδικός C1+',
          description:
            'Μπορείς να χρησιμοποιείς τη γλώσσα αποτελεσματικά σε περίπλοκες καταστάσεις',
          value: 'expert',
        },
      ],
      next: 'ΕΠΟΜΕΝΟ',
    },
    thirdScreen: {
      title: 'Ποια είναι τα χόμπι σου;',
      subtitle: 'Επίλεξε τουλάχιστον 3 χόμπι.',
      next: 'ΕΠΟΜΕΝΟ',
    },
    fourthScreen: {
      title: 'Πόσα λεπτά την ημέρα θέλεις να μελετάς;',
      subtitle: 'Επίλεξε τον ημερήσιο στόχο σου.',
      next: 'ΑΣ ΞΕΚΙΝΗΣΟΥΜΕ!',
      config: [
        {
          name: 'Γρήγορο μάθημα',
          dsecription: 'Η ιδανική επιλογή για γρήγορη εκμάθηση',
          time: '5 λεπτά',
          value: 'five_minutes',
        },
        {
          name: 'Τυπική συνεδρία',
          dsecription: 'Εξαιρετική επιλογή για ισορροπημένη εκμάθηση',
          time: '10 λεπτά',
          value: 'ten_minutes',
        },
        {
          name: 'Εντατική πρακτική',
          dsecription: 'Η καλύτερη επιλογή για ενθουσιώδεις',
          time: '15 λεπτά',
          value: 'fifteen_minutes',
        },
      ],
    },
  },
  features: {
    topbar: {
      back: 'Πίσω',
    },
    fotoMode: {
      welcome: {
        title: 'Μάντεψε τη φωτογραφία',
        subtitle:
          'Περιέγραψε τι βλέπεις στην εικόνα με όσο το δυνατόν περισσότερες λεπτομέρειες στο επιλεγμένο θέμα.',
        start: 'Έναρξη!',
      },
      info: {
        startTyping: 'Ξεκίνα να πληκτρολογείς...',
        newTask: 'Νέα εργασία',
        send: 'Αποστολή',
      },
    },
    flashcards: {
      title: 'Κάρτες μνήμης',
      subtitleTop:
        'Μάθε και απομνημόνευσε νέες λέξεις μέσα από έναν διαδραστικό τρόπο μελέτης με κάρτες.',
      addCategory: 'Προσθέστε την κατηγορία σας',
      subtitle: 'Γράψτε το όνομα της κατηγορίας σας',
      type: 'πληκτρολόγησε την κατηγορία...',
      add: 'Προσθήκη',
      previous: 'Προηγούμενη κάρτα',
      task: 'Εργασία',
      changeLevel: 'Αλλαγή επιπέδου',
      dontKnow: 'Δεν το ξέρω',
      know: 'Το ξέρω',
      pickPairs: 'Συνδύασε ζεύγη',
      goodJob: 'Μπράβο!',
      titleEnd: 'Η αφοσίωσή σου είναι εντυπωσιακή',
      subtitleEnd: 'Είσαι έτοιμος για περισσότερες προκλήσεις;',
      repeat: 'Επανάληψη κατηγορίας',
      other: 'Επίλεξε άλλη κατηγορία',
      level: {
        title: 'Αλλαγή επιπέδου καρτών',
        beginner: ' Αρχάριος A1-A2',
        intermediate: 'Μέτριο B1-B2',
        advance: 'Προχωρημένο C1-C2',
        change: 'Αλλαγή',
      },
    },
    scene: {
      title: 'Σκηνές',
      subtitle:
        'Βάλε τον εαυτό σου στο ρόλο και παίξε μια σκηνή με έναν AI δάσκαλο στο επιλεγμένο θέμα.',
      suggest: 'Πρότεινε νέα σκηνή',
      talk: 'Συζήτηση',
      deletedFromFavorite: 'Διαγράφηκε από τα αγαπημένα',
      addedToFavorite: 'Προστέθηκε στα αγαπημένα',
    },
    word: {
      title: 'Λέξεις',
      subtitle:
        'Διεύρυνε το λεξιλόγιό σου σε βασικούς τομείς σε συνομιλίες με έναν AI δάσκαλο.',
      suggest: 'Πρότεινε νέο θέμα λέξεων',
      deletedFromFavorite: 'Διαγράφηκε από τα αγαπημένα',
      addedToFavorite: 'Προστέθηκε στα αγαπημένα',
    },
  },
  cookie: {
    title: 'Πληροφορίες για τα cookies',
    subtitle:
      'Αυτός ο ιστότοπος χρησιμοποιεί cookies για να σου παρέχει μια καλύτερη εμπειρία χρήστη και να σου προσφέρει εξατομικευμένο περιεχόμενο. Για να μάθεις περισσότερα, συμβουλέψου την πολιτική απορρήτου μας.',
    link: 'πολιτική απορρήτου',
    accept: 'ΑΠΟΔΟΧΗ',
    denied: 'ΑΠΟΡΡΙΨΗ',
  },
  talkingBar: {
    allowMicrofon: 'Παρακαλώ, επέτρεψε την πρόσβαση στο μικρόφωνο',
    typeMessage: 'Πληκτρολόγησε ένα μήνυμα...',
    record: 'Εγγραφή',
    recording: 'Ηχογράφηση',
  },
  hands: {
    giveUsInfo: 'Δώσε μας την άποψή σου',
    thanksForGrading: 'Ευχαριστούμε για την αξιολόγηση!',
    subtitle: 'Η γνώμη σου είναι σημαντική για εμάς!',
    subtitle2: 'Πες μας τι σκέφτεσαι για αυτό το μάθημα:',
    skip: 'ΠΑΡΑΛΕΙΨΗ',
    send: 'ΑΠΟΣΤΟΛΗ',
  },
  suggestConfig: {
    profile: {
      title: 'Μοιράσου τη γνώμη σου!',
      subtitle:
        'Βελτιώνουμε συνεχώς την εφαρμογή μας. Μοιράσου τη γνώμη σου για το τι θα μπορούσαμε να αλλάξουμε ή τι σου αρέσει περισσότερο.',
    },
    word: {
      title: 'Πρότεινε νέο θέμα λέξεων',
      subtitle: '',
    },
    scene: {
      title: 'Πρότεινε νέα σκηνή',
      subtitle: '',
    },
    startTyping: 'Ξεκίνα να πληκτρολογείς',
    send: 'ΑΠΟΣΤΟΛΗ',
    thx: 'Η άποψη σου στάλθηκε! Ευχαριστούμε!',
    error: 'Ουπς! Κάτι πήγε στραβά',
  },
  checkout: {
    emailGuessText: 'Μήπως εννοούσες',
    leftTitle: 'Επίλεξε ένα πλάνο για εσένα',
    rightTitle: 'Ολοκλήρωση στοιχείων',
    subscribe: 'συνδρομή',
    currencySign: '€',
    currency: 'eur',
    savePayments: 'Οι ασφαλείς πληρωμές διαχειρίζονται από',
  },
  dailyV2: {
    conversation: {
      doYouWantContinueConversation:
        'Θέλετε να συνεχίσετε τη συνομιλία ή να ξεκινήσετε μια νέα;',
      continueConversation: 'Συνέχιση συνομιλίας',
      newConversation: 'Νέα συνομιλία',
    },
    hint: {
      title: 'Υπόδειξη',
      pick: 'Επίλεξε',
    },
    feedback: {
      yourMessage: 'Το μήνυμά σου',
      goodMessage: 'Σωστό μήνυμα',
      explanation: 'Επεξήγηση',
    },
    tutorial: {
      title: 'Πώς να χρησιμοποιήσεις τη συνομιλία;',
      skip: 'Παράλειψη',
      next: 'ΕΠΟΜΕΝΟ',
      start: 'Ξεκίνα',
      screenConf: [ScreenOne, ScreenTwo, ScreenThree, ScreenFour],
      config: [
        [
          {
            title: 'Κάνε κλικ για να σιγήσεις τον ήχο του Δασκάλου',
            image: VolumeUp,
          },
          { title: 'Κάνε κλικ για να δώσεις τη γνώμη σου', image: HandsUp },
          { title: 'Κάνε κλικ για να ανοίξεις τις οδηγίες', image: Tutorial },
        ],
        [
          { title: 'Κάνε κλικ για να ακούσεις ξανά', image: VolumeUpBlank },
          {
            title: 'Κάνε κλικ για να δεις τη μετάφραση',
            image: TranslationBlank,
          },
          { title: 'Κάνε κλικ για να δεις την υπόδειξη', image: HintBlank },
          {
            title: 'Κάνε κλικ για να αναφέρεις ένα πρόβλημα',
            image: InfoBlank,
          },
        ],
        [
          { title: 'Το μήνυμά σου είναι σωστό', image: GreenCircle },
          {
            title:
              'Κάνε κλικ για να δεις τη διορθωμένη απάντηση και την εξήγηση',
            image: YellowCircle,
          },
        ],
        [
          {
            title:
              'Κάνε κλικ στο πεδίο κειμένου για να πληκτρολογήσεις ένα μήνυμα',
          },
          {
            title: 'Κάνε κλικ για να ηχογραφήσεις ένα μήνυμα',
            image: Microphone,
          },
        ],
      ],
    },
  },
  problem: {
    title: 'Αναφορά προβλήματος',
    subtitle: 'Περιέγραψε το πρόβλημα σχετικά με το μήνυμα του AI',
    send: 'Αποστολή',
    messageSend: 'Το μήνυμα στάλθηκε',
    error: 'Κάτι πήγε στραβά, γράψε μας :)',
  },
};
