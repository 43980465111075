import PopUp from '@/common/components/PopUp';
import React from 'react';
import styled from 'styled-components';
import VolumeBox from '../images/volume-up-tutorial-box.svg';
import PreviewBox from '../images/preview-tutorial-box.svg';
import TranslateBox from '../images/translate-tutorial-box.svg';
import VolumeViolet from '../images/volume-up-tutorial-violet.svg';
import PreviewViolet from '../images/preview-tutorial-preview.svg';
import TranslateViolet from '../images/translate-tutorial-violet.svg';
import { useMutation } from 'react-query';
import { markFlashcardTutorialPopup } from '@/api/query/customer';
import Loading from '@/common/components/Loading';
import { useLanguageData } from '@/common/hooks/useLanguageData';

const Title = styled.div`
  color: #29185e;
  text-align: center;
  font-family: 'Work Sans';
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 28px */
  margin-top: 22px;
`;

const Text = styled.div`
  color: var(--Text-color, #29185e);
  font-family: 'Work Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 12px */
  max-width: 290px;
  padding: 0 20px;
`;

const CloseButton = styled.button`
  padding: 14px;
  width: 200px;
  justify-content: center;
  align-items: center;

  color: #fff;
  text-align: center;
  font-family: 'Work Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.64px;
  text-transform: uppercase;

  border-radius: 100px;
  background: linear-gradient(91deg, #4f17aa 1.48%, #ac02f1 99.24%);

  margin: 20px 0;
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 12px;

  div {
    display: flex;
    align-items: center;
  }
`;
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const BoxWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  gap: 47px;
  padding: 10px;
  width: 200px;

  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 0px 8px 0px rgba(41, 24, 94, 0.1);
  margin: 22px auto;
`;

const BoxElement = styled.div`
  display: flex;
  align-items: center;
`;

const IMAGES_BOX = [VolumeBox, PreviewBox, TranslateBox];

const FlashcardTutorial = ({ setClose }) => {
  const translation = useLanguageData('learn.flashcardV2');

  const sawPopupMutation = useMutation({
    mutationFn: (payload) => markFlashcardTutorialPopup(payload),
    onSuccess: () => {
      setClose(() => false);
    },
  });

  const handleClose = () => {
    const payload = {
      type: 'flashcard',
    };

    sawPopupMutation.mutate(payload);
  };

  return (
    <>
      {sawPopupMutation.isLoading && <Loading />}
      <PopUp setClose={() => handleClose()}>
        <Title>{translation?.howToUseFlashcard}</Title>
        <Text style={{ marginTop: '22px' }}>{translation?.knowFlashcard}</Text>
        <Text style={{ marginTop: '12px' }}>
          {translation?.dontKnowFlashcard}
        </Text>
        <BoxWrapper>
          {IMAGES_BOX.map((ib) => (
            <BoxElement>
              <img src={ib} />
            </BoxElement>
          ))}{' '}
        </BoxWrapper>
        <ContentWrapper>
          <div>
            <img src={VolumeViolet} />
          </div>
          <Text style={{ padding: 0, width: '260px' }}>
            {translation?.clickToHear}
          </Text>
        </ContentWrapper>
        <ContentWrapper>
          <div>
            <img src={PreviewViolet} />
          </div>
          <Text style={{ padding: 0, width: '260px' }}>
            {translation?.clickForDefinition}
          </Text>
        </ContentWrapper>
        <ContentWrapper>
          <div>
            <img src={TranslateViolet} />
          </div>
          <Text style={{ padding: 0, width: '260px' }}>
            {translation?.clickForTranslation}
          </Text>
        </ContentWrapper>
        <ButtonWrapper>
          <CloseButton onClick={handleClose}>{translation?.close}</CloseButton>
        </ButtonWrapper>
      </PopUp>
    </>
  );
};

export default FlashcardTutorial;
