import Android1 from './images/Android_1_150_BG.png';
import Android2 from './images/Android_Dodaj_150_BG.png';
import Ios1 from './images/IOS_1_150_BG.png';
import Ios2 from './images/IOS_Dodaj_150_BG.png';
import Last from './images/ios_android_3.png';

import VolumeUp from './images/dailyTutorial/volume-up.svg';
import HandsUp from './images/dailyTutorial/hand-up.svg';
import VolumeUpBlank from './images/dailyTutorial/volume-up-blank.svg';
import TranslationBlank from './images/dailyTutorial/icon-translate-blank.svg';
import HintBlank from './images/dailyTutorial/idea-blank.svg';
import InfoBlank from './images/dailyTutorial/information-blank.svg';
import GreenCircle from './images/dailyTutorial/green-circle.svg';
import YellowCircle from './images/dailyTutorial/yelow-circle.png';
import Microphone from './images/dailyTutorial/icon-microphone.svg';
import ScreenOne from './images/dailyTutorial/samouczek_czat_1.png';
import ScreenTwo from './images/dailyTutorial/samouczek_czat_2.png';
import ScreenThree from './images/dailyTutorial/samouczek_czat_3.png';
import ScreenFour from './images/dailyTutorial/samouczek_czat_4.png';
import Tutorial from './images/dailyTutorial/samouczek-fiolet.svg';

export default {
  passwordRestart: {
    title: 'Възстановяване на парола',
    password: 'Парола',
    repeatPassword: 'Повторете паролата',
    restartPawssord: 'Възстановяване на парола',
  },
  paywall: {
    title: 'Изберете план, за да продължите обучението',
    buy: 'Купуване',
    savePayment: 'Сигурно плащане чрез',
    skip: 'Пропускане',
    pickPlan: 'Изберете план',
    endPlanFirstPart: 'Вашият достъп ще приключи след',
    endPlanSecondPart: 'дни.',
    endPlanAditionalPart: 'дни.',
    paymentTitle: 'Резюме',
    product: 'Продукт:',
    plan: 'SynthiAi Пакет',
    forLong: 'Достъп за',
    data: 'данни:',
    nameAndSurname: 'Име и фамилия:',
    paymentForm: 'Начин на плащане:',
    toPay: 'Дължима сума днес:',
    accept: 'Приемам',
    rules: 'условията',
    payingAccept: 'Плащайки, приемате',
    paymentRules: '"Условията за плащане на PayU"',
    buyAndPay: 'КУПУВАМ И ПЛАЩАМ',
  },
  paymentStatus: {
    veryfication: 'Плащането все още се обработва...',
    title: 'Плащането е завършено',
    success: 'Успешно!',
    failure: 'Неуспешно!',
    subtitle: 'Скоро ще бъдете пренасочени към страницата за вход.',
    textInfo: `Вашето плащане беше успешно обработено. Вече можете да зададете своята парола, като отидете на страницата ни за регистрация. Ще получите имейл с връзка за задаване на парола в рамките на няколко минути.
Моля, проверете входящата си поща, както и папката за спам. За по-лесно търсене, въведете "synthiai" в полето за търсене. В случай на проблеми, свържете се с нас на: contact@synthiaiapp.com`,
    login: 'Вход',
  },
  register: {
    steps: {
      register: 'Регистрация',
      package: 'Пакет',
      summarize: 'Обобщение',
      step: 'Стъпка ',
    },
    stepOne: {
      password: 'Парола',
      errorGoogle: 'Присъединете се с вашия имейл адрес',
      title: 'Регистрация',
      nameAndSurname: 'Име и фамилия',
      placeholderNameAndSurname: 'XXXXXXXXXXX',
      email: 'Имейл адрес',
      placeholderEmail: 'напр. primer@synthiaiapp.com',
      password: 'Парола',
      start: 'СЪЗДАЙ АКАУНТ',
      subtitle:
        'Паролата трябва да бъде най-малко 8 символа и да съдържа малки и главни букви, както и число.',
      passwordError1: 'Паролата трябва да съдържа поне 8 знака.',
      passwordError2: 'Паролата трябва да съдържа поне 1 главна буква.',
      passwordError3: 'Паролата трябва да съдържа поне 1 малка буква.',
      passwordError4: 'Паролата трябва да съдържа поне 1 цифра.',
      passwordError5: 'Паролите трябва да съвпадат.',
    },
    stepTwo: {
      title: 'Изберете план',
      month: 'месец',
      months: 'месеца',
      months2: 'месеца',
      value: '€ / месец',
      pick: 'изберете',
      nowPay: 'Незабавно плащане',
      saveOne: 'Спестете',
      buy: 'Купуване',
      savePayment: 'Сигурно плащане чрез',
    },
    stepThree: {
      title: 'Обобщение',
      product: 'Продукт:',
      plan: 'Synthi AI Пакет',
      forLong: 'Достъп за',
      data: 'дата:',
      nameAndSurname: 'Име и фамилия:',
      paymentForm: 'Начин на плащане:',
      toPay: 'Сума за плащане:',
      accept: 'Приемам',
      rules: 'условията',
      payingAccept: 'С плащането приемате',
      paymentRules: '"Условията за плащане на PayU"',
      buyAndPay: 'КУПУВАНЕ И ПЛАЩАНЕ',
    },
  },
  succesPayment: {
    title: 'Успешно плащане!',
    subtitle: 'Скоро ще бъдете пренасочени към страницата за вход.',
  },
  firstLogin: {
    createPasswordForAcoount: 'Създайте парола за своя акаунт',
    typeEmail: 'Въведете имейл адрес',
    useSameEmail: 'Използвайте същия адрес, който сте посочили при покупката',
    typePassword: 'Въведете парола',
    confirmPassword: 'Потвърдете паролата',
    createPassword: 'Създайте парола',
    errorLogin: 'Проверете дали вашата парола или имейл са правилни',
    createdPassword: 'Вече създадохте ли парола?',
    clickToLogin: 'Щракнете тук, за да влезете',
  },
  login: {
    dontHavePassword: 'Все още нямате парола?',
    getPassword: 'Кликнете тук, за да получите достъп',

    title: 'Вход',
    email: 'Имейл адрес',
    password: 'Парола',
    textSub: 'Забравили сте паролата? Възстановете я',
    restart: 'тук',
    restartPasswordText:
      'Въведете имейл адреса си, за да възстановите паролата. Паролата ще бъде изпратена във входящата ви поща.',
    placeholder: 'въведете вашия имейл адрес...',
    resetPassword: 'възстановяване на парола',
    buttonText: 'Вход',
    errorLogin: 'Моля, проверете имейла и паролата си.',
    passwordResterted: 'Вашата парола е възстановена. Проверете имейла си.',
    passwordError: 'Съжаляваме, не намерихме вашия имейл адрес.',
    mobileAppBannerText: 'Открийте нашето приложение!',
  },
  navBar: {
    home: 'Начало',
    learn: 'Учене',
    profile: 'Профил',
  },
  home: {
    hello: 'Здравейте',
    question: 'За какво искате да говорите днес?',
    answer: 'Започнете разговор',
    forYou: 'Препоръчано за вас',
    popularNow: 'Популярно сега',
    doYouKnow: 'Знаете ли, че...?',
    funFact: 'Забавен факт на деня',
    new: 'Ново',
    newTitle: 'Флашкарти',
    newSubtitle: 'Ново издание',
    check: 'Провери',
    yourFavorite: 'Вашите любими',
  },
  learn: {
    flashcardV2: {
      new: 'Ново',
      sport: 'Спорт',
      continue: 'продължи',
      nice: 'Супер!',
      congratulation: 'Поздравления!',
      category: 'Категория',
      textAfterCategory: 'няма тайни за теб!',
      pickNewCategory: 'Избери нова категория',
      showResult: 'Покажи решение',
      createWordWithLetters: 'Създай дума от разбъркани букви',
      know: 'Знам',
      dontKnow: 'Не знам',
      metchWords: 'Съчетай в двойки',
      addNewCategory: 'Добави нова категория',
      personalizeLearning:
        'Персонализирай своето обучение, като създадеш собствена категория.',
      typeNewCategory: 'Въведи името на своята категория',
      save: 'Запази',
      weAreCreatingCategoryForYou: 'Създаваме нова категория за теб:',
      start: 'ЗАПОЧНИ',
      howToUseFlashcard: 'Как да използваш флашкарти?',
      knowFlashcard: 'Кликни „Не знам“ или „Знам“, за да оцениш знанията си.',
      dontKnowFlashcard:
        'Флашкартите, маркирани като „Знам“, няма да се повтарят.',
      clickToHear: 'Кликни, за да чуеш думата.',
      clickForDefinition: 'Кликни, за да видиш дефиницията',
      clickForTranslation: 'Кликни, за да видиш превода и примери',
      close: 'Затвори',
      clickCategoryToStart:
        'Кликни върху избраната категория, за да започнеш обучение.',
      lookAsProgressBar:
        'Лентата за напредък под категорията показва колко думи вече си усвоил.',
      clickToSetupFavaroiteFlashcard:
        'Кликни, за да отбележиш категория като любима.',
      clickToCreateOwnCategory:
        'Кликни, за да създадеш собствена категория флашкарти.',
      howLearningLookLike: 'Как изглежда обучението?',
      restText:
        ' Обучението се състои от преглед на флашкарти и оценяване на познанията. Допълнително ще решаваш лесни задачи.',
    },
    chat: {
      title: 'Чат',
      subtitle: 'Говорете на всякакви теми.',
    },
    fotoMode: {
      title: 'Познайте картината',
      subtitle: 'Описвайте картината възможно най-точно.',
    },
    scene: {
      title: 'Сцени',
      subtitle: 'Играйте с AI преподавател.',
    },
    word: {
      title: 'Думи',
      subtitle: 'Разширявайте речниковия си запас.',
    },
    flashcard: {
      title: 'Флашкарти',
      subtitle: 'Преговаряйте и запомняйте думи.',
    },
    seeMore: 'Вижте всички',
  },
  common: {
    description:
      'SynthiAi - Вашият интелигентен помощник за изучаване на английски език. Учете английски свободно с помощта на AI. Открийте нов, ефективен начин за изучаване на език. Започнете още днес!',
    deletedFromFavorite: 'Премахнато от любимите',
    addedToFavorite: 'Добавено към любимите',
    flashcards: 'Флашкарти',
    flashcards_v2: 'Флашкарти',
    scenes: 'Сцени',
    words: 'Думи',
    privacyLink: 'privacy',
    termsLink: 'tos',
    or: 'или',
    continueWithFB: 'Продължете с Facebook',
  },
  profile: {
    days: [
      'Понеделник',
      'Вторник',
      'Сряда',
      'Четвъртък',
      'Петък',
      'Събота',
      'Неделя',
    ],
    top: {
      profile: 'Профил',
      accessTo: 'Достъп до:',
      profileImage: 'Профилна снимка',
      nameAndSurname: 'Име и фамилия',
      password: 'Парола',
      change: 'Промяна',
      changeProfileImage: 'Промяна на профилна снимка',
      changeNameAndSurname: 'Промяна на име и фамилия',
      changeNameAndSurnamePlaceHolder: 'Въведете вашето име',
      save: 'Запазване',
      changePassword: 'Промяна на парола',
      passwordInfo:
        'Паролата трябва да бъде най-малко 8 символа и да съдържа малки и главни букви, както и число.',
      repeatPassword: 'Повторете паролата',
      passwordChanged: 'Паролата е променена',
      error: 'Упс, възникна грешка',
      nameChanged: 'Името е променено',
      profileChanged: 'Профилът е променен',
    },
    plan: {
      yourPlan: 'Вашият план',
      extend: 'Удължаване на план',
      short_extend: 'Удължаване',
      currentPlan: 'Текущ план:',
      activeToPlan: 'Активен до:',
      currentPlanLong: 'Вашият текущ план',
    },
    middle: {
      yourMedals: 'Вашите постижения',
      oneDay: 'ден',
      twoDays: 'дни',
      words: 'Думи',
      learningTime: 'Време за учене',
      streak: 'Поредност',
      streakRecord: 'Рекорд на поредност',
      yourRecord: 'Вашият рекорд:',
      currentRecord: 'Текущ рекорд:',
      wordsInWeek: 'Думи за седмицата:',
    },
    bottom: {
      yourTutor: 'Вашият AI преподавател',
      change: 'Смяна',
      logout: 'Изход',
      FAQ: 'ЧЗВ',
      shareOpinion: 'Споделете мнение',
      yourPlan: 'Вашият план',
      hobby: 'Хоби',
      learningSetting: 'Настройки за учене',
      back: 'Назад',
      dayTarget: 'Ежедневна цел',
      languageLevel: 'Езиково ниво',
      changeYourLangLevel: 'Променете вашето езиково ниво',
      pickNewTutor: 'Изберете нов преподавател',
      yourDecision:
        'Вашият избор ще повлияе на стила и настроението на уроците.',
      tutroChanged: 'Преподавателят е променен',
      error: 'Упс, възникна грешка',
      englishLevelChanged: 'Езиковото ниво е променено',
      levelsOptions: [
        {
          name: 'Начинаещ A1',
          description: 'Започнете да учите английски',
          value: 'basic',
          borderRadius: '8px 8px 0 0',
        },
        {
          name: 'Основен A2',
          description: 'Разбирате основна информация в ежедневни разговори',
          borderRadius: '0',
          value: 'beginner',
        },
        {
          name: 'Средно ниво B1',
          description:
            'Можете да водите прости, но разбираеми разговори по познати теми.',
          borderRadius: '0',
          value: 'intermediate',
        },
        {
          name: 'Напреднал B2',
          description: 'Можете да общувате свободно в повечето ситуации',
          borderRadius: '0',
          value: 'advanced',
        },
        {
          name: 'Експерт C1+',
          description: 'Използвате езика ефективно в сложни ситуации',
          value: 'expert',
          borderRadius: '0 0 8px 8px',
        },
      ],
      changeDailyTarget: 'Променете вашата ежедневна цел.',
      deletedFromFavorite: 'Премахнато от любимите',
      addedToFavorite: 'Добавено към любимите',
      lessonTime: [
        {
          name: 'Бърз урок',
          dsecription: 'Идеален за бързо учене',
          time: '5 минути',
          value: 'five_minutes',
          borderRadius: '8px 8px 0 0',
        },
        {
          name: 'Стандартен урок',
          dsecription: 'Балансирано учебно изживяване',
          time: '10 минути',
          value: 'ten_minutes',
          borderRadius: '0',
        },
        {
          name: 'Интензивна практика',
          dsecription: 'Най-добър избор за ангажирани учащи',
          time: '15 минути',
          value: 'fifteen_minutes',
          borderRadius: '0 0 8px 8px',
        },
      ],
      dailyTargetChanged: 'Ежедневната цел е променена!',
      pickIntresets: 'Изберете поне 3 хобита.',
      activeTo: 'Вашият план е активен до:',
      daysLeft: 'Оставащи дни на плана:',
      opinionSubtitle:
        'Ние непрекъснато подобряваме нашето приложение. Споделете вашето мнение за това, какво бихте променили или какво ви харесва най-много в приложението.',
      startTyping: 'Започнете да пишете',
      faq: 'ЧЗВ',
      send: 'Изпращане',
      withProblems: 'Ако имате въпроси/проблеми, свържете се с нас:',
      contactMail: 'contact@synthiaiapp.com',
      faqConfig: [
        {
          id: 1,
          question: 'Звукът не работи.',
          answer:
            'Ако имате проблеми със звука, опитайте следното:\n\nПроверете аудио разрешенията в браузъра си.\n\nУверете се, че силата на звука на устройството е подходяща.\n\nПроверете дали вашите високоговорители/слушалки са правилно свързани.\n\nПрегледайте настройките на звука на системата, за да проверите дали някое приложение не е заглушено.\n\nОпитайте с друго устройство или се свържете с техническата поддръжка.',
        },
        {
          id: 2,
          question: 'Не мога да записвам звук.',
          answer:
            'Ако не можете да записвате звук, опитайте следното:\n\nПроверете разрешенията за микрофона в браузъра си.\n\nУверете се, че мобилният браузър има достъп до микрофона.\n\nПроверете дали микрофонът е правилно свързан.\n\nОпитайте с друго устройство или се свържете с техническата поддръжка.',
        },
        {
          id: 3,
          question: 'Мога ли да използвам приложението на няколко устройства?',
          answer:
            'Разбира се, влезте от всяко съвместимо устройство за безпроблемно обучение.',
        },
        {
          id: 4,
          question: 'Мога ли да използвам Synthi AI без интернет?',
          answer: 'Не, Synthi AI изисква интернет връзка и браузър.',
        },
        {
          id: 5,
          question: 'Как мога да сменя моя AI преподавател?',
          answer:
            "Отидете на раздела 'Преподавател' в настройките на Synthi AI. Разгледайте и изберете друг преподавател.",
        },
        {
          id: 6,
          question: 'Как мога да подновя достъпа си след изтичане?',
          answer:
            'Можете да подновите своя план, като влезете след изтичането му.',
        },
        {
          id: 7,
          question: 'Мога ли да получа фактура?',
          answer:
            'Пишете на contact@synthiaiapp.com с вашия данъчен номер и информация за фирмата.',
        },
        {
          id: 8,
          question: 'Как мога да се свържа с техническата поддръжка?',
          answer:
            'Пишете на contact@synthiaiapp.com с подробности за проблема и информация за устройството и браузъра.',
        },
      ],
    },
    rules: 'условия',
    privacy: 'Политика за поверителност',
    span: ' и ',
  },
  welcome: {
    languagePicker: {
      title: 'Избери език, който искаш да учиш',
      next: 'Напред',
      langToLearn: 'Език на обучение',
      successTitle: 'Езикът на обучение беше променен',
      config: [
        {
          label: 'Английски',
          label2: 'Английски',
          labelInside: 'Английски',
          value: 'en',
        },
        {
          label: 'Немски',
          label2: 'Немски',
          labelInside: 'Немски',
          value: 'de',
        },
        {
          label: 'Испански',
          label2: 'Испански',
          labelInside: 'Испански',
          value: 'es',
        },
        {
          label: 'Френски',
          label2: 'Френски',
          labelInside: 'Френски',
          value: 'fr',
        },
        {
          label: 'Италиански',
          label2: 'Италиански',
          labelInside: 'Италиански',
          value: 'it',
        },
      ],
    },
    zeroScreen: {
      welcomeInApp: 'Добре дошли в приложението Synthi AI!',
      start: 'СТАРТ',
      welcomeMobile: 'Добре дошли',
      config: {
        chrome: {
          title: 'Инсталирайте приложението на вашето Android устройство.',
          text1: 'В браузъра Chrome натиснете',
          text1bold: ' Настройки',
          image1: Android1,
          text2: 'След това натиснете',
          text2bold: ' Добавяне към начален екран',
          image2: Android2,
          text3: 'Готово! Synthi AI вече е на вашия начален екран',
          image3: Last,
        },
        safari: {
          title: 'Инсталирайте приложението на вашето iOS устройство.',
          text1: 'В браузъра Safari натиснете',
          text1bold: ' Споделяне',
          image1: Ios1,
          text2: 'След това натиснете',
          text2bold: ' Добавяне към начален екран',
          image2: Ios2,
          text3: 'Готово! Synthi AI вече е на вашия начален екран',
          image3: Last,
        },
      },
    },
    firstScreen: {
      title: 'Изберете вашия преподавател!',
      subtitle: 'Вашият избор ще повлияе на стила и настроението на уроците.',
      next: 'НАПРЕД',
    },
    secondScreen: {
      title: 'Какво е текущото ви ниво по {language}?',
      config: [
        {
          name: 'Начинаещ A1',
          description: 'Започване на езиково приключение {language}.',
          value: 'basic',
        },
        {
          name: 'Основен A2',
          description: 'Разбирате основна информация в ежедневни разговори',
          value: 'beginner',
        },
        {
          name: 'Средно ниво B1',
          description:
            'Можете да водите прости, но разбираеми разговори по познати теми.',
          value: 'intermediate',
        },
        {
          name: 'Напреднал B2',
          description: 'Можете да общувате свободно в повечето ситуации',
          value: 'advanced',
        },
        {
          name: 'Експерт C1+',
          description: 'Използвате езика ефективно в сложни ситуации',
          value: 'expert',
        },
      ],
      next: 'НАПРЕД',
    },
    thirdScreen: {
      title: 'Какви са вашите хобита?',
      subtitle: 'Изберете поне 3 хобита.',
      next: 'НАПРЕД',
    },
    fourthScreen: {
      title: 'Колко минути искате да учите всеки ден?',
      subtitle: 'Изберете вашата ежедневна цел.',
      next: 'ДА ЗАПОЧВАМЕ!',
      config: [
        {
          name: 'Бърз урок',
          dsecription: 'Идеален за бързо учене',
          time: '5 минути',
          value: 'five_minutes',
        },
        {
          name: 'Стандартен урок',
          dsecription: 'Балансирано учебно изживяване',
          time: '10 минути',
          value: 'ten_minutes',
        },
        {
          name: 'Интензивна практика',
          dsecription: 'Най-добър избор за ангажирани учащи',
          time: '15 минути',
          value: 'fifteen_minutes',
        },
      ],
    },
  },
  features: {
    topbar: {
      back: 'Назад',
    },
    fotoMode: {
      welcome: {
        title: 'Познайте картината',
        subtitle:
          'Описвайте това, което виждате на картината, възможно най-точно по избраната тема.',
        start: 'Започнете!',
      },
      info: {
        startTyping: 'Започнете да пишете...',
        newTask: 'Нова задача',
        send: 'Изпращане',
      },
    },
    flashcards: {
      title: 'Карти',
      subtitleTop:
        'Учете и запомняйте нови думи с помощта на интерактивни карти.',
      addCategory: 'Добавете собствена категория',
      subtitle: 'Въведете име на категорията',
      type: 'въведете категория...',
      add: 'Добавяне',
      previous: 'Предишна карта',
      task: 'Задача',
      changeLevel: 'Смяна на ниво',
      dontKnow: 'Не знам',
      know: 'Знам',
      pickPairs: 'Избор на двойки',
      goodJob: 'Добра работа!',
      titleEnd: 'Вашата отдаденост е впечатляваща',
      subtitleEnd: 'Готови ли сте за повече предизвикателства?',
      repeat: 'Повторете категорията',
      other: 'Изберете друга категория',
      level: {
        title: 'Смяна на ниво на карти',
        beginner: 'Начинаещ A1-A2',
        intermediate: 'Средно ниво B1-B2',
        advance: 'Напреднал C1-C2',
        change: 'Смяна',
      },
    },
    scene: {
      title: 'Сцени',
      subtitle: 'Изпълнявайте сцена с AI преподавател по избрана тема.',
      suggest: 'Предложете нова сцена',
      talk: 'Разговор',
      deletedFromFavorite: 'Премахнато от любимите',
      addedToFavorite: 'Добавено към любимите',
    },
    word: {
      title: 'Думи',
      subtitle:
        'Разширявайте речниковия си запас с AI преподавател по ключови теми.',
      suggest: 'Предложете нова тема за думи',
      deletedFromFavorite: 'Премахнато от любимите',
      addedToFavorite: 'Добавено към любимите',
    },
  },
  cookie: {
    title: 'Информация за бисквитки',
    subtitle:
      'Този уебсайт използва бисквитки, за да осигури по-добро потребителско изживяване и персонализирано съдържание. За повече информация прочетете нашата политика за поверителност.',
    link: 'политика за поверителност',
    accept: 'ПРИЕМАМ',
    denied: 'ОТКАЗВАМ',
  },
  talkingBar: {
    allowMicrofon: 'Моля, разрешете използването на микрофон',
    typeMessage: 'Напишете съобщение...',
    record: 'Запис',
    recording: 'Запис в процес',
  },
  hands: {
    giveUsInfo: 'Споделете вашето мнение',
    thanksForGrading: 'Благодарим за оценката!',
    subtitle: 'Вашето мнение е важно за нас!',
    subtitle2: 'Кажете ни какво мислите за този урок:',
    skip: 'Пропускане',
    send: 'Изпращане',
  },
  suggestConfig: {
    profile: {
      title: 'Споделете вашето мнение!',
      subtitle:
        'Ние непрекъснато подобряваме нашето приложение. Споделете вашето мнение за това, какво бихте променили или какво ви харесва най-много в приложението.',
    },
    word: {
      title: 'Предложете нова тема за думи',
      subtitle: '',
    },
    scene: {
      title: 'Предложете нова сцена',
      subtitle: '',
    },
    startTyping: 'Започнете да пишете',
    send: 'ИЗПРАЩАНЕ',
    thx: 'Мнението е изпратено! Благодарим ви!',
    error: 'Упс! Възникна грешка',
  },
  checkout: {
    emailGuessText: 'Имахте предвид това?',
    leftTitle: 'Изберете план за себе си',
    rightTitle: 'Попълнете данните',
    subscribe: 'ПЛАЩАНЕ',
    total: 'Общо',
    currencySign: 'лв',
    currency: 'bgn',
    accept: 'Приемам ',
    space: ' и ',
    email: 'Имейл',
    placeHolderEmail: 'Въведете вашия имейл адрес',
    rules: 'условията за ползване',
    tos: 'политиката за поверителност',
    savePayments: 'Сигурни плащания се обработват от',
  },
  dailyV2: {
    conversation: {
      doYouWantContinueConversation:
        'Искате ли да продължите разговора или да започнете нов?',
      continueConversation: 'Продължи разговора',
      newConversation: 'Нов разговор',
    },
    hint: {
      title: 'Съвет',
      pick: 'Изберете',
    },
    feedback: {
      yourMessage: 'Вашето съобщение',
      goodMessage: 'Правилно съобщение',
      explanation: 'Обяснение',
    },
    tutorial: {
      title: 'Как да използвате чата?',
      skip: 'Пропускане',
      next: 'НАПРЕД',
      start: 'Начало',
      screenConf: [ScreenOne, ScreenTwo, ScreenThree, ScreenFour],
      config: [
        [
          {
            title: 'Натиснете, за да заглушите гласа на преподавателя',
            image: VolumeUp,
          },
          {
            title: 'Натиснете, за да споделите мнението си',
            image: HandsUp,
          },
          {
            title: 'Натиснете, за да отворите ръководството',
            image: Tutorial,
          },
        ],
        [
          {
            title: 'Натиснете, за да чуете отново',
            image: VolumeUpBlank,
          },
          {
            title: 'Натиснете, за да видите превода',
            image: TranslationBlank,
          },
          {
            title: 'Натиснете, за да видите съвета',
            image: HintBlank,
          },
          {
            title: 'Натиснете, за да докладвате за проблем',
            image: InfoBlank,
          },
        ],
        [
          {
            title: 'Вашето съобщение е правилно',
            image: GreenCircle,
          },
          {
            title: 'Натиснете, за да видите коригиран отговор и обяснение',
            image: YellowCircle,
          },
        ],
        [
          {
            title: 'Натиснете, за да напишете съобщение',
            image: '',
          },
          {
            title: 'Натиснете, за да направите запис',
            image: Microphone,
          },
        ],
      ],
    },
  },
  problem: {
    title: 'Докладвайте за проблем',
    subtitle: 'Опишете проблема, свързан със съобщението на AI',
    send: 'Изпращане',
    messageSend: 'Съобщението е изпратено',
    error: 'Възникна грешка, моля, пишете ни :)',
  },
};
