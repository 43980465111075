import Android1 from './images/Android_1_150_HU.png';
import Android2 from './images/Android_Dodaj_150_HU.png';
import Ios1 from './images/IOS_1_150_HU.png';
import Ios2 from './images/IOS_Dodaj_150_HU.png';
import Last from './images/ios_android_3.png';

import VolumeUp from './images/dailyTutorial/volume-up.svg';
import HandsUp from './images/dailyTutorial/hand-up.svg';
import VolumeUpBlank from './images/dailyTutorial/volume-up-blank.svg';
import TranslationBlank from './images/dailyTutorial/icon-translate-blank.svg';
import HintBlank from './images/dailyTutorial/idea-blank.svg';
import InfoBlank from './images/dailyTutorial/information-blank.svg';
import GreenCircle from './images/dailyTutorial/green-circle.svg';
import YellowCircle from './images/dailyTutorial/yelow-circle.png';
import Microphone from './images/dailyTutorial/icon-microphone.svg';
import ScreenOne from './images/dailyTutorial/samouczek_czat_1.png';
import ScreenTwo from './images/dailyTutorial/samouczek_czat_2.png';
import ScreenThree from './images/dailyTutorial/samouczek_czat_3.png';
import ScreenFour from './images/dailyTutorial/samouczek_czat_4.png';
import Tutorial from './images/dailyTutorial/samouczek-fiolet.svg';

export default {
  passwordRestart: {
    title: 'Jelszó visszaállítása',
    password: 'Jelszó',
    repeatPassword: 'Jelszó ismétlése',
    restartPawssord: 'Jelszó visszaállítása',
  },

  paywall: {
    title: 'Válassz egy tervet a tanulás folytatásához',
    buy: 'Vásárlás',
    savePayment: 'Biztonságos fizetés a következőn keresztül',
    skip: 'Kihagyás',
    pickPlan: 'Terv kiválasztása',
    endPlanFirstPart: 'A hozzáférésed véget ér',
    endPlanSecondPart: 'nap múlva.',
    endPlanAditionalPart: 'nap múlva.',
    paymentTitle: 'Összefoglaló',
    product: 'Termék:',
    plan: 'SynthiAi Csomag',
    forLong: 'Hozzáférés:',
    data: 'adatok:',
    nameAndSurname: 'Név és vezetéknév:',
    paymentForm: 'Fizetési mód:',
    toPay: 'Ma fizetendő:',
    accept: 'Elfogadom',
    rules: 'feltételek',
    payingAccept: 'Fizetéssel elfogadod',
    paymentRules: '"PayU fizetési feltételeit"',
    buyAndPay: 'VÁSÁROLOK ÉS FIZETEK',
  },
  paymentStatus: {
    veryfication: 'A fizetés még feldolgozás alatt van...',
    title: 'Fizetés befejeződött',
    success: 'Sikeres!',
    failure: 'Sikertelen!',
    subtitle: 'Hamarosan átirányítunk a bejelentkezési oldalra.',
    textInfo: `A fizetésedet sikeresen feldolgoztuk. Most beállíthatod a jelszavadat a regisztrációs oldalunkon. Néhány percen belül e-mailt kapsz egy linkkel, amely lehetővé teszi a jelszó beállítását.
Kérjük, ellenőrizd a beérkező üzeneteidet, valamint a spam mappát. A keresés megkönnyítése érdekében írd be a keresőmezőbe a "synthiai" szót. Ha bármilyen probléma merül fel, kérjük, lépj velünk kapcsolatba a következő címen: contact@synthiaiapp.com`,
    login: 'Bejelentkezés',
  },
  register: {
    steps: {
      register: 'Regisztráció',
      package: 'Csomag',
      summarize: 'Összegzés',
      step: 'Lépés ',
    },
    stepOne: {
      password: 'Jelszó',
      errorGoogle: 'Csatlakozz az e-mail címeddel',
      title: 'Regisztráció',
      nameAndSurname: 'Név és vezetéknév',
      placeholderNameAndSurname: 'XXXXXXXXXXX',
      email: 'E-mail cím',
      placeholderEmail: 'pl. pelda@synthiaiapp.com',
      password: 'Jelszó',
      start: 'FIÓK LÉTREHOZÁSA',
      subtitle:
        'A jelszónak legalább 8 karakter hosszúnak kell lennie, tartalmaznia kell kis- és nagybetűket, valamint egy számot.',
      passwordError1: 'A jelszónak legalább 8 karaktert kell tartalmaznia.',
      passwordError2: 'A jelszónak legalább 1 nagybetűt kell tartalmaznia.',
      passwordError3: 'A jelszónak legalább 1 kisbetűt kell tartalmaznia.',
      passwordError4: 'A jelszónak legalább 1 számot kell tartalmaznia.',
      passwordError5: 'A jelszavaknak meg kell egyezniük.',
    },
    stepTwo: {
      title: 'Válassz egy tervet',
      month: 'hónap',
      months: 'hónapok',
      months2: 'hónap',
      value: '€ / hónap',
      pick: 'kiválaszt',
      nowPay: 'Azonnali fizetés',
      saveOne: 'Megment',
      buy: 'Vásárlás',
      savePayment: 'Biztonságos fizetés a következőn keresztül',
    },
    stepThree: {
      title: 'Összegzés',
      product: 'Termék:',
      plan: 'Synthi AI Csomag',
      forLong: 'Hozzáférés',
      data: 'dátum:',
      nameAndSurname: 'Név és vezetéknév:',
      paymentForm: 'Fizetési mód:',
      toPay: 'Fizetendő:',
      accept: 'Elfogadom',
      rules: 'feltételeket',
      payingAccept: 'A fizetéssel elfogadod a',
      paymentRules: '"PayU Fizetési Feltételek"',
      buyAndPay: 'VÁSÁRLÁS ÉS FIZETÉS',
    },
  },
  succesPayment: {
    title: 'Sikeres fizetés!',
    subtitle: 'Hamarosan átirányítunk a bejelentkezési oldalra.',
  },
  firstLogin: {
    createPasswordForAcoount: 'Hozzon létre egy jelszót a fiókjához',
    typeEmail: 'Adja meg az e-mail címét',
    useSameEmail: 'Használja ugyanazt a címet, amelyet a vásárláskor adott meg',
    typePassword: 'Írja be a jelszavát',
    confirmPassword: 'Erősítse meg a jelszavát',
    createPassword: 'Jelszó létrehozása',
    errorLogin: 'Ellenőrizze, hogy helyes-e a jelszava vagy e-mail címe',
    createdPassword: 'Már létrehozott egy jelszót?',
    clickToLogin: 'Kattintson ide a bejelentkezéshez',
  },
  login: {
    dontHavePassword: 'Még nincs jelszava?',
    getPassword: 'Kattintson ide a hozzáféréshez',
    title: 'Bejelentkezés',
    email: 'E-mail cím',
    password: 'Jelszó',
    textSub: 'Elfelejtetted a jelszavad? Visszaállítás',
    restart: 'itt',
    restartPasswordText:
      'Add meg az e-mail címed a jelszó visszaállításához. A jelszó a bejövő mappádba fog érkezni.',
    placeholder: 'add meg az e-mail címed...',
    resetPassword: 'jelszó visszaállítása',
    buttonText: 'Bejelentkezés',
    errorLogin: 'Kérlek, ellenőrizd az e-mail címed és jelszavad.',
    passwordResterted:
      'A jelszavad visszaállítva. Ellenőrizd az e-mail fiókod.',
    passwordError: 'Sajnáljuk, nem találtuk az e-mail címed.',
    mobileAppBannerText: 'Fedezze fel az alkalmazásunkat!',
  },
  navBar: {
    home: 'Start',
    learn: 'Tanulás',
    profile: 'Profil',
  },
  home: {
    hello: 'Szia',
    question: 'Miről szeretnél ma beszélgetni?',
    answer: 'Indíts egy beszélgetést',
    forYou: 'Neked ajánlva',
    popularNow: 'Most népszerű',
    doYouKnow: 'Tudtad, hogy...?',
    funFact: 'Mai érdekesség',
    new: 'Új',
    newTitle: 'Kártyák',
    newSubtitle: 'Új kiadás',
    check: 'Ellenőriz',
    yourFavorite: 'Kedvenceid',
  },
  learn: {
    flashcardV2: {
      new: 'Új',
      sport: 'Sport',
      continue: 'folytatás',
      nice: 'Nagyszerű!',
      congratulation: 'Gratulálok!',
      category: 'Kategória',
      textAfterCategory: 'számodra nem titok!',
      pickNewCategory: 'Válassz új kategóriát',
      showResult: 'Mutasd a megoldást',
      createWordWithLetters: 'Alkoss szót az összekevert betűkből',
      know: 'Tudom',
      dontKnow: 'Nem tudom',
      metchWords: 'Kapcsold össze a szavakat',
      addNewCategory: 'Új kategória hozzáadása',
      personalizeLearning:
        'Személyre szabhatod a tanulást saját kategória létrehozásával.',
      typeNewCategory: 'Írd be a kategóriád nevét',
      save: 'Mentés',
      weAreCreatingCategoryForYou: 'Létrehozzuk az új kategóriádat:',
      start: 'KEZDÉS',
      howToUseFlashcard: 'Hogyan használjuk a kártyákat?',
      knowFlashcard:
        'Kattints a „Nem tudom” vagy „Tudom” gombra a tudásod értékeléséhez.',
      dontKnowFlashcard: 'A „Tudom” jelzésű kártyákat nem ismételjük.',
      clickToHear: 'Kattints a szó meghallgatásához.',
      clickForDefinition: 'Kattints a definíció megtekintéséhez',
      clickForTranslation: 'Kattints a fordítás és példák megtekintéséhez',
      close: 'Bezárás',
      clickCategoryToStart:
        'Kattints a kiválasztott kategóriára a tanulás megkezdéséhez.',
      lookAsProgressBar:
        'A kategória alatti fejlődési sáv mutatja, hány szót sajátítottál el.',
      clickToSetupFavaroiteFlashcard:
        'Kattints a kategória kedvencként való megjelöléséhez.',
      clickToCreateOwnCategory:
        'Kattints saját kártyakategória létrehozásához.',
      howLearningLookLike: 'Hogy néz ki a tanulás?',
      restText:
        ' A tanulás a kártyák átnézéséből és a tudás értékeléséből áll. Emellett egyszerű feladatokat is megoldasz.',
    },
    chat: {
      title: 'Csevegés',
      subtitle: 'Beszélgess bármilyen témáról.',
    },
    fotoMode: {
      title: 'Találd ki a képet',
      subtitle: 'Írd le a képet a lehető legpontosabban.',
    },
    scene: {
      title: 'Jelenetek',
      subtitle: 'Játssz AI tutorral.',
    },
    word: {
      title: 'Szavak',
      subtitle: 'Bővítsd a szókincsed.',
    },
    flashcard: {
      title: 'Memóriakártyák',
      subtitle: 'Ismételj és memorizálj szavakat.',
    },
    seeMore: 'Összes megtekintése',
  },
  common: {
    description:
      'SynthiAi - Az intelligens angoltanulási asszisztensed. Tanulj folyékonyan angolul az AI segítségével. Fedezz fel egy új, hatékony nyelvtanulási módszert. Kezdd el még ma!',
    deletedFromFavorite: 'Eltávolítva a kedvencek közül',
    addedToFavorite: 'Hozzáadva a kedvencekhez',
    flashcards: 'Memóriakártyák',
    flashcards_v2: 'Memóriakártyák',
    scenes: 'Jelenetek',
    words: 'Szavak',
    privacyLink: 'privacy',
    termsLink: 'tos',
    or: 'vagy',
    continueWithFB: 'Folytatás Facebookkal',
  },
  profile: {
    days: [
      'Hétfő',
      'Kedd',
      'Szerda',
      'Csütörtök',
      'Péntek',
      'Szombat',
      'Vasárnap',
    ],
    top: {
      profile: 'Profil',
      accessTo: 'Hozzáférés:',
      profileImage: 'Profilkép',
      nameAndSurname: 'Név és vezetéknév',
      password: 'Jelszó',
      change: 'Módosítás',
      changeProfileImage: 'Profilkép módosítása',
      changeNameAndSurname: 'Név és vezetéknév módosítása',
      changeNameAndSurnamePlaceHolder: 'Írd be a neved',
      save: 'Mentés',
      changePassword: 'Jelszó módosítása',
      passwordInfo:
        'A jelszónak legalább 8 karakter hosszúnak kell lennie, tartalmaznia kell kis- és nagybetűket, valamint egy számot.',
      repeatPassword: 'Jelszó ismétlése',
      passwordChanged: 'A jelszó módosítva',
      error: 'Hoppá, valami hiba történt',
      nameChanged: 'Név módosítva',
      profileChanged: 'Profil módosítva',
    },
    plan: {
      yourPlan: 'A te terved',
      extend: 'Terv meghosszabbítása',
      short_extend: 'Meghosszabbítás',
      currentPlan: 'Jelenlegi terv:',
      activeToPlan: 'Aktív addig:',
      currentPlanLong: 'Jelenlegi terved',
    },
    middle: {
      yourMedals: 'Eredményeid',
      oneDay: 'nap',
      twoDays: 'nap',
      words: 'Szavak',
      learningTime: 'Tanulási idő',
      streak: 'Sorozat',
      streakRecord: 'Sorozat rekordja',
      yourRecord: 'Saját rekordod:',
      currentRecord: 'Jelenlegi rekord:',
      wordsInWeek: 'Heti szavak:',
    },
    bottom: {
      yourTutor: 'AI tutorod',
      change: 'Változtatás',
      logout: 'Kijelentkezés',
      FAQ: 'GYIK',
      shareOpinion: 'Oszd meg a véleményed',
      yourPlan: 'Terved',
      hobby: 'Hobbi',
      learningSetting: 'Tanulási beállítások',
      back: 'Vissza',
      dayTarget: 'Napi cél',
      languageLevel: 'Nyelvi szint',
      changeYourLangLevel: 'Nyelvi szinted módosítása',
      pickNewTutor: 'Új tutor kiválasztása',
      yourDecision: 'Döntésed befolyásolja az órák stílusát és hangulatát.',
      tutroChanged: 'Tutor megváltoztatva',
      error: 'Hoppá, valami hiba történt',
      englishLevelChanged: 'A nyelvi szinted megváltoztatva',
      levelsOptions: [
        {
          name: 'Kezdő A1',
          description: 'Kezdd el az angoltanulást',
          value: 'basic',
          borderRadius: '8px 8px 0 0',
        },
        {
          name: 'Alap A2',
          description:
            'Megértesz alapvető információkat egyszerű, mindennapi beszélgetésekben',
          borderRadius: '0',
          value: 'beginner',
        },
        {
          name: 'Középhaladó B1',
          description:
            'Egyszerű, de érthető beszélgetéseket tudsz folytatni ismert témákban.',
          borderRadius: '0',
          value: 'intermediate',
        },
        {
          name: 'Haladó B2',
          description: 'A legtöbb helyzetben folyékonyan tudsz kommunikálni',
          borderRadius: '0',
          value: 'advanced',
        },
        {
          name: 'Szakértő C1+',
          description: 'Hatékonyan használod a nyelvet bonyolult helyzetekben',
          value: 'expert',
          borderRadius: '0 0 8px 8px',
        },
      ],
      changeDailyTarget: 'Napi tanulási cél módosítása.',
      deletedFromFavorite: 'Eltávolítva a kedvencek közül',
      addedToFavorite: 'Hozzáadva a kedvencekhez',
      lessonTime: [
        {
          name: 'Gyors lecke',
          dsecription: 'Ideális választás a gyors tanuláshoz',
          time: '5 perc',
          value: 'five_minutes',
          borderRadius: '8px 8px 0 0',
        },
        {
          name: 'Standard óra',
          dsecription: 'Kiegyensúlyozott tanulási élmény',
          time: '10 perc',
          value: 'ten_minutes',
          borderRadius: '0',
        },
        {
          name: 'Intenzív gyakorlás',
          dsecription: 'Legjobb választás a lelkes tanulóknak',
          time: '15 perc',
          value: 'fifteen_minutes',
          borderRadius: '0 0 8px 8px',
        },
      ],
      dailyTargetChanged: 'Napi cél módosítva!',
      pickIntresets: 'Válassz legalább 3 hobbit.',
      activeTo: 'Terved aktív addig:',
      daysLeft: 'Terved hátralévő napjai:',
      opinionSubtitle:
        'Folyamatosan fejlesztjük alkalmazásunkat. Oszd meg véleményed, hogy mit változtatnál az alkalmazásban vagy mi tetszik benne a legjobban.',
      startTyping: 'Kezdj el gépelni',
      faq: 'GYIK',
      send: 'Küldés',
      withProblems: 'Ha kérdésed/problémád van, lépj kapcsolatba velünk:',
      contactMail: 'contact@synthiaiapp.com',
      faqConfig: [
        {
          id: 1,
          question: 'Nem működik a hang.',
          answer:
            'Ha problémád van a hanggal, próbáld meg az alábbiakat:\n\nEllenőrizd az audio engedélyeket a böngészőben.\n\nGyőződj meg róla, hogy az eszközöd hangereje megfelelő.\n\nEllenőrizd, hogy a hangszórók/fülhallgatók megfelelően csatlakoznak-e.\n\nNézd meg a rendszer hangbeállításait, hogy nincs-e elnémítva valamelyik alkalmazás.\n\nPróbáld ki egy másik eszközön vagy lépj kapcsolatba a technikai támogatással.',
        },
        {
          id: 2,
          question: 'Nem tudok hangot rögzíteni.',
          answer:
            'Ha nem tudsz hangot rögzíteni, próbáld meg az alábbiakat:\n\nEllenőrizd a mikrofon engedélyeket a böngészőben.\n\nGyőződj meg róla, hogy a mobil böngésző hozzáférhet a mikrofonhoz.\n\nEllenőrizd, hogy a mikrofon megfelelően csatlakozik-e.\n\nPróbálj ki egy másik eszközt vagy lépj kapcsolatba a technikai támogatással.',
        },
        {
          id: 3,
          question: 'Használhatom az alkalmazást több eszközön is?',
          answer:
            'Természetesen, jelentkezz be bármelyik kompatibilis eszközön, hogy zökkenőmentesen tanulhass.',
        },
        {
          id: 4,
          question: 'Használhatom a Synthi AI-t internetkapcsolat nélkül?',
          answer:
            'Nem, a Synthi AI egy internetkapcsolattal rendelkező web böngészőt igényel.',
        },
        {
          id: 5,
          question: 'Hogyan változtathatom meg az AI tutoromat?',
          answer:
            "Menj a 'Tutor' fülre a Synthi AI beállításaiban. Böngéssz és válassz egy másik tutort.",
        },
        {
          id: 6,
          question: 'Hogyan újíthatom meg a hozzáférésem a lejárat után?',
          answer: 'A lejárat után bejelentkezve újíthatod meg a terved.',
        },
        {
          id: 7,
          question: 'Kaphatok számlát?',
          answer:
            'Írj a contact@synthiaiapp.com címre, és add meg az adószámodat és a céged adatait.',
        },
        {
          id: 8,
          question: 'Hogyan léphetek kapcsolatba a technikai támogatással?',
          answer:
            'Írj egy e-mailt a contact@synthiaiapp.com címre a probléma részleteivel, az eszköz és böngésző információkkal.',
        },
      ],
    },
    rules: 'feltételek',
    privacy: 'Adatvédelmi irányelvek',
    span: ' és ',
  },
  welcome: {
    languagePicker: {
      title: 'Válaszd ki, melyik nyelvet szeretnéd tanulni',
      next: 'Tovább',
      langToLearn: 'Tanulandó nyelv',
      successTitle: 'A tanulandó nyelv módosítva lett',
      config: [
        {
          label: 'Angol',
          label2: 'Angol',
          labelInside: 'angolt',
          value: 'en',
        },
        {
          label: 'Német',
          label2: 'Német',
          labelInside: 'németet',
          value: 'de',
        },
        {
          label: 'Spanyol',
          label2: 'Spanyol',
          labelInside: 'spanyolt',
          value: 'es',
        },
        {
          label: 'Francia',
          label2: 'Francia',
          labelInside: 'franciát',
          value: 'fr',
        },
        {
          label: 'Olasz',
          label2: 'Olasz',
          labelInside: 'olaszt',
          value: 'it',
        },
      ],
    },
    zeroScreen: {
      welcomeInApp: 'Üdvözlünk a Synthi AI alkalmazásban!',
      start: 'KEZDÉS',
      welcomeMobile: 'Üdvözlünk',
      config: {
        chrome: {
          title: 'Telepítsd az alkalmazást az Android készülékedre.',
          text1: 'A Chrome böngészőben kattints a',
          text1bold: ' Beállítások',
          image1: Android1,
          text2: 'Ezután kattints a',
          text2bold: ' Hozzáadás a kezdőképernyőhöz',
          image2: Android2,
          text3: 'Kész! A Synthi AI most már a kezdőképernyődön van',
          image3: Last,
        },
        safari: {
          title: 'Telepítsd az alkalmazást az iOS készülékedre.',
          text1: 'A Safari böngészőben kattints a',
          text1bold: ' Megosztás',
          image1: Ios1,
          text2: 'Ezután kattints a',
          text2bold: ' Hozzáadás a kezdőképernyőhöz',
          image2: Ios2,
          text3: 'Kész! A Synthi AI most már a kezdőképernyődön van',
          image3: Last,
        },
      },
    },
    firstScreen: {
      title: 'Válaszd ki a tutorodat!',
      subtitle: 'A választásod hatással lesz az órák stílusára és hangulatára.',
      next: 'TOVÁBB',
    },
    secondScreen: {
      title: 'Mi a jelenlegi {language} nyelvi szinted?',
      config: [
        {
          name: 'Alap A1',
          description: 'Kezdd el az {language}.',
          value: 'basic',
        },
        {
          name: 'Alap A2',
          description:
            'Megértesz alapvető információkat egyszerű beszélgetésekben',
          value: 'beginner',
        },
        {
          name: 'Középhaladó B1',
          description:
            'Egyszerű, de érthető beszélgetéseket tudsz folytatni ismert témákban.',
          value: 'intermediate',
        },
        {
          name: 'Haladó B2',
          description: 'A legtöbb helyzetben folyékonyan tudsz kommunikálni',
          value: 'advanced',
        },
        {
          name: 'Szakértő C1+',
          description:
            'Bonyolult helyzetekben is hatékonyan használod a nyelvet',
          value: 'expert',
        },
      ],
      next: 'TOVÁBB',
    },
    thirdScreen: {
      title: 'Mik a hobbiid?',
      subtitle: 'Válassz legalább 3 hobbit.',
      next: 'TOVÁBB',
    },
    fourthScreen: {
      title: 'Hány percet szeretnél naponta tanulni?',
      subtitle: 'Válaszd ki a napi célodat.',
      next: 'KEZDÜNK!',
      config: [
        {
          name: 'Gyors lecke',
          dsecription: 'Ideális választás a gyors tanuláshoz',
          time: '5 perc',
          value: 'five_minutes',
        },
        {
          name: 'Standard óra',
          dsecription: 'Kiegyensúlyozott tanulási élmény',
          time: '10 perc',
          value: 'ten_minutes',
        },
        {
          name: 'Intenzív gyakorlás',
          dsecription: 'Legjobb választás a lelkes tanulóknak',
          time: '15 perc',
          value: 'fifteen_minutes',
        },
      ],
    },
  },
  features: {
    topbar: {
      back: 'Vissza',
    },
    fotoMode: {
      welcome: {
        title: 'Kép kitalálása',
        subtitle:
          'Írd le, amit a képen látsz a kiválasztott témában a lehető legpontosabban.',
        start: 'Kezdés!',
      },
      info: {
        startTyping: 'Kezdj el gépelni...',
        newTask: 'Új feladat',
        send: 'Küldés',
      },
    },
    flashcards: {
      title: 'Kártyák',
      subtitleTop: 'Tanulj és memorizálj új szavakat interaktív kártyákkal.',
      addCategory: 'Adj hozzá saját kategóriát',
      subtitle: 'Írd be a kategória nevét',
      type: 'írd be a kategóriát...',
      add: 'Hozzáadás',
      previous: 'Előző kártya',
      task: 'Feladat',
      changeLevel: 'Szint váltása',
      dontKnow: 'Nem tudom',
      know: 'Tudom',
      pickPairs: 'Párok kiválasztása',
      goodJob: 'Szép munka!',
      titleEnd: 'Lenyűgöző az elkötelezettséged',
      subtitleEnd: 'Készen állsz további kihívásokra?',
      repeat: 'Kategória ismétlése',
      other: 'Válassz másik kategóriát',
      level: {
        title: 'Kártyaszint váltása',
        beginner: 'Kezdő A1-A2',
        intermediate: 'Középhaladó B1-B2',
        advance: 'Haladó C1-C2',
        change: 'Váltás',
      },
    },
    scene: {
      title: 'Jelenetek',
      subtitle: 'Játssz el egy jelenetet AI tutorral a választott témában.',
      suggest: 'Új jelenet javaslása',
      talk: 'Beszélgetés',
      deletedFromFavorite: 'Eltávolítva a kedvencek közül',
      addedToFavorite: 'Hozzáadva a kedvencekhez',
    },
    word: {
      title: 'Szavak',
      subtitle: 'Bővítsd a szókincsed kulcsfontosságú területeken AI tutorral.',
      suggest: 'Új szótéma javaslása',
      deletedFromFavorite: 'Eltávolítva a kedvencek közül',
      addedToFavorite: 'Hozzáadva a kedvencekhez',
    },
  },
  cookie: {
    title: 'Cookie tájékoztató',
    subtitle:
      'Ez a weboldal cookie-kat használ, hogy jobb felhasználói élményt biztosítson és személyre szabott tartalmakat kínáljon. Ha többet szeretnél megtudni erről, olvasd el az adatvédelmi szabályzatunkat.',
    link: 'adatvédelmi irányelvek',
    accept: 'ELFOGADOM',
    denied: 'ELUTASÍTOM',
  },
  talkingBar: {
    allowMicrofon: 'Kérlek, engedélyezd a mikrofon használatát',
    typeMessage: 'Írj egy üzenetet...',
    record: 'Felvétel',
    recording: 'Felvétel folyamatban',
  },
  hands: {
    giveUsInfo: 'Oszd meg velünk a véleményed',
    thanksForGrading: 'Köszönjük az értékelést!',
    subtitle: 'Fontos számunkra a véleményed!',
    subtitle2: 'Mondd el, mit gondolsz erről a leckéről:',
    skip: 'Kihagyás',
    send: 'Küldés',
  },
  suggestConfig: {
    profile: {
      title: 'Oszd meg a véleményed!',
      subtitle:
        'Folyamatosan fejlesztjük alkalmazásunkat. Oszd meg véleményed, hogy mit változtatnál az alkalmazásban vagy mi tetszik benne a legjobban.',
    },
    word: {
      title: 'Új szótéma javaslása',
      subtitle: '',
    },
    scene: {
      title: 'Új jelenet javaslása',
      subtitle: '',
    },
    startTyping: 'Kezdj el gépelni',
    send: 'KÜLDÉS',
    thx: 'Vélemény elküldve! Köszönjük!',
    error: 'Hoppá! Valami hiba történt',
  },
  checkout: {
    emailGuessText: 'Ezt szeretted volna írni?',
    leftTitle: 'Válassz egy tervet magadnak',
    rightTitle: 'Adatok kitöltése',
    subscribe: 'Előfizet',
    total: 'Összesen',
    currencySign: 'Ft',
    currency: 'huf',
    accept: 'Elfogadom a ',
    space: ' és ',
    email: 'Email',
    placeHolderEmail: 'Adja meg az e-mail címét',
    rules: 'felhasználási feltételeit',
    tos: 'adatvédelmi irányelveit',
    savePayments: 'Biztonságos fizetéseket kezeli',
  },
  dailyV2: {
    conversation: {
      doYouWantContinueConversation:
        'Folytatni szeretné a beszélgetést, vagy újat kezdeni?',
      continueConversation: 'Beszélgetés folytatása',
      newConversation: 'Új beszélgetés',
    },
    hint: {
      title: 'Tipp',
      pick: 'Választás',
    },
    feedback: {
      yourMessage: 'Az üzeneted',
      goodMessage: 'Helyes üzenet',
      explanation: 'Magyarázat',
    },
    tutorial: {
      title: 'Hogyan használd a csevegést?',
      skip: 'Kihagyás',
      next: 'TOVÁBB',
      start: 'Kezdés',
      screenConf: [ScreenOne, ScreenTwo, ScreenThree, ScreenFour],
      config: [
        [
          {
            title: 'Kattints a tutor hangjának némításához',
            image: VolumeUp,
          },
          {
            title: 'Kattints a véleményed megosztásához',
            image: HandsUp,
          },
          {
            title: 'Kattints az útmutató megnyitásához',
            image: Tutorial,
          },
        ],
        [
          {
            title: 'Kattints az újrahallgatáshoz',
            image: VolumeUpBlank,
          },
          {
            title: 'Kattints a fordítás megtekintéséhez',
            image: TranslationBlank,
          },
          {
            title: 'Kattints a tipp megtekintéséhez',
            image: HintBlank,
          },
          {
            title: 'Kattints a probléma jelentéséhez',
            image: InfoBlank,
          },
        ],
        [
          {
            title: 'Az üzeneted helyes',
            image: GreenCircle,
          },
          {
            title: 'Kattints a kijavított válasz és magyarázat megtekintéséhez',
            image: YellowCircle,
          },
        ],
        [
          {
            title: 'Kattints az üzenet írásához',
            image: '',
          },
          {
            title: 'Kattints az üzenet felvételéhez',
            image: Microphone,
          },
        ],
      ],
    },
  },
  problem: {
    title: 'Probléma jelentése',
    subtitle: 'Írd le az AI üzenetével kapcsolatos problémát',
    send: 'Küldés',
    messageSend: 'Üzenet elküldve',
    error: 'Valami hiba történt, írj nekünk :)',
  },
};
