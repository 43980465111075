import React from 'react';
import styled from 'styled-components';
import Hint from '../images/hint.svg';
import { useLanguageData } from '@/common/hooks/useLanguageData';

const PosibleHintsWrapper = styled.div`
  width: 40px;
  height: 40px;
  flex-shrink: 0;

  border-radius: 53px;
  background: #ece5f2;

  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;

  margin-top: 30px;
`;

const HintsCounter = styled.div`
  display: flex;
  width: 18px;
  height: 18px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 1px solid #8477af;
  background: #fff;

  color: #29185e;
  text-align: center;
  font-family: 'Work Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 16.8px */
  position: absolute;

  top: -8px;
  right: -8px;
`;

const PosibleHintsWrapperAll = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;

  border-radius: 53px;
  background: #ece5f2;
  width: 280px;
  padding: 7px 0;

  color: #29185e;
  text-align: center;
  font-family: 'Work Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 19.6px */
  margin-top: 30px;
`;

const PosibleHints = ({ posibleHints, handleHint }) => {
  const translation = useLanguageData('learn.flashcardV2');

  if (posibleHints < 0) {
    return <></>;
  }

  if (posibleHints === 0) {
    return (
      <PosibleHintsWrapperAll onClick={handleHint}>
        {translation.showResult}
      </PosibleHintsWrapperAll>
    );
  }

  return (
    <PosibleHintsWrapper onClick={handleHint}>
      <img src={Hint} />
      <HintsCounter>{posibleHints}</HintsCounter>
    </PosibleHintsWrapper>
  );
};

export default PosibleHints;
