import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  ActionsWrapper,
  Card,
  EyeIconWrapper,
  Input,
  InputWrapper,
  InputsContainer,
  Label,
  Span,
  SubmitButton,
  Title,
  Wrapper,
} from './firstLogin.styles';
import { Link, useNavigate } from 'react-router-dom';
import { useMutation } from 'react-query';
import { firstLogin, login, userLoginVerfyfication } from '@/api/query/auth';
import userManager from '@/api/userManager';
import api from '@/api/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
import Loading from '@/common/components/Loading';
import { MessageContext } from '@/common/contexts/message-context/MessageContext';
import LoginImage from './images/login.png';
import { IpStatsContext } from '@/common/contexts/IpStatsContext';
import { EventService } from '@/common/services/eventService';
import { EventNamesEnum } from '@/common/enums/eventNamesEnum';
import { IP_STATS_ENUM } from '@/common/enums/ipStatsEnum';
import { useLanguageData } from '@/common/hooks/useLanguageData';
import Logo from '@/common/images/Logo.svg';
import { checkPassword } from '@/common/functions/checkPassword';
import LoginMoveImage from './images/loginMove.svg';
import useQueryMemo from '@/common/hooks/urlMemo';

const FirstLoginTopText = styled.div`
  color: #fff;
  font-family: 'Work Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 19.6px */
`;

const FirstLoginBottomText = styled.div`
  color: #fff;
  font-family: 'Work Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
`;

const FirstLoginImageWrapper = styled.div`
  width: 30px;
  aspect-ratio: 1;

  img {
    width: 100%;
    height: auto;
  }
`;

const FirstLoginLink = styled(Link)`
  display: flex;
  padding: 8px 12px 8px 10px;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  justify-content: space-between;
  background: #29185e;
  margin-top: 32px;
  width: 260px;
  text-decoration: none;
`;

const ImageWrapper = styled.div`
  width: 340px;

  img {
    width: 100%;
    height: auto;
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

const Or = styled.div`
  color: #868686;
  font-family: 'Work Sans';
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 14.3px */
  text-align: center;
`;

const { tokenManager } = api;

const FirstLogin = () => {
  const data = useLanguageData('firstLogin');
  const register = useLanguageData('register.stepOne');
  const queryMemo = useQueryMemo();

  const [labelType, setLabelType] = useState('password');

  const { addMessage } = useContext(MessageContext);
  const { handleSendIPstats } = useContext(IpStatsContext);

  const navigate = useNavigate();
  const emailRef = useRef();
  const passwordRef = useRef();
  const repeatPasswordRef = useRef();

  const firstLoginMutation = useMutation({
    mutationFn: (value) => firstLogin(value),
    onSuccess: ({ data }) => {
      const { token, refreshToken } = data;
      tokenManager.setToken(token);
      tokenManager.setRefreshToken(refreshToken);
      const payload = tokenManager.parseJwt(token);
      userManager.setUser(payload);
      handleSendIPstats(IP_STATS_ENUM.PASSOWRD_GENERETED_SUCCESS);
      return navigate('/');
    },
    onError: () => {
      handleSendIPstats(IP_STATS_ENUM.PASSOWRD_GENERETED_ERROR);
      addMessage(data.errorLogin, 'error');
    },
  });

  const userLoginVerfyficationMutation = useMutation({
    mutationFn: (value) => userLoginVerfyfication(value),
    onSuccess: ({ data }) => {
      if (data.moveTologin) {
        handleSendIPstats(IP_STATS_ENUM.NEXT_TIME_ON_PASSWORD_GENERATION);
        return navigate('/login');
      }
      handleSendIPstats(IP_STATS_ENUM.FIRST_TIME_ON_PASSWORD_GENERATION);
    },
    onError: () => {},
  });

  const handleFirstLogin = (e) => {
    e && e.preventDefault();
    const payload = {};

    payload.email = emailRef.current.value;
    payload.password = passwordRef.current.value;
    payload.repeatPassword = repeatPasswordRef.current.value;

    const passwordCheck = checkPassword(
      payload.password,
      payload.repeatPassword,
    );

    if (!passwordCheck.valid) {
      return addMessage(register[passwordCheck.message], 'error');
    }

    firstLoginMutation.mutate(payload);
  };

  const handleChangeLabel = () => {
    passwordRef?.current?.focus();

    if (labelType === 'password') {
      return setLabelType(() => 'text');
    }
    setLabelType(() => 'password');
  };

  const handleLoadUser = () => {
    const payload = {
      userId: queryMemo.get('session'),
    };

    userLoginVerfyficationMutation.mutate(payload);
  };

  useEffect(() => {
    return EventService.subscribeOnce(EventNamesEnum.TRACING_ID_PROCESSED, () =>
      handleSendIPstats(IP_STATS_ENUM.LOGIN_PAGE_VIEW, null),
    );
  }, []);

  useEffect(() => {
    const session = queryMemo.get('session');

    if (session) {
      handleLoadUser();
    }
  }, [queryMemo.get('session')]);

  return (
    <>
      {firstLoginMutation.isLoading && <Loading />}
      <Wrapper>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <img
            src={Logo}
            onClick={() => navigate('/login')}
            style={{ cursor: 'pointer' }}
          />
        </div>
        <Card onSubmit={handleFirstLogin}>
          <div>
            <Title>{data.createPasswordForAcoount}</Title>
            <InputsContainer>
              <InputWrapper>
                <Label>{data.typeEmail}</Label>
                <Input
                  ref={emailRef}
                  id="email-SynthiAi"
                  type="email"
                  required
                  placeholder="example@synthiaiapp.com"
                />
              </InputWrapper>
              <Span>{data.useSameEmail}</Span>
              <InputWrapper>
                <Label>{data.typePassword}</Label>
                <Input
                  ref={passwordRef}
                  id="pawssword-SynthiAi"
                  type={labelType}
                  autoComplete="synthiai-password"
                  required
                />
                <EyeIconWrapper onClick={handleChangeLabel}>
                  <FontAwesomeIcon
                    tabIndex="none"
                    icon={faEye}
                    style={{
                      color: labelType === 'text' ? ' #F2F0FF' : '#650675',
                    }}
                  />
                </EyeIconWrapper>
              </InputWrapper>
              <Span>{register.subtitle}</Span>
              <InputWrapper>
                <Label>{data.confirmPassword}</Label>
                <Input
                  ref={repeatPasswordRef}
                  id="pawssword-SynthiAi"
                  type={labelType}
                  autoComplete="synthiai-password"
                  required
                />
                <EyeIconWrapper onClick={handleChangeLabel}>
                  <FontAwesomeIcon
                    tabIndex="none"
                    icon={faEye}
                    style={{
                      color: labelType === 'text' ? ' #F2F0FF' : '#650675',
                    }}
                  />
                </EyeIconWrapper>
              </InputWrapper>
            </InputsContainer>
            <ActionsWrapper>
              <SubmitButton type="submit">{data.createPassword}</SubmitButton>
            </ActionsWrapper>
            <FirstLoginLink to={'/login'}>
              <FirstLoginImageWrapper>
                <img src={LoginMoveImage} />
              </FirstLoginImageWrapper>
              <div>
                <FirstLoginTopText>{data.createdPassword}</FirstLoginTopText>
                <FirstLoginBottomText>{data.clickToLogin}</FirstLoginBottomText>
              </div>
            </FirstLoginLink>
          </div>
          <ImageWrapper>
            <img alt="" src={LoginImage} />
          </ImageWrapper>
        </Card>
      </Wrapper>
    </>
  );
};

export default FirstLogin;
