import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useRef,
} from 'react';
import { styled } from 'styled-components';
import { useMutation } from 'react-query';
import BotMessage from '../Message/BotMessage';
import TalkingMicrofon from './TalkingMicrofon';
import UserMessage from './UserMessage';
import BotMessageTalking from './BotMessageTalking';
import { Howler } from 'howler';
import {
  generateHint,
  getTranslationDaily,
  getVoiceDaily,
} from '@/api/query/workflow-daily';
import { DemoConversationContext } from '@/common/contexts/DemoConversation';
import ProblemView from '@/common/components/customer/ProblemView';
import { messageDataWrapHandlers } from '../../messageDataReducer/messageDataWrapHandlers';
import { messageDataReducer } from '../../messageDataReducer/messageDataReducer';
import { messageDataInitObject } from '../../messageDataReducer/messageDataInitObject';
import { voiceRunner } from '../../functions/voiceRunner';
import { SOURCES_TYPES } from '@/common/components/customer/NewHands';
import ContinuePopUp from '../ContinuePopUp';
import { DEVICE_TYPE } from '@/common/constants/types';

export const TALKING_ROLES = {
  USER: 'user',
  BOT: 'bot',
};

export const TextInformationWrapper = styled.div`
  display: flex;
  justify-content: right;
  margin-top: 10px;
`;

const TalkiMessage = ({}) => {
  const {
    dailyConversation,
    showContinuePopUp,
    handleDailyConversationMutation,
    setShowContinuePopUp,
    setDailyConversation,
    userVoice,
  } = useContext(DemoConversationContext);

  const [messageData, dispatchMessageData] = useReducer(
    messageDataReducer,
    messageDataInitObject(
      dailyConversation?.customer_message
        .filter((c) => c.role !== 'system')
        .map((c, i) => ({
          index: i,
          message: c.content,
          role: c.role === 'assistant' ? TALKING_ROLES.BOT : TALKING_ROLES.USER,
        })),
    ),
  );

  const handlers = messageDataWrapHandlers(dispatchMessageData);

  const handleGetVoiceMutation = useMutation({
    mutationFn: (value) => getVoiceDaily(value),
    onSuccess: async ({ data }) => {
      handlers.setLoadingVoice(false);
      handlers.setRecordings({
        name: data.name,
        value: data.data.data,
      });

      await voiceRunner(data.data.data, data.name, handlers.setVoiceTalking);
    },
    onError: () => {
      handlers.setLoadingVoice(false);
    },
  });

  const translationMutation = useMutation({
    mutationFn: (value) => getTranslationDaily(value),
    onSuccess: ({ data }) => {
      handlers.addShowMessagesTranslations(data.name);
      handlers.removeLoadingTranslations(data.name);
      handlers.setTrnaslationMessages({
        name: data.name,
        data: data.translation,
      });
    },
  });

  const handleGenerateHintMutation = useMutation({
    mutationFn: (value) => generateHint(value),
    onSuccess: ({ data }) => {
      handlers.setIsHintLoading(false);
      handlers.setHintData(data);
    },
    onError: () => {
      handlers.setIsHintLoading(false);
    },
  });

  const handleGetVoice = async (name, message) => {
    const voice = messageData.recordings.find((tm) => tm.name === name);
    Howler?.unload();

    if (messageData.voiceTalking) {
      handlers.setVoiceTalking(false);
      return;
    }

    if (voice) {
      return await voiceRunner(
        voice.value,
        voice.name,
        handlers.setVoiceTalking,
      );
    }

    handlers.setLoadingVoice(name);

    const payload = {};
    payload.message = message;
    payload.name = name;

    handleGetVoiceMutation.mutate(payload);
  };

  const handleGetTranlationForBot = (name, message) => {
    const translation = messageData.translationMessages.find(
      (tm) => tm.name === name,
    );

    if (translation) {
      if (messageData.showMessagesTranslations.includes(name)) {
        handlers.removeShowMessagesTranslations(name);
      } else {
        handlers.addShowMessagesTranslations(name);
      }

      return;
    }

    const payload = {};
    payload.message = message;
    payload.name = name;
    payload.convId = dailyConversation._id;

    handlers.addLoadingTranslations(name);

    translationMutation.mutate(payload);
  };

  const handleGetHint = () => {
    handlers.setIsHintLoading(true);

    const payload = {
      conversationId: dailyConversation._id,
    };

    handleGenerateHintMutation.mutate(payload);
  };

  const previousMessages = useMemo(
    () =>
      messageData.conversationWithChatGPT.map((element, i) =>
        element.role === TALKING_ROLES.BOT ? (
          <BotMessageTalking
            key={i}
            id={i}
            message={element.message}
            handlers={handlers}
            messageData={messageData}
            handleGetTranlationForBot={handleGetTranlationForBot}
            handleGetVoice={handleGetVoice}
            isLastMessage={messageData.conversationWithChatGPT.length - 2 < i}
            handleGetHint={handleGetHint}
          />
        ) : (
          <UserMessage
            message={element.message}
            key={i}
            userMessageId={`user-message-id-${i + 1}`}
            messageScore={messageData.messageScore}
            handleShowBadMessage={handlers.setShowBadMessage}
          />
        ),
      ),
    [
      messageData.conversationWithChatGPT,
      messageData.translationMessages,
      messageData.handleGetTranlationForBot,
      messageData.showMessagesTranslations,
      messageData.loadingTranslations,
      messageData.loadingVoice,
      messageData.voiceTalking,
      messageData.isHintLoading,
      messageData.messageScore,
    ],
  );

  const currentRef = useRef();

  useEffect(() => {
    const element = document.getElementById(`bottom-info`);
    element?.scrollIntoView({ behavior: 'smooth' });
  }, [
    messageData.current,
    messageData.conversationWithChatGPT.length,
    currentRef?.current?.clientHeight,
  ]);

  return (
    <>
      {previousMessages}
      {messageData.current && (
        <div
          ref={currentRef}
          style={{
            minHeight: '60px',
          }}
        >
          <BotMessage text={messageData.current} showTranslateIcon={false} />
        </div>
      )}
      {showContinuePopUp && (
        <ContinuePopUp
          handleDailyConversationMutation={handleDailyConversationMutation}
          setClose={setShowContinuePopUp}
          setDailyConversation={setDailyConversation}
          type={DEVICE_TYPE.MOBILE}
        />
      )}
      <TalkingMicrofon
        setCurrent={handlers.setCurrent}
        current={messageData.current}
        setConversationWithChatGPT={handlers.setConversationWithChatGPT}
        conversationWithChatGPT={messageData.conversationWithChatGPT}
        hintData={messageData.hintData}
        setMessageScore={handlers.setMessageScore}
        setHintData={handlers.setHintData}
        setShowBadMessage={handlers.setShowBadMessage}
        showBadMessage={messageData.handleShowBadMessage}
      />
      {messageData.showProblem && (
        <ProblemView
          chatMessage={messageData.showProblem}
          setShowProblem={handlers.setShowProblem}
          type={SOURCES_TYPES.DAILY_V2}
          userVoice={userVoice}
        />
      )}
    </>
  );
};

export default TalkiMessage;
