import { createCustomerCategory } from '@/api/query/flashcard-category-v2';
import PopUp from '@/common/components/PopUp';
import { MessageContext } from '@/common/contexts/message-context/MessageContext';

import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useMutation } from 'react-query';
import styled from 'styled-components';
import { MAX_PERCENT } from '../constants/max-precent';
import { useNavigate } from 'react-router-dom';
import { useLanguageData } from '@/common/hooks/useLanguageData';

const Title = styled.div`
  color: var(--Text-color, #29185e);
  text-align: center;
  font-family: 'Work Sans';
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 28px */
  margin-top: 20px;
`;

const SubTitle = styled.div`
  color: var(--Text-color, #29185e);
  text-align: center;
  font-family: 'Work Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 19.6px */
  max-width: 280px;
  margin: 0 auto;
  margin-top: 10px;
`;

const CategoryName = styled.div`
  color: var(--Text-color, #29185e);
  text-align: center;
  font-family: 'Work Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 19.6px */
  margin-top: 10px;
`;

const StyledInput = styled.input`
  border-radius: 8px;
  background: #f4f6f8;
  padding: 10px 0px 10px 8px;
  width: 300px;
  margin-top: 22px;

  color: #868686;
  font-family: 'Work Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 19.6px */

  border: none;
`;

const StyledButton = styled.button`
  border-radius: 100px;
  background: var(
    --Purple,
    linear-gradient(91deg, #4f17aa 1.48%, #ac02f1 99.24%)
  );

  color: #fff;
  text-align: center;
  font-family: 'Work Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.64px;
  text-transform: uppercase;
  padding: 8px;
  min-width: 180px;
  margin-top: 28px;
  margin-bottom: 20px;

  &:disabled {
    background: #d9d9d9 !important;
  }
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const TitlePending = styled.div`
  color: var(--Text-color, #29185e);
  text-align: center;
  font-family: 'Work Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%; /* 18.2px */
  max-width: 180px;
  margin: 0 auto;
  margin-top: 30px;
`;

const CREATING_STATUS = {
  NONE: 'none',
  PENDING: 'pending',
  SUCCESS: 'success',
};

const Wrapper = styled.div`
  max-width: 320px;
  width: 90vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ProgressBarContainer = styled.div`
  width: 172px;
  height: 18px;
  background: #f4f6f8;
  border-radius: 63px;
  overflow: hidden;
  margin: 10px 0;
`;

const ProgressBarFilled = styled.div`
  width: ${(props) => props.width}%;
  height: 100%;
  background: linear-gradient(91deg, #4f17aa 1.48%, #ac02f1 99.24%);
  transition: width 0.3s ease;
`;

const NewCategory = ({ setShow }) => {
  const categoryNameRef = useRef();
  const [category, setCategory] = useState();
  const [generatedCategory, setGeneratedCategory] = useState();
  const [creatingStatus, setCreatingStatus] = useState(CREATING_STATUS.NONE);
  const { addMessage } = useContext(MessageContext);
  const [currentPercent, setCurrentPercent] = useState(0);
  const navigate = useNavigate();

  const translation = useLanguageData('learn.flashcardV2');

  const createCustomerCategoryMutation = useMutation({
    mutationFn: (payload) => createCustomerCategory(payload),
    onSuccess: ({ data }) => {
      setCreatingStatus(() => CREATING_STATUS.SUCCESS);
      setGeneratedCategory(() => data);
    },
    onError: () => {
      addMessage(
        'Niestety nie udało nam się wygenerować dla Ciebie kategorii',
        'error',
      );
      setShow(() => false);
    },
  });

  const handleCreateCustomerCategory = (e) => {
    e?.preventDefault();

    const currentCategory = categoryNameRef.current.value;

    const payload = {
      categoryName: currentCategory,
    };

    setCategory(() => currentCategory);
    createCustomerCategoryMutation.mutate(payload);
    setCreatingStatus(() => CREATING_STATUS.PENDING);
  };

  const getCurentPercent = useMemo(() => {
    if (creatingStatus === CREATING_STATUS.SUCCESS) {
      return 100;
    }

    if (createCustomerCategoryMutation.isLoading && currentPercent >= 99) {
      return 99;
    }

    return currentPercent;
  }, [currentPercent, creatingStatus]);

  useEffect(() => {
    let interval;

    if (creatingStatus === CREATING_STATUS.PENDING) {
      interval = setInterval(() => {
        setCurrentPercent((prev) => Math.min(MAX_PERCENT, prev + 1));
      }, (15 / 100) * 1000);
    }

    return () => clearInterval(interval);
  }, [creatingStatus]);

  return (
    <PopUp setClose={setShow}>
      <Wrapper>
        {creatingStatus === CREATING_STATUS.NONE && (
          <>
            <Title>{translation.addNewCategory}</Title>
            <SubTitle>{translation.personalizeLearning}</SubTitle>
            <StyledForm onSubmit={handleCreateCustomerCategory}>
              <StyledInput
                ref={categoryNameRef}
                required
                placeholder={translation.typeNewCategory}
              />
              <StyledButton type="submit">{translation.save} </StyledButton>
            </StyledForm>
          </>
        )}
        {[CREATING_STATUS.PENDING, CREATING_STATUS.SUCCESS].includes(
          creatingStatus,
        ) && (
          <>
            <TitlePending>
              {translation.weAreCreatingCategoryForYou}
            </TitlePending>
            <CategoryName>{category}</CategoryName>
            <TitlePending style={{ marginTop: '20px' }}>
              {getCurentPercent}%
            </TitlePending>
            <ProgressBarContainer>
              <ProgressBarFilled width={getCurentPercent} />
            </ProgressBarContainer>
            <StyledButton
              type="button"
              onClick={() => {
                navigate(`/learn/flashcards/${generatedCategory}`);
              }}
              disabled={getCurentPercent != 100}
            >
              {translation.start}
            </StyledButton>
          </>
        )}
      </Wrapper>
    </PopUp>
  );
};

export default NewCategory;
