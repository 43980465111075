import React, { useContext } from 'react';
import styled from 'styled-components';
import callmode from '../images/callmode.png';
import { Link } from 'react-router-dom';
import { useLanguageData } from '@/common/hooks/useLanguageData';
import { LanguageContext } from '@/common/contexts/LanguageContext';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
`;

const WrapperMobile = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;

  @media (min-width: 600px) {
    display: none;
  }
`;

const Box = styled(Link)`
  background-image: url(${(props) => props.image});
  background-size: cover;
  background-position: left;
  height: 140px;
  margin-top: 12px;
  border-radius: 8px;
  overflow: hidden;
  text-decoration: none;
  width: 380px;
  margin-left: -12px;
`;

const Subtitle = styled(Link)`
  color: #29185e;
  font-family: 'Work Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 123.5%; /* 17.29px */
  letter-spacing: 0.28px;
  text-align: left;
  text-decoration: none;
  margin-top: -5px;
`;

const TitleTop = styled(Link)`
  color: #29185e;
  font-family: 'Work Sans';
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 25.2px */
  text-align: left;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: left;
  gap: 12px;
`;

const CallModeDesktop = () => {
  const data = useLanguageData('learn.callMode');
  const { lang } = useContext(LanguageContext);

  return (
    <>
      {lang === 'pl' && (
        <>
          <Wrapper>
            <TitleTop to={'/learn/call-mode'}>{data?.title}</TitleTop>
            <Subtitle to={'/learn/call-mode'}>{data?.subtitle}</Subtitle>
            <Box image={callmode} to={'/learn/call-mode'}></Box>
          </Wrapper>
        </>
      )}
    </>
  );
};

export default CallModeDesktop;
