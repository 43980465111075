import React from 'react';
import styled from 'styled-components';
import OkayIcon from '../images/okay-icon.svg';

const ProgressContainer = styled.div`
  width: 144px;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 8px;
  overflow: hidden;
  height: 10px;
  position: absolute;
  bottom: 10px;
  margin-left: 8px;
  z-index: 1;
`;

const ProgressBarFill = styled.div`
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  width: ${(props) => props.percentage}%;
  transition: width 0.3s ease-in-out;
`;

const IconContainer = styled.div`
  position: absolute;
  bottom: 10px;
  right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  z-index: 1;
`;

const ProgressBar = ({ progress, maxProgress }) => {
  if (progress === 0) {
    return <></>;
  }

  if (progress === maxProgress) {
    return (
      <IconContainer>
        <img src={OkayIcon} alt="Completed" />
      </IconContainer>
    );
  }

  const percentage = (progress / maxProgress) * 100;

  return (
    <ProgressContainer
      role="progressbar"
      aria-valuenow={progress}
      aria-valuemin="0"
      aria-valuemax={maxProgress}
    >
      <ProgressBarFill percentage={percentage} />
    </ProgressContainer>
  );
};

export default ProgressBar;
