import Android1 from './images/Android_1_150_SI.png';
import Android2 from './images/Android_Dodaj_150_SI.png';
import Ios1 from './images/IOS_1_150_SI.png';
import Ios2 from './images/IOS_Dodaj_150_SI.png';
import Last from './images/ios_android_3.png';

import VolumeUp from './images/dailyTutorial/volume-up.svg';
import HandsUp from './images/dailyTutorial/hand-up.svg';
import VolumeUpBlank from './images/dailyTutorial/volume-up-blank.svg';
import TranslationBlank from './images/dailyTutorial/icon-translate-blank.svg';
import HintBlank from './images/dailyTutorial/idea-blank.svg';
import InfoBlank from './images/dailyTutorial/information-blank.svg';
import GreenCircle from './images/dailyTutorial/green-circle.svg';
import YellowCircle from './images/dailyTutorial/yelow-circle.png';
import Microphone from './images/dailyTutorial/icon-microphone.svg';
import ScreenOne from './images/dailyTutorial/samouczek_czat_1.png';
import ScreenTwo from './images/dailyTutorial/samouczek_czat_2.png';
import ScreenThree from './images/dailyTutorial/samouczek_czat_3.png';
import ScreenFour from './images/dailyTutorial/samouczek_czat_4.png';
import Tutorial from './images/dailyTutorial/samouczek-fiolet.svg';

export default {
  passwordRestart: {
    title: 'Ponastavi geslo',
    password: 'Geslo',
    repeatPassword: 'Ponovi geslo',
    restartPassword: 'Ponastavi geslo',
  },

  paywall: {
    title: 'Izberi načrt za nadaljevanje učenja',
    buy: 'Kupi',
    savePayment: 'Varna plačila ureja',
    skip: 'Preskoči',
    pickPlan: 'Izberi načrt',
    endPlanFirstPart: 'Tvoj dostop se izteče čez',
    endPlanSecondPart: 'dni.',
    endPlanAdditionalPart: 'dan.',
    paymentTitle: 'Povzetek',
    product: 'Izdelek:',
    plan: 'Paket SynthiAi',
    forLong: 'Dostop za',
    data: 'podatki:',
    nameAndSurname: 'Ime in Priimek:',
    paymentForm: 'Način plačila:',
    toPay: 'Za plačilo danes:',
    accept: 'Sprejmem',
    rules: 'pogoje',
    payingAccept: 'S plačilom sprejemate',
    paymentRules: '"Plačilne pogoje PayU"',
    buyAndPay: 'KUPI IN PLAČAJ',
  },
  paymentStatus: {
    verification: 'Plačilo je še v obdelavi...',
    title: 'Plačilo',
    success: 'uspešno zaključeno!',
    failure: 'ni uspelo!',
    textInfo:
      'Vaše plačilo je bilo uspešno obdelano. Sedaj lahko nastavite svoje geslo s prijavo na našo registracijsko stran. Prejeli boste e-pošto s povezavo za nastavitev gesla v nekaj minutah. Preverite svoj nabiralnik, vključno s predalom za nezaželeno pošto. Za lažje iskanje vnesite "synthiai" v iskalno polje. V primeru težav nas kontaktirajte na: contact@synthiaiapp.com.',
    login: 'Pojdi na prijavo',
  },
  register: {
    steps: {
      register: 'Registracija',
      package: 'Paket',
      summarize: 'Povzetek',
      step: 'Korak ',
    },
    stepOne: {
      password: 'Geslo',
      errorGoogle: 'Prijavite se z e-pošto',
      title: 'Registracija',
      nameAndSurname: 'Ime in Priimek',
      placeholderNameAndSurname: 'npr. Janez Novak',
      email: 'E-pošta',
      placeholderEmail: 'npr. primer@synthiaiapp.com',
      password: 'Geslo',
      start: 'USTVARI RAČUN',
      subtitle:
        'Geslo mora biti dolgo vsaj 8 znakov in vsebovati črke, velike črke in številke.',
      passwordError1: 'Geslo mora imeti vsaj 8 znakov.',
      passwordError2: 'Geslo mora vsebovati vsaj 1 veliko črko.',
      passwordError3: 'Geslo mora vsebovati vsaj 1 malo črko.',
      passwordError4: 'Geslo mora vsebovati vsaj 1 številko.',
      passwordError5: 'Gesli se morata ujemati.',
    },
    stepTwo: {
      title: 'Izberi svoj načrt',
      month: 'Mesec',
      months: 'Meseci',
      months2: 'Meseci',
      value: '€/Mesec',
      pick: 'izberi',
      nowPay: 'Plačaj zdaj',
      saveOne: 'Prihrani',
      buy: 'Kupi',
      savePayment: 'Varna plačila ureja',
    },
    stepThree: {
      title: 'Povzetek',
      product: 'Izdelek:',
      plan: 'Paket Synthi Ai',
      forLong: 'Dostop za',
      data: 'Podatki:',
      nameAndSurname: 'Ime in Priimek:',
      paymentForm: 'Način plačila:',
      toPay: 'Za plačilo danes:',
      accept: 'Sprejmem',
      rules: 'Pogoje',
      payingAccept: 'S plačilom sprejemate',
      paymentRules: '"Plačilne pogoje PayU"',
      buyAndPay: 'KUPI IN PLAČAJ',
    },
  },
  firstLogin: {
    createPasswordForAccount: 'Ustvarite geslo za svoj račun',
    typeEmail: 'Vnesite svoj e-poštni naslov',
    useSameEmail: 'Uporabite isti naslov, ki ste ga navedli ob nakupu',
    typePassword: 'Vnesite svoje geslo',
    confirmPassword: 'Potrdite svoje geslo',
    createPassword: 'Ustvari geslo',
    errorLogin: 'Preverite, če je vaše geslo ali e-pošta pravilna',
    createdPassword: 'Ste že ustvarili geslo?',
    clickToLogin: 'Kliknite tukaj za prijavo',
  },
  login: {
    dontHavePassword: 'Še nimate gesla?',
    getPassword: 'Kliknite tukaj za dostop',
    title: 'Prijava',
    email: 'E-pošta',
    password: 'Geslo',
    textSub: 'Pozabljeno geslo? Ponastavi',
    restart: 'tukaj',
    restartPasswordText:
      'Vnesite svoj e-poštni naslov za ponastavitev gesla. Geslo bo poslano na vaš e-poštni naslov.',
    placeholder: 'Vnesite svoj e-poštni naslov...',
    resetPassword: 'Ponastavi geslo',
    buttonText: 'Prijava',
    errorLogin: 'Preverite, če sta vaše geslo in e-poštni naslov pravilna.',
    passwordRestarted:
      'Vaše geslo je bilo ponastavljeno, preverite svoj e-poštni nabiralnik.',
    passwordError: 'Žal vašega e-poštnega naslova nismo našli.',
    mobileAppBannerText: 'Odkrijte našo aplikacijo!',
  },
  navBar: {
    home: 'Začetek',
    learn: 'Uči se',
    profile: 'Profil',
  },
  home: {
    hello: 'Pozdravljen',
    question: 'O čem bi želeli govoriti danes?',
    answer: 'Začni pogovor',
    forYou: 'Priporočeno za vas',
    popularNow: 'Priljubljeno zdaj',
    doYouKnow: 'Ali ste vedeli, da...?',
    funFact: 'Zanimivost dneva',
    new: 'Novo',
    newTitle: 'Kartice',
    newSubtitle: 'Nova izdaja',
    check: 'Preveri',
    yourFavorite: 'Vaši priljubljeni',
  },
  learn: {
    flashcardV2: {
      new: 'Novo',
      sport: 'Šport',
      continue: 'nadaljuj',
      nice: 'Odlično!',
      congratulation: 'Čestitke!',
      category: 'Kategorija',
      textAfterCategory: 'nima skrivnosti za vas!',
      pickNewCategory: 'Izberite novo kategorijo',
      showResult: 'Prikaži rešitev',
      createWordWithLetters: 'Sestavite besedo iz razmetanih črk',
      know: 'Vem',
      dontKnow: 'Ne vem',
      metchWords: 'Poveži v pare',
      addNewCategory: 'Dodaj novo kategorijo',
      personalizeLearning:
        'Prilagodite učenje z ustvarjanjem lastne kategorije.',
      typeNewCategory: 'Vnesite ime svoje kategorije',
      save: 'Shrani',
      weAreCreatingCategoryForYou: 'Ustvarjamo novo kategorijo za vas:',
      start: 'ZAČNI',
      howToUseFlashcard: 'Kako uporabljati kartice?',
      knowFlashcard: 'Kliknite »Ne vem« ali »Vem«, da ocenite svoje znanje.',
      dontKnowFlashcard: 'Kartice označene kot »Vem« ne bodo ponovljene.',
      clickToHear: 'Kliknite, da slišite besedo.',
      clickForDefinition: 'Kliknite za ogled definicije',
      clickForTranslation: 'Kliknite za ogled prevoda in primerov',
      close: 'Zapri',
      clickCategoryToStart:
        'Kliknite na izbrano kategorijo, da začnete z učenjem.',
      lookAsProgressBar:
        'Vrstica napredka pod kategorijo kaže, koliko besed ste že obvladali.',
      clickToSetupFavaroiteFlashcard:
        'Kliknite za označitev kategorije kot priljubljene.',
      clickToCreateOwnCategory:
        'Kliknite za ustvarjanje lastne kategorije kartic.',
      howLearningLookLike: 'Kako izgleda učenje?',
      restText:
        ' Učenje vključuje pregled kartic in oceno vašega znanja. Poleg tega boste reševali enostavne naloge.',
    },
    chat: {
      title: 'Klepet',
      subtitle: 'Pogovarjajte se o katerikoli temi.',
    },
    fotoMode: {
      title: 'Foto-uganka',
      subtitle: 'Opisi sliko kar se da podrobno.',
    },
    scene: {
      title: 'Scene',
      subtitle: 'Odigrajte vlogo s tutorjem AI.',
    },
    word: {
      title: 'Besede',
      subtitle: 'Razširite svoj besedni zaklad.',
    },
    flashcard: {
      title: 'Kartice',
      subtitle: 'Ponovite in zapomnite si besede.',
    },
    seeMore: 'Poglej več',
  },
  common: {
    description:
      'Synthi AI - Vaš pametni pomočnik za učenje angleščine. Naučite se tekoče govoriti angleško s pomočjo umetne inteligence. Odkrijte nov in učinkovit pristop k učenju jezikov. Začnite še danes!',
    deletedFromFavorite: 'Odstranjeno iz priljubljenih',
    addedToFavorite: 'Dodano med priljubljene',
    flashcards: 'Kartice',
    flashcards_v2: 'Kartice',
    scenes: 'Scene',
    words: 'Besede',
    or: 'ali',
    continueWithFB: 'Nadaljuj s Facebookom',
    privacyLink: 'privacy',
    termsLink: 'tos',
  },
  profile: {
    days: ['Pon', 'Tor', 'Sre', 'Čet', 'Pet', 'Sob', 'Ned'],
    top: {
      profile: 'Profil',
      accessTo: 'Dostop do:',
      profileImage: 'Slika profila',
      nameAndSurname: 'Ime in Priimek',
      password: 'Geslo',
      change: 'Spremeni',
      changeProfileImage: 'Spremeni sliko profila',
      changeNameAndSurname: 'Spremeni svoje ime in priimek',
      changeNameAndSurnamePlaceHolder: 'Vnesi svoje ime in priimek',
      save: 'Shrani',
      changePassword: 'Spremeni svoje geslo',
      passwordInfo:
        'Geslo mora biti dolgo vsaj 8 znakov in vsebovati črke, velike črke in številke.',
      repeatPassword: 'Ponovi geslo',
      passwordChanged: 'Geslo je bilo spremenjeno',
      error: 'Ups, nekaj je šlo narobe',
      nameChanged: 'Ime je bilo spremenjeno',
      profileChanged: 'Profil je bil spremenjen',
    },
    plan: {
      subInfo:
        'Za preklic naročnine pošljite e-pošto na contact@synthiaiapp.com',
      yourPlan: 'Vaš načrt',
      extend: 'Podaljšajte svoj načrt',
      short_extend: 'Podaljšaj',
      currentPlan: 'Trenutni načrt:',
      activeToPlan: 'Aktiven do:',
      currentPlanLong: 'Vaš trenutni načrt',
    },
    middle: {
      yourMedals: 'Vaša priznanja',
      oneDay: 'Dan',
      twoDays: 'Dni',
      words: 'Besede',
      learningTime: 'Čas učenja',
      streak: 'Zaporedje',
      streakRecord: 'Rekord zaporedja',
      yourRecord: 'Vaš rekord:',
      currentRecord: 'Trenutno:',
      words: 'Besede',
      wordsInWeek: 'Število besed na teden:',
    },
    bottom: {
      yourTutor: 'Vaš AI tutor',
      change: 'Spremeni',
      logout: 'Odjava',
      FAQ: 'FAQ',
      shareOpinion: 'Delite svoje mnenje',
      yourPlan: 'Vaš načrt',
      hobby: 'Interesi',
      learningSetting: 'Nastavitve učenja',
      back: 'Nazaj',
      dayTarget: 'Dnevni cilj',
      languageLevel: 'Stopnja jezika',
      changeYourLangLevel: 'Spremenite svojo raven jezika.',
      pickNewTutor: 'Izberite novega tutorja',
      yourDecision: 'Vaša odločitev bo vplivala na slog in vzdušje lekcij.',
      tutorChanged: 'Tutor je bil spremenjen',
      error: 'Ups, nekaj je šlo narobe',
      englishLevelChanged: 'Vaša stopnja je bila spremenjena',
      levelsOptions: [
        {
          name: 'Začetnik A1',
          description: 'Začni svojo pustolovščino z angleščino',
          value: 'basic',
          borderRadius: '8px 8px 0 0',
        },
        {
          name: 'Začetnik A2',
          description: 'Lahko razumete osnovne izraze v preprostih pogovorih.',
          borderRadius: '0',
          value: 'beginner',
        },
        {
          name: 'Srednji B1',
          description: 'Vodite preproste a razumljive pogovore o znanih temah.',
          borderRadius: '0',
          value: 'intermediate',
        },
        {
          name: 'Napredno B2',
          description: 'Komunicirate prosto v večini situacij',
          borderRadius: '0',
          value: 'advanced',
        },
        {
          name: 'Ekspert C1+',
          description: 'Obvladate jezik učinkovito v zapletenih kontekstih.',
          value: 'expert',
          borderRadius: '0 0 8px 8px ',
        },
      ],
      changeDailyTarget: 'Spremenite svoj dnevni učni cilj.',
      deletedFromFavorite: 'Odstranjeno iz priljubljenih',
      addedToFavorite: 'Dodano med priljubljene',
      lessonTime: [
        {
          name: 'Hitri tečaj',
          description: 'Popolna izbira za hitro učenje',
          time: '5 minut',
          value: 'five_minutes',
          borderRadius: '8px 8px 0 0',
        },
        {
          name: 'Standardna seja',
          description: 'Odlična izbira za uravnoteženo učenje',
          time: '10 minut',
          value: 'ten_minutes',
          borderRadius: '0',
        },
        {
          name: 'Intenzivno usposabljanje',
          description: 'Najboljša možnost za navdušence',
          time: '15 minut',
          value: 'fifteen_minutes',
          borderRadius: '0 0 8px 8px ',
        },
      ],
      dailyTargetChanged: 'Dnevni cilj je bil spremenjen!',
      pickInterests: 'Izberi vsaj 3 interese.',
      activeTo: 'Načrt aktiven do:',
      daysLeft: 'Dni preostalih v načrtu:',
      opinionSubtitle:
        'Stalno izboljšujemo našo aplikacijo. Povejte nam, kaj lahko izboljšamo ali kaj vam je najbolj všeč.',
      startTyping: 'Napiši svoje mnenje',
      faq: 'FAQ',
      send: 'Pošlji',
      withProblems: 'Za vprašanja/težave nas kontaktirajte na:',
      contactMail: 'contact@synthiaiapp.com',
      faqConfig: [
        {
          id: 1,
          question: 'Moj zvok ne deluje.',
          answer: `Če imate težave z zvokom, preizkusite te nasvete:
          
- Preverite dovoljenja za zvok v brskalniku.
- Poskrbite, da je glasnost vaše naprave primerna.
- Preverite pravilno povezavo zvočnikov/slušalk.
- Preverite nastavitve zvoka sistema, da zagotovite, da aplikacije niso utišane.
- Preizkusite drugo napravo ali kontaktirajte tehnično podporo.`,
        },
        {
          id: 2,
          question: 'Ne morem posneti svojega glasu.',
          answer: `Če imate težave pri snemanju zvoka, preizkusite te nasvete:
          
- Preverite dovoljenja za mikrofon v brskalniku.
- Prepričajte se, da ima mobilni brskalnik dostop do mikrofona.
- Preverite pravilno povezavo mikrofona.
- Uporabite drugo napravo ali kontaktirajte tehnično podporo.`,
        },
        {
          id: 3,
          question: 'Ali lahko uporabljam aplikacijo na več napravah?',
          answer:
            'Seveda, preprosto se prijavite na vsaki združljivi napravi, da uživate v nemoteni učni izkušnji.',
        },
        {
          id: 4,
          question: 'Ali lahko uporabljam Synthi Ai brez povezave?',
          answer:
            'Ne, Synthi Ai zahteva spletni brskalnik s povezavo do interneta.',
        },
        {
          id: 5,
          question: 'Kako spremenim AI tutorja?',
          answer:
            'Pojdite na "Tutor" v nastavitvah Synthi AI. Oglejte si in izberite tutorja, ki vam je najbolj všeč.',
        },
        {
          id: 6,
          question: 'Kako podaljšam dostop do aplikacije po poteku?',
          answer: 'Svoj načrt lahko obnovite po poteku tako, da se prijavite.',
        },
        {
          id: 7,
          question: 'Ali lahko dobim račun?',
          answer:
            'Kontaktirajte nas na contact@synthiaiapp.com in nam posredujte svojo davčno številko ter podatke o podjetju.',
        },
        {
          id: 8,
          question: 'Kako lahko kontaktiram tehnično podporo?',
          answer:
            'Pošljite e-pošto na contact@synthiaiapp.com in navedite podrobnosti o težavi ter informacije o napravi in brskalniku.',
        },
      ],
    },
    rules: 'Pravila',
    privacy: 'Politika zasebnosti',
    span: ' in ',
  },
  welcome: {
    languagePicker: {
      title: 'Izberi jezik, ki se ga želiš naučiti',
      next: 'Naprej',
      langToLearn: 'Jezik za učenje',
      successTitle: 'Jezik za učenje je bil spremenjen',
      config: [
        {
          label: 'Angleščina',
          label2: 'Angleščina',
          labelInside: 'angleščina',
          value: 'en',
        },
        {
          label: 'Nemščina',
          label2: 'Nemščina',
          labelInside: 'nemščina',
          value: 'de',
        },
        {
          label: 'Španščina',
          label2: 'Španščina',
          labelInside: 'španščina',
          value: 'es',
        },
        {
          label: 'Francoščina',
          label2: 'Francoščina',
          labelInside: 'francoščina',
          value: 'fr',
        },
        {
          label: 'Italijanščina',
          label2: 'Italijanščina',
          labelInside: 'italijanščina',
          value: 'it',
        },
      ],
    },
    zeroScreen: {
      welcomeInApp: 'Dobrodošli v aplikaciji Synthi AI!',
      start: 'ZAČNIMO',
      welcomeMobile: 'Dobrodošli',
      config: {
        chrome: {
          title: 'Namesti aplikacijo na svojo napravo Android.',
          text1: 'Kliknite na',
          text1bold: ' Nastavitve',
          image1: Android1,
          text2: 'Nato kliknite na',
          text2bold: ' Dodaj na domači zaslon',
          image2: Android2,
          text3: 'Končano! Synthi AI je sedaj na vašem domačem zaslonu.',
          image3: Last,
        },
        safari: {
          title: 'Namesti aplikacijo na svojo napravo iOS.',
          text1: 'Kliknite na',
          text1bold: ' Deli',
          image1: Ios1,
          text2: 'Nato kliknite na',
          text2bold: ' Dodaj na domači zaslon',
          image2: Ios2,
          text3: 'Končano! Synthi AI je sedaj na vašem domačem zaslonu.',
          image3: Last,
        },
      },
    },
    firstScreen: {
      title: 'Izberi svojega tutorja!',
      subtitle: 'Vaša odločitev bo vplivala na slog in vzdušje lekcij.',
      next: 'NAPREJ',
    },
    secondScreen: {
      title: 'Kakšna je vaša trenutna raven {language}?',
      config: [
        {
          name: 'Začetnik A1',
          description: 'Začni svojo pustolovščino z jezikom {language}.',
          value: 'basic',
        },
        {
          name: 'Začetnik A2',
          description:
            'Razumete osnovne informacije v preprostih in vsakodnevnih pogovorih.',
          value: 'beginner',
        },
        {
          name: 'Srednji B1',
          description: 'Vodite preproste a razumljive pogovore o znanih temah.',
          value: 'intermediate',
        },
        {
          name: 'Napredno B2',
          description: 'Komunicirate prosto v večini situacij',
          value: 'advanced',
        },
        {
          name: 'Ekspert C1+',
          description: 'Obvladate jezik učinkovito v zapletenih kontekstih.',
          value: 'expert',
        },
      ],
      next: 'NAPREJ',
    },
    thirdScreen: {
      title: 'Kateri so vaši interesi?',
      subtitle: 'Izberi vsaj 3 interese.',
      next: 'NAPREJ',
    },
    fourthScreen: {
      title: 'Koliko minut na dan želite študirati?',
      subtitle: 'Izberi svoj dnevni učni cilj.',
      next: 'ZAČNIMO!',
      config: [
        {
          name: 'Hitri tečaj',
          description: 'Popolna izbira za hitro učenje',
          time: '5 minut',
          value: 'five_minutes',
        },
        {
          name: 'Standardna seja',
          description: 'Odlična izbira za uravnoteženo učenje',
          time: '10 minut',
          value: 'ten_minutes',
        },
        {
          name: 'Intenzivno usposabljanje',
          description: 'Najboljša možnost za navdušence',
          time: '15 minut',
          value: 'fifteen_minutes',
        },
      ],
    },
  },
  features: {
    topbar: {
      back: 'Nazaj',
    },
    fotoMode: {
      welcome: {
        title: 'Foto-uganka',
        subtitle:
          'Opisujte, kaj vidite na sliki s čim več podrobnostmi o izbrani temi.',
        start: 'Začni!',
      },
      info: {
        startTyping: 'Piši...',
        newTask: 'Nova naloga',
        send: 'Pošlji',
      },
    },
    flashcards: {
      title: 'Kartice',
      subtitleTop:
        'Naučite se in zapomnite si nove besede z interaktivnimi učnimi metodami kartic.',
      addCategory: 'Dodajte svojo kategorijo',
      subtitle: 'Vnesite ime svoje kategorije',
      type: 'vnesite kategorijo...',
      add: 'Dodaj',
      previous: 'Prejšnja kartica',
      task: 'Naloga',
      changeLevel: 'Spremeni raven',
      dontKnow: 'Ne vem',
      know: 'Vem',
      pickPairs: 'Združi pare',
      goodJob: 'Odlično opravljeno!',
      titleEnd: 'Vaša prizadevnost je impresivna',
      subtitleEnd: 'Ste pripravljeni na nove izzive?',
      repeat: 'Ponovi kategorijo',
      other: 'Izberi drugo kategorijo',
      level: {
        title: 'Spremeni raven kartic',
        beginner: 'Začetnik A1-A2',
        intermediate: 'Srednji B1-B2',
        advance: 'Ekspert C1-C2',
        change: 'Spremeni',
      },
    },
    scene: {
      title: 'Scene',
      subtitle:
        'Prevzemite vlogo in odigrajte prizor s tutorjem AI na izbrano temo.',
      suggest: 'Predlagajte novo sceno',
      talk: 'Pogovor',
      deletedFromFavorite: 'Odstranjeno iz priljubljenih',
      addedToFavorite: 'Dodano med priljubljene',
    },
    word: {
      title: 'Besede',
      subtitle:
        'Razširite svoj besedni zaklad na ključnih področjih pogovora s tutorjem AI.',
      suggest: 'Predlagajte novo temo za besede',
      deletedFromFavorite: 'Odstranjeno iz priljubljenih',
      addedToFavorite: 'Dodano med priljubljene',
    },
  },
  cookie: {
    title: 'Obvestilo o piškotkih',
    subtitle:
      'To spletno mesto uporablja piškotke za zagotavljanje boljše uporabniške izkušnje in zagotavljanje prilagojene vsebine. Če želite izvedeti več, preberite našo ',
    link: 'Politiko zasebnosti',
    accept: 'SPREJMI',
    denied: 'ZAVRNI',
  },
  talkingBar: {
    allowMicrophone: 'Dovolite dostop do mikrofona',
    typeMessage: 'Napiši sporočilo...',
    record: 'Snemi',
    recording: 'Snemanje',
  },
  hands: {
    giveUsInfo: 'Delite svoje mnenje z nami',
    thanksForGrading: 'Hvala za vašo oceno!',
    subtitle: 'Vaše mnenje nam veliko pomeni!',
    subtitle2: 'Povejte nam, kaj mislite o tej lekciji:',
    skip: 'PRESKOČI',
    send: 'POŠLJI',
  },
  suggestConfig: {
    profile: {
      title: 'Delite svoje mnenje!',
      subtitle:
        'Stalno izboljšujemo našo aplikacijo. Povejte nam, kaj lahko izboljšamo ali kaj vam je najbolj všeč.',
    },
    word: {
      title: 'Predlagajte novo temo za besede',
      subtitle: '',
    },
    scene: {
      title: 'Predlagajte novo sceno',
      subtitle: '',
    },
    startTyping: 'Začni tipkati',
    send: 'POŠLJI',
    thx: 'Mnenje je bilo dodano! Hvala!',
    error: 'Ups, nekaj je šlo narobe',
  },
  checkout: {
    emailGuessText: 'Hoteli ste povedati',
    leftTitle: 'Izberite si načrt zase',
    rightTitle: 'Izpolnite podatke',
    email: 'Email',
    placeHolderEmail: 'Vnesite email',
    pay: 'KUPI IN PLAČAJ',
    accept: 'Sprejmem',
    rules: 'pogoje',
    space: 'in',
    tos: 'pogoje zasebnosti',
    payingAccept: 'S plačilom sprejemate',
    paymentRules: '"Pogoji plačila PayU"',
    savePayments: 'Varna plačila ureja',
    total: 'Skupaj',
    currency: 'EUR',
    currencySign: '€',
    subscribe: 'Kupite in plačajte',
  },
  dailyV2: {
    conversation: {
      doYouWantContinueConversation:
        'Ali želite nadaljevati pogovor ali začeti novega?',
      continueConversation: 'Nadaljuj pogovor',
      newConversation: 'Nov pogovor',
    },
    hint: {
      title: 'Namig',
      pick: 'Izberi',
    },
    feedback: {
      yourMessage: 'Vaše sporočilo',
      goodMessage: 'Pravilno sporočilo',
      explanation: 'Razlaga',
    },
    tutorial: {
      title: 'Kako uporabljati klepet?',
      skip: 'Preskoči',
      next: 'NAPREJ',
      start: 'Začni',
      screenConf: [ScreenOne, ScreenTwo, ScreenThree, ScreenFour],
      config: [
        [
          {
            title: 'Kliknite za utišanje tutorja',
            image: VolumeUp,
          },
          { title: 'Kliknite za povratne informacije', image: HandsUp },
          { title: 'Kliknite za navodila', image: Tutorial },
        ],
        [
          { title: 'Kliknite za ponovni predvajanje', image: VolumeUpBlank },
          {
            title: 'Kliknite za prevod',
            image: TranslationBlank,
          },
          { title: 'Kliknite za namig', image: HintBlank },
          { title: 'Kliknite za prijavo težave', image: InfoBlank },
        ],
        [
          { title: 'Vaš odgovor je pravilen', image: GreenCircle },
          {
            title: 'Kliknite za prikaz pravilnega odgovora in razlage',
            image: YellowCircle,
          },
        ],
        [
          { title: 'Kliknite v besedilno polje za pisanje sporočila' },
          { title: 'Kliknite za snemanje sporočila', image: Microphone },
        ],
      ],
    },
  },
  problem: {
    title: 'Prijavite težavo',
    subtitle: 'Opišite težavo v zvezi z AI sporočilom',
    send: 'Pošlji',
    messageSend: 'Sporočilo poslano',
    error: 'Nekaj je šlo narobe, pišite nam :)',
  },
};
