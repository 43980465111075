import React, { useMemo, useState } from 'react';
import DisctionaryImage from '../images/dictionary.svg';
import TranslateBlankImage from '../images/translate-blank.svg';
import TranslateImage from '../images/translate.svg';
import VoiceImage from '../images/voice.svg';
import VoiceBlankImage from '../images/voice-blank.svg';
import DisctionaryWhite from '../images/dictionary-white.svg';
import TranslateWhite from '../images/translate-white.svg';
import VolumeWhite from '../images/volume-white.svg';
import ProblemFlashcard from '@/common/components/customer/ProblemFlashcard';
import DictionaryPopup from './DictionaryPopup';
import ProblemIcon from '../images/probem-icon.svg';
import LoadingCircle from '@/common/components/loading/LoadingCircle';
import styled from 'styled-components';
import { SOURCES_TYPES } from '@/common/components/customer/Hands';
import { useMutation } from 'react-query';
import { handleGetVoiceForFlashcard } from '@/api/query/flashcard-v2-user-progress';
import { playSound } from '../functions/playSound';

const Title = styled.div`
  color: #29185e;
  font-family: 'Work Sans';
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 50.4px */
`;

const Subtitle = styled.div`
  color: #29185e;
  text-align: center;
  font-family: 'Work Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 19.6px */
  margin-top: 10px;
  max-width: 260px;
`;

const ImageWrapper = styled.div`
  width: 25px;
  cursor: pointer;
  transition: transform 0.3s;
  -webkit-tap-highlight-color: transparent;

  &:hover {
    transform: scale(1.1);
  }

  img {
    width: 100%;
    height: auto;
  }
`;

const ImageDisplayWrapper = styled.div`
  display: flex;
  gap: 60px;
  margin-bottom: 20px;
`;

const SubtitleTranslation = styled.div`
  color: #7f7c85;
  text-align: center;
  font-family: 'Work Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  margin-top: 10px;

  max-width: 260px;
`;

const CardTopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  div {
    margin-top: 5px;
    margin-left: 5px;
    width: 18px;

    img {
      width: 100%;
      height: auto;
    }
  }
`;

const CardContainer = styled.div`
  position: absolute;
  perspective: 1000px;
  width: 100%;
  height: calc(var(--vh, 1vh) * 100 - 300px);
  margin-top: 10px;
  -webkit-user-select: none;
  touch-action: pan-y;

  @media (min-width: 600px) {
    background: unset;
    box-shadow: unset;
    padding-top: 0px;
    padding-bottom: 20px;
    height: 50vh;
    max-height: 450px;

    @media (max-height: 700px) {
      height: 250px;
    }
  }
`;

const CardInner = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transform-style: preserve-3d;
  border-radius: 10px;
  background: #fff;
  transition: transform 0.6s ease-out;
  z-index: 2;

  @media (min-width: 600px) {
    width: 360px;
    flex: 1;
    margin: 0 auto;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
  }
`;

const CardFace = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  border-radius: 10px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff;

  @media (min-width: 600px) {
    background: unset;
    box-shadow: unset;
  }
`;

const CardFront = styled(CardFace)`
  color: #29185e;
  background: #fff;
`;

const CardBack = styled(CardFace)`
  color: #29185e;
  background: #fff;
  transform: rotateY(180deg);
`;

const Flashcard = ({ data, translate: { x, y, deq }, isTop }) => {
  const [problem, setProblem] = useState(false);
  const [dictionaryPopup, setDictionaryPopup] = useState(false);
  const [isFlipped, setIsFlipped] = useState();

  const handleFlip = () => {
    setIsFlipped((prev) => !prev);
  };

  const handleGetVoiceMutation = useMutation({
    mutationFn: (value) => handleGetVoiceForFlashcard(value),
    onSuccess: ({ data }) => {
      playSound(data?.base64);
    },
  });

  const handleLoadVoice = () => {
    if (handleGetVoiceMutation.isLoading) {
      return;
    }

    const payload = {
      flashcardId: data._id,
    };

    handleGetVoiceMutation.mutate(payload);
  };

  const getColorPalet = useMemo(() => {
    if (x > 10 && isTop) {
      return {
        background: 'linear-gradient(271deg, #4322C6 -0.67%, #48068A 101.48%)',
        color: 'white',
        secondColor: 'white',
        boxShadow: 'unset',
        dictionary: DisctionaryWhite,
        translate: TranslateWhite,
        volume: VolumeWhite,
        blankVoice: VolumeWhite,
        translateBlank: TranslateImage,
      };
    }

    if (x < -10 && isTop) {
      return {
        background: '#E8E8E8',
        color: '#29185E',
        secondColor: '#29185E',
        boxShadow: 'unset',
        dictionary: DisctionaryImage,
        translate: TranslateImage,
        volume: VoiceImage,
        blankVoice: VoiceImage,
        translateBlank: TranslateImage,
      };
    }

    return {
      background: 'white',
      color: '#29185E',
      boxShadow: '0px 0px 15px 0px rgba(0, 0, 0, 0.1)',
      secondColor: '#7F7C85',
      dictionary: DisctionaryImage,
      translate: TranslateImage,
      translateBlank: TranslateBlankImage,
      volume: VoiceImage,
      blankVoice: VoiceBlankImage,
    };
  }, [x]);

  return (
    <>
      <CardContainer>
        <CardInner
          style={{
            transform: isTop
              ? `translate(${x}px, ${y}px) rotate(${deq}deg) rotateY(${
                  isFlipped ? 180 : 0
                }deg)`
              : '',
          }}
        >
          <CardFront
            style={{
              background: getColorPalet.background,
              color: getColorPalet.color,
            }}
          >
            <CardTopWrapper>
              <div onClick={() => setProblem(true)}>
                <img src={ProblemIcon} alt="Problem Icon" />
              </div>
              <div />
            </CardTopWrapper>
            <div style={{ flex: 1 }} />
            <Title
              style={{
                color: getColorPalet.color,
              }}
            >
              {data?.word}
            </Title>
            <Subtitle
              style={{
                color: getColorPalet.color,
              }}
            >
              {data?.example_sentence?.split(data?.word)?.map((part, index) => (
                <React.Fragment key={index}>
                  {part}
                  {index <
                    data?.example_sentence?.split(data?.word).length - 1 && (
                    <b>{data?.word}</b>
                  )}
                </React.Fragment>
              ))}
            </Subtitle>
            <div style={{ flex: 1 }} />
            <ImageDisplayWrapper>
              <ImageWrapper onClick={() => handleLoadVoice()}>
                {handleGetVoiceMutation.isLoading ? (
                  <LoadingCircle width="18px" height="18px" />
                ) : (
                  <img src={getColorPalet.volume} alt="Voice" />
                )}
              </ImageWrapper>
              <ImageWrapper onClick={() => setDictionaryPopup(true)}>
                <img src={getColorPalet.dictionary} alt="Dictionary" />
              </ImageWrapper>
              <ImageWrapper onClick={handleFlip}>
                <img src={getColorPalet.translate} alt="Translate" />
              </ImageWrapper>
            </ImageDisplayWrapper>
          </CardFront>
          <CardBack
            style={{
              background: getColorPalet.background,
              color: getColorPalet.color,
            }}
          >
            <CardTopWrapper>
              <div onClick={() => setProblem(true)}>
                <img src={ProblemIcon} alt="Problem Icon" />
              </div>
              <div />
            </CardTopWrapper>
            <div style={{ flex: 1 }} />
            <Title
              style={{
                color: getColorPalet.color,
              }}
            >
              {data?.word_translation}
            </Title>
            <Subtitle
              style={{
                color: getColorPalet.color,
              }}
            >
              {data?.example_sentence?.split(data?.word)?.map((part, index) => (
                <React.Fragment key={index}>
                  {part}
                  {index <
                    data?.example_sentence.split(data?.word).length - 1 && (
                    <b>{data?.word}</b>
                  )}
                </React.Fragment>
              ))}
            </Subtitle>
            <SubtitleTranslation
              style={{
                color: getColorPalet.secondColor,
              }}
            >
              {data.example_sentence_translation}
            </SubtitleTranslation>
            <div style={{ flex: 1 }} />
            <ImageDisplayWrapper>
              <ImageWrapper>
                <img src={getColorPalet.blankVoice} alt="Voice" />
              </ImageWrapper>
              <ImageWrapper onClick={() => setDictionaryPopup(true)}>
                <img src={getColorPalet.dictionary} alt="Dictionary" />
              </ImageWrapper>
              <ImageWrapper onClick={handleFlip}>
                <img src={getColorPalet.translateBlank} alt="Translate" />
              </ImageWrapper>
            </ImageDisplayWrapper>
          </CardBack>
        </CardInner>
      </CardContainer>
      {problem && (
        <ProblemFlashcard
          flashcard={data._id}
          type={SOURCES_TYPES.FLASH_CARD_V2}
          setShowProblem={setProblem}
        />
      )}
      {dictionaryPopup && (
        <DictionaryPopup
          flashcard={data}
          setClose={setDictionaryPopup}
          isFlipped={isFlipped}
        />
      )}
    </>
  );
};

export default Flashcard;
