import Android1 from './images/Android_1_150_ID.png';
import Android2 from './images/Android_Dodaj_150_ID.png';
import Ios1 from './images/IOS_1_150_ID.png';
import Ios2 from './images/IOS_Dodaj_150_ID.png';
import Last from './images/ios_android_3.png';

import VolumeUp from './images/dailyTutorial/volume-up.svg';
import HandsUp from './images/dailyTutorial/hand-up.svg';
import VolumeUpBlank from './images/dailyTutorial/volume-up-blank.svg';
import TranslationBlank from './images/dailyTutorial/icon-translate-blank.svg';
import HintBlank from './images/dailyTutorial/idea-blank.svg';
import InfoBlank from './images/dailyTutorial/information-blank.svg';
import GreenCircle from './images/dailyTutorial/green-circle.svg';
import YellowCircle from './images/dailyTutorial/yelow-circle.png';
import Microphone from './images/dailyTutorial/icon-microphone.svg';
import ScreenOne from './images/dailyTutorial/samouczek_czat_1.png';
import ScreenTwo from './images/dailyTutorial/samouczek_czat_2.png';
import ScreenThree from './images/dailyTutorial/samouczek_czat_3.png';
import ScreenFour from './images/dailyTutorial/samouczek_czat_4.png';
import Tutorial from './images/dailyTutorial/samouczek-fiolet.svg';

export default {
  passwordRestart: {
    title: 'Atur Ulang Kata Sandi',
    password: 'Kata Sandi',
    repeatPassword: 'Ulangi Kata Sandi',
    restartPassword: 'Atur Ulang Kata Sandi',
  },

  paywall: {
    title: 'Pilih paket untuk melanjutkan belajar',
    buy: 'Beli',
    savePayment: 'Pembayaran aman dikelola oleh',
    skip: 'Lewati',
    pickPlan: 'Pilih paket',
    endPlanFirstPart: 'Akses Anda akan berakhir dalam',
    endPlanSecondPart: 'hari.',
    endPlanAdditionalPart: 'hari.',
    paymentTitle: 'Ringkasan',
    product: 'Produk:',
    plan: 'Paket SynthiAi',
    forLong: 'Akses untuk',
    data: 'data:',
    nameAndSurname: 'Nama dan Nama Belakang:',
    paymentForm: 'Metode Pembayaran:',
    toPay: 'Jumlah yang harus dibayar hari ini:',
    accept: 'Saya setuju dengan',
    rules: 'syarat',
    payingAccept: 'Dengan melakukan pembayaran, Anda menyetujui',
    paymentRules: '"Ketentuan Pembayaran PayU"',
    buyAndPay: 'BELI DAN BAYAR',
  },
  paymentStatus: {
    verification: 'Pembayaran masih dalam proses...',
    title: 'Pembayaran',
    success: 'berhasil diselesaikan!',
    failure: 'gagal!',
    textInfo: `Pembayaran Anda berhasil diproses. Sekarang Anda dapat mengatur kata sandi Anda dengan mengunjungi halaman pendaftaran kami. Anda akan menerima email berisi tautan untuk mengatur kata sandi dalam beberapa menit.
Periksa kotak masuk Anda, termasuk folder spam. Untuk memudahkan pencarian, ketik "synthiai" di kolom pencarian. Jika ada masalah, hubungi kami di: contact@synthiaiapp.com`,
    login: 'Pergi ke login',
  },
  register: {
    steps: {
      register: 'Pendaftaran',
      package: 'Paket',
      summarize: 'Ringkasan',
      step: 'Langkah ',
    },
    stepOne: {
      password: 'Kata Sandi',
      errorGoogle: 'Masuk dengan email Anda',
      title: 'Pendaftaran',
      nameAndSurname: 'Nama dan Nama Belakang',
      placeholderNameAndSurname: 'cth. Budi Santoso',
      email: 'Email',
      placeholderEmail: 'cth. contoh@synthiaiapp.com',
      password: 'Kata Sandi',
      start: 'BUAT AKUN',
      subtitle:
        'Kata sandi harus terdiri dari minimal 8 karakter, termasuk huruf, huruf besar, dan angka.',
      passwordError1: 'Kata sandi harus memiliki minimal 8 karakter.',
      passwordError2: 'Kata sandi harus memiliki setidaknya 1 huruf besar.',
      passwordError3: 'Kata sandi harus memiliki setidaknya 1 huruf kecil.',
      passwordError4: 'Kata sandi harus memiliki setidaknya 1 angka.',
      passwordError5: 'Kata sandi harus sesuai.',
    },
    stepTwo: {
      title: 'Pilih paket Anda',
      month: 'Bulan',
      months: 'Bulan',
      months2: 'Bulan',
      value: '€/Bulan',
      pick: 'pilih',
      nowPay: 'Bayar sekarang',
      saveOne: 'Hemat',
      buy: 'Beli',
      savePayment: 'Pembayaran aman dikelola oleh',
    },
    stepThree: {
      title: 'Ringkasan',
      product: 'Produk:',
      plan: 'Paket Synthi Ai',
      forLong: 'Akses untuk',
      data: 'Data:',
      nameAndSurname: 'Nama dan Nama Belakang:',
      paymentForm: 'Metode pembayaran:',
      toPay: 'Jumlah yang harus dibayar hari ini:',
      accept: 'Saya setuju dengan',
      rules: 'Syarat',
      payingAccept: 'Dengan melakukan pembayaran, Anda menyetujui',
      paymentRules: '"Ketentuan Pembayaran PayU"',
      buyAndPay: 'BELI DAN BAYAR',
    },
  },
  firstLogin: {
    createPasswordForAccount: 'Buat kata sandi untuk akun Anda',
    typeEmail: 'Masukkan alamat email Anda',
    useSameEmail: 'Gunakan alamat yang sama yang diberikan saat pembelian',
    typePassword: 'Masukkan kata sandi Anda',
    confirmPassword: 'Konfirmasi kata sandi Anda',
    createPassword: 'Buat kata sandi',
    errorLogin: 'Periksa apakah kata sandi atau email Anda benar',
    createdPassword: 'Sudah membuat kata sandi?',
    clickToLogin: 'Klik di sini untuk masuk',
  },
  login: {
    dontHavePassword: 'Belum memiliki kata sandi?',
    getPassword: 'Klik di sini untuk mendapatkan akses',
    title: 'Masuk',
    email: 'Email',
    password: 'Kata Sandi',
    textSub: 'Lupa kata sandi? Atur ulang',
    restart: 'di sini',
    restartPasswordText:
      'Masukkan alamat email Anda untuk mengatur ulang kata sandi. Kata sandi akan dikirim ke alamat email Anda.',
    placeholder: 'Masukkan alamat email Anda...',
    resetPassword: 'Atur ulang kata sandi',
    buttonText: 'Masuk',
    errorLogin: 'Periksa apakah kata sandi dan alamat email Anda benar.',
    passwordRestarted:
      'Kata sandi Anda telah diatur ulang, periksa email Anda.',
    passwordError: 'Maaf, kami tidak menemukan alamat email Anda.',
    mobileAppBannerText: 'Temukan aplikasi kami!',
  },
  navBar: {
    home: 'Mulai',
    learn: 'Belajar',
    profile: 'Profil',
  },
  home: {
    hello: 'Halo',
    question: 'Ingin membahas apa hari ini?',
    answer: 'Mulai percakapan',
    forYou: 'Rekomendasi untuk Anda',
    popularNow: 'Populer saat ini',
    doYouKnow: 'Tahukah kamu bahwa...?',
    funFact: 'Fakta Menarik Hari Ini',
    new: 'Baru',
    newTitle: 'Kartu',
    newSubtitle: 'Edisi Baru',
    check: 'Periksa',
    yourFavorite: 'Favorit Anda',
  },
  learn: {
    flashcardV2: {
      new: 'Baru',
      sport: 'Olahraga',
      continue: 'lanjutkan',
      nice: 'Luar biasa!',
      congratulation: 'Selamat!',
      category: 'Kategori',
      textAfterCategory: 'tidak memiliki rahasia untukmu!',
      pickNewCategory: 'Pilih kategori baru',
      showResult: 'Tampilkan solusi',
      createWordWithLetters: 'Buat kata dari huruf yang acak',
      know: 'Saya tahu',
      dontKnow: 'Saya tidak tahu',
      metchWords: 'Pasangkan kata',
      addNewCategory: 'Tambahkan kategori baru',
      personalizeLearning:
        'Sesuaikan pembelajaran Anda dengan membuat kategori Anda sendiri.',
      typeNewCategory: 'Ketik nama kategori Anda',
      save: 'Simpan',
      weAreCreatingCategoryForYou:
        'Kami sedang membuat kategori baru untuk Anda:',
      start: 'MULAI',
      howToUseFlashcard: 'Bagaimana cara menggunakan kartu kilat?',
      knowFlashcard:
        'Klik "Saya tidak tahu" atau "Saya tahu" untuk menilai pengetahuan Anda.',
      dontKnowFlashcard: 'Kartu yang ditandai "Saya tahu" tidak akan diulang.',
      clickToHear: 'Klik untuk mendengar kata.',
      clickForDefinition: 'Klik untuk melihat definisi',
      clickForTranslation: 'Klik untuk melihat terjemahan dan contoh',
      close: 'Tutup',
      clickCategoryToStart: 'Klik kategori pilihan untuk mulai belajar.',
      lookAsProgressBar:
        'Bilah kemajuan di bawah kategori menunjukkan berapa banyak kata yang sudah Anda kuasai.',
      clickToSetupFavaroiteFlashcard:
        'Klik untuk menandai kategori sebagai favorit.',
      clickToCreateOwnCategory:
        'Klik untuk membuat kategori kartu kilat Anda sendiri.',
      howLearningLookLike: 'Bagaimana pembelajaran terlihat?',
      restText:
        ' Pembelajaran melibatkan peninjauan kartu kilat dan penilaian pengetahuan Anda. Selain itu, Anda akan menyelesaikan tugas sederhana.',
    },
    chat: {
      title: 'Obrolan',
      subtitle: 'Bicarakan topik apapun.',
    },
    fotoMode: {
      title: 'Foto-Teka-teki',
      subtitle: 'Deskripsikan gambar sedetail mungkin.',
    },
    scene: {
      title: 'Adegan',
      subtitle: 'Berakting peran dengan tutor AI.',
    },
    word: {
      title: 'Kata-kata',
      subtitle: 'Perluas kosakata Anda.',
    },
    flashcard: {
      title: 'Kartu Flash',
      subtitle: 'Ulangi dan hafalkan kata-kata.',
    },
    seeMore: 'Lihat semua',
  },
  common: {
    description:
      'Synthi AI - Asisten pintar Anda untuk belajar bahasa Inggris. Belajar berbicara bahasa Inggris dengan lancar dengan bantuan kecerdasan buatan. Temukan pendekatan baru dan efektif untuk belajar bahasa. Mulailah sekarang!',
    deletedFromFavorite: 'Dihapus dari favorit',
    addedToFavorite: 'Ditambahkan ke favorit',
    flashcards: 'Kartu Flash',
    flashcards_v2: 'Kartu Flash',
    scenes: 'Adegan',
    words: 'Kata-kata',
    or: 'atau',
    continueWithFB: 'Lanjutkan dengan Facebook',
    privacyLink: 'privacy',
    termsLink: 'tos',
  },
  profile: {
    days: ['Sen', 'Sel', 'Rab', 'Kam', 'Jum', 'Sab', 'Min'],
    top: {
      profile: 'Profil',
      accessTo: 'Akses ke:',
      profileImage: 'Gambar profil',
      nameAndSurname: 'Nama dan Nama Belakang',
      password: 'Kata Sandi',
      change: 'Ubah',
      changeProfileImage: 'Ubah gambar profil',
      changeNameAndSurname: 'Ubah nama dan nama belakang Anda',
      changeNameAndSurnamePlaceHolder: 'Masukkan nama dan nama belakang Anda',
      save: 'Simpan',
      changePassword: 'Ubah kata sandi Anda',
      passwordInfo:
        'Kata sandi harus terdiri dari minimal 8 karakter, termasuk huruf, huruf besar, dan angka.',
      repeatPassword: 'Ulangi kata sandi',
      passwordChanged: 'Kata sandi telah diubah',
      error: 'Ups, ada yang salah',
      nameChanged: 'Nama telah diubah',
      profileChanged: 'Profil telah diubah',
    },
    plan: {
      subInfo:
        'Untuk membatalkan langganan, kirim email ke contact@synthiaiapp.com',
      yourPlan: 'Paket Anda',
      extend: 'Perpanjang paket Anda',
      short_extend: 'Perpanjang',
      currentPlan: 'Paket saat ini:',
      activeToPlan: 'Aktif hingga:',
      currentPlanLong: 'Paket Anda saat ini',
    },
    middle: {
      yourMedals: 'Penghargaan Anda',
      oneDay: 'Hari',
      twoDays: 'Hari',
      words: 'Kata-kata',
      learningTime: 'Waktu belajar',
      streak: 'Rekor beruntun',
      streakRecord: 'Rekor beruntun',
      yourRecord: 'Rekor Anda:',
      currentRecord: 'Saat ini:',
      words: 'Kata-kata',
      wordsInWeek: 'Jumlah kata per minggu:',
    },
    bottom: {
      yourTutor: 'Tutor AI Anda',
      change: 'Ubah',
      logout: 'Keluar',
      FAQ: 'FAQ',
      shareOpinion: 'Bagikan pendapat',
      yourPlan: 'Paket Anda',
      hobby: 'Minat',
      learningSetting: 'Pengaturan belajar',
      back: 'Kembali',
      dayTarget: 'Target harian',
      languageLevel: 'Tingkat bahasa',
      changeYourLangLevel: 'Ubah tingkat bahasa Anda.',
      pickNewTutor: 'Pilih tutor baru',
      yourDecision:
        'Keputusan Anda akan mempengaruhi gaya dan suasana pelajaran.',
      tutorChanged: 'Tutor telah diubah',
      error: 'Ups, ada yang salah',
      englishLevelChanged: 'Tingkat bahasa Anda telah diubah',
      levelsOptions: [
        {
          name: 'Pemula A1',
          description: 'Mulai petualangan Anda dengan bahasa Inggris',
          value: 'basic',
          borderRadius: '8px 8px 0 0',
        },
        {
          name: 'Pemula A2',
          description:
            'Anda dapat memahami ungkapan dasar dalam percakapan sederhana.',
          borderRadius: '0',
          value: 'beginner',
        },
        {
          name: 'Menengah B1',
          description:
            'Anda dapat menjalankan percakapan sederhana pada topik yang dikenal.',
          borderRadius: '0',
          value: 'intermediate',
        },
        {
          name: 'Lanjutan B2',
          description:
            'Anda dapat berkomunikasi dengan bebas dalam kebanyakan situasi',
          borderRadius: '0',
          value: 'advanced',
        },
        {
          name: 'Ahli C1+',
          description:
            'Anda menguasai bahasa secara efektif dalam konteks yang kompleks.',
          value: 'expert',
          borderRadius: '0 0 8px 8px ',
        },
      ],
      changeDailyTarget: 'Ubah target belajar harian Anda.',
      deletedFromFavorite: 'Dihapus dari favorit',
      addedToFavorite: 'Ditambahkan ke favorit',
      lessonTime: [
        {
          name: 'Pelajaran Cepat ',
          dsecription: 'Pilihan sempurna untuk belajar cepat',
          time: '5 menit',
          value: 'five_minutes',
          borderRadius: '8px 8px 0 0',
        },
        {
          name: 'Sesi Standar',
          dsecription: 'Pilihan bagus untuk belajar seimbang',
          time: '10 menit',
          value: 'ten_minutes',
          borderRadius: '0',
        },
        {
          name: 'Latihan Intensif',
          dsecription: 'Pilihan terbaik untuk penggemar',
          time: '15 menit',
          value: 'fifteen_minutes',
          borderRadius: '0 0 8px 8px ',
        },
      ],
      dailyTargetChanged: 'Target harian telah diubah!',
      pickInterests: 'Pilih minimal 3 minat.',
      activeTo: 'Paket aktif hingga:',
      daysLeft: 'Hari tersisa dalam paket:',
      opinionSubtitle:
        'Kami terus memperbarui aplikasi kami. Beritahu kami apa yang dapat kami tingkatkan atau apa yang paling Anda sukai.',
      startTyping: 'Tulis pendapat Anda',
      faq: 'FAQ',
      send: 'Kirim',
      withProblems: 'Untuk pertanyaan/masalah, hubungi kami di:',
      contactMail: 'contact@synthiaiapp.com',
      faqConfig: [
        {
          id: 1,
          question: 'Audio saya tidak berfungsi.',
          answer: `Jika Anda mengalami masalah dengan audio, pertimbangkan tips berikut:
      
              Periksa izin audio di browser Anda.
      
              Pastikan volume perangkat Anda cukup tinggi.
      
              Pastikan koneksi speaker/headset benar.
      
              Periksa pengaturan audio sistem untuk memastikan aplikasi tidak dalam mode bisu.
      
              Coba perangkat lain atau hubungi dukungan teknis.
          `,
        },
        {
          id: 2,
          question: 'Saya tidak bisa merekam suara saya.',
          answer: `Jika Anda mengalami masalah saat merekam audio, pertimbangkan tips berikut:
              
              Periksa izin mikrofon di browser Anda.
      
              Pastikan browser seluler memiliki akses ke mikrofon.
      
              Pastikan koneksi mikrofon benar.
      
              Gunakan perangkat lain atau hubungi dukungan teknis.
          `,
        },
        {
          id: 3,
          question: 'Dapatkah saya menggunakan aplikasi di beberapa perangkat?',
          answer: `Tentu, cukup login di setiap perangkat yang kompatibel untuk menikmati pengalaman belajar tanpa gangguan.`,
        },
        {
          id: 4,
          question: 'Dapatkah saya menggunakan Synthi Ai secara offline?',
          answer: `Tidak, Synthi Ai memerlukan browser web dengan akses internet.`,
        },
        {
          id: 5,
          question: 'Bagaimana cara mengganti tutor AI?',
          answer: `Pergi ke "Tutor" di pengaturan Synthi AI. Jelajahi dan pilih tutor yang Anda sukai.`,
        },
        {
          id: 6,
          question:
            'Bagaimana cara memperpanjang akses aplikasi setelah berakhir?',
          answer: `Anda dapat memperbarui paket Anda setelah berakhir dengan melakukan login.`,
        },
        {
          id: 7,
          question: 'Dapatkah saya menerima faktur?',
          answer:
            'Hubungi kami di contact@synthiaiapp.com dan berikan nomor pajak serta data perusahaan Anda.',
        },
        {
          id: 8,
          question: 'Bagaimana cara menghubungi dukungan teknis?',
          answer:
            'Kirim email ke contact@synthiaiapp.com dengan detail masalah dan informasi perangkat serta browser yang digunakan.',
        },
      ],
    },
    rules: 'Peraturan',
    privacy: 'Kebijakan Privasi',
    span: ' dan ',
  },
  welcome: {
    languagePicker: {
      title: 'Pilih bahasa yang ingin Anda pelajari',
      next: 'Lanjut',
      langToLearn: 'Bahasa untuk belajar',
      successTitle: 'Bahasa untuk belajar telah diubah',
      config: [
        {
          label: 'Inggris',
          label2: 'Inggris',
          labelInside: 'bahasa Inggris',
          value: 'en',
        },
        {
          label: 'Jerman',
          label2: 'Jerman',
          labelInside: 'bahasa Jerman',
          value: 'de',
        },
        {
          label: 'Spanyol',
          label2: 'Spanyol',
          labelInside: 'bahasa Spanyol',
          value: 'es',
        },
        {
          label: 'Perancis',
          label2: 'Perancis',
          labelInside: 'bahasa Perancis',
          value: 'fr',
        },
        {
          label: 'Italia',
          label2: 'Italia',
          labelInside: 'bahasa Italia',
          value: 'it',
        },
      ],
    },
    zeroScreen: {
      welcomeInApp: 'Selamat datang di aplikasi Synthi AI!',
      start: 'MULAI',
      welcomeMobile: 'Selamat datang',
      config: {
        chrome: {
          title: 'Instal aplikasi di perangkat Android Anda.',
          text1: 'Klik pada',
          text1bold: ' Pengaturan',
          image1: Android1,
          text2: 'Lalu klik',
          text2bold: ' Tambahkan ke layar utama',
          image2: Android2,
          text3: 'Selesai! Synthi AI sekarang ada di layar utama Anda.',
          image3: Last,
        },
        safari: {
          title: 'Instal aplikasi di perangkat iOS Anda.',
          text1: 'Klik pada',
          text1bold: ' Bagikan',
          image1: Ios1,
          text2: 'Lalu klik',
          text2bold: ' Tambahkan ke layar utama',
          image2: Ios2,
          text3: 'Selesai! Synthi AI sekarang ada di layar utama Anda.',
          image3: Last,
        },
      },
    },
    firstScreen: {
      title: 'Pilih tutor Anda!',
      subtitle: 'Keputusan Anda akan mempengaruhi gaya dan suasana pelajaran.',
      next: 'LANJUT',
    },
    secondScreen: {
      title: 'Apa tingkat {language} Anda saat ini?',
      config: [
        {
          name: 'Pemula A1',
          description: 'Mulai petualangan Anda dengan bahasa {language}.',
          value: 'basic',
        },
        {
          name: 'Pemula A2',
          description:
            'Anda dapat memahami informasi dasar dalam percakapan sederhana dan sehari-hari.',
          value: 'beginner',
        },
        {
          name: 'Menengah B1',
          description:
            'Anda dapat menjalankan percakapan sederhana yang dapat dipahami pada topik yang dikenal.',
          value: 'intermediate',
        },
        {
          name: 'Lanjutan B2',
          description:
            'Anda dapat berkomunikasi dengan bebas dalam sebagian besar situasi',
          value: 'advanced',
        },
        {
          name: 'Ahli C1+',
          description:
            'Anda menguasai bahasa secara efektif dalam konteks yang kompleks.',
          value: 'expert',
        },
      ],
      next: 'LANJUT',
    },
    thirdScreen: {
      title: 'Apa minat Anda?',
      subtitle: 'Pilih minimal 3 minat.',
      next: 'LANJUT',
    },
    fourthScreen: {
      title: 'Berapa menit sehari Anda ingin belajar?',
      subtitle: 'Pilih target belajar harian Anda.',
      next: 'MULAI!',
      config: [
        {
          name: 'Pelajaran Cepat',
          description: 'Pilihan sempurna untuk belajar cepat',
          time: '5 menit',
          value: 'five_minutes',
        },
        {
          name: 'Sesi Standar',
          description: 'Pilihan bagus untuk belajar seimbang',
          time: '10 menit',
          value: 'ten_minutes',
        },
        {
          name: 'Latihan Intensif',
          description: 'Pilihan terbaik untuk penggemar',
          time: '15 menit',
          value: 'fifteen_minutes',
        },
      ],
    },
  },
  features: {
    topbar: {
      back: 'Kembali',
    },
    fotoMode: {
      welcome: {
        title: 'Foto-Teka-teki',
        subtitle:
          'Deskripsikan apa yang Anda lihat dalam gambar dengan sebanyak mungkin detail tentang topik pilihan.',
        start: 'Mulai!',
      },
      info: {
        startTyping: 'Tulis...',
        newTask: 'Tugas baru',
        send: 'Kirim',
      },
    },
    flashcards: {
      title: 'Kartu Flash',
      subtitleTop:
        'Pelajari dan hafalkan kata-kata baru dengan metode belajar interaktif menggunakan kartu flash.',
      addCategory: 'Tambah kategori Anda',
      subtitle: 'Masukkan nama kategori Anda',
      type: 'masukkan kategori...',
      add: 'Tambah',
      previous: 'Kartu sebelumnya',
      task: 'Tugas',
      changeLevel: 'Ubah level',
      dontKnow: 'Tidak tahu',
      know: 'Tahu',
      pickPairs: 'Pasangkan',
      goodJob: 'Kerja bagus!',
      titleEnd: 'Kerja keras Anda mengesankan',
      subtitleEnd: 'Siap untuk tantangan berikutnya?',
      repeat: 'Ulangi kategori',
      other: 'Pilih kategori lain',
      level: {
        title: 'Ubah level kartu',
        beginner: 'Pemula A1-A2',
        intermediate: 'Menengah B1-B2',
        advance: 'Ahli C1-C2',
        change: 'Ubah',
      },
    },
    scene: {
      title: 'Adegan',
      subtitle:
        'Masuki peran dan berakting dalam adegan dengan tutor AI tentang topik pilihan.',
      suggest: 'Sarankan adegan baru',
      talk: 'Bicara',
      deletedFromFavorite: 'Dihapus dari favorit',
      addedToFavorite: 'Ditambahkan ke favorit',
    },
    word: {
      title: 'Kata-kata',
      subtitle:
        'Perluas kosakata Anda di bidang percakapan utama dengan tutor AI.',
      suggest: 'Sarankan tema baru untuk kata-kata',
      deletedFromFavorite: 'Dihapus dari favorit',
      addedToFavorite: 'Ditambahkan ke favorit',
    },
  },
  cookie: {
    title: 'Pemberitahuan Cookie',
    subtitle:
      'Situs ini menggunakan cookie untuk memberikan pengalaman pengguna yang lebih baik dan menyediakan konten yang disesuaikan. Untuk informasi lebih lanjut, baca',
    link: 'Kebijakan Privasi',
    accept: 'TERIMA',
    denied: 'TOLAK',
  },
  talkingBar: {
    allowMicrophone: 'Silakan izinkan akses mikrofon',
    typeMessage: 'Tulis pesan...',
    record: 'Rekam',
    recording: 'Merekam',
  },
  hands: {
    giveUsInfo: 'Bagikan pendapat Anda kepada kami',
    thanksForGrading: 'Terima kasih atas penilaian Anda!',
    subtitle: 'Pendapat Anda sangat penting bagi kami!',
    subtitle2: 'Beritahu kami pendapat Anda tentang pelajaran ini:',
    skip: 'LEWATI',
    send: 'KIRIM',
  },
  suggestConfig: {
    profile: {
      title: 'Bagikan pendapat Anda!',
      subtitle:
        'Kami terus memperbarui aplikasi kami. Beritahu kami apa yang dapat kami tingkatkan atau apa yang paling Anda sukai.',
    },
    word: {
      title: 'Sarankan tema baru untuk kata-kata',
      subtitle: '',
    },
    scene: {
      title: 'Sarankan adegan baru',
      subtitle: '',
    },
    startTyping: 'Mulai mengetik',
    send: 'KIRIM',
    thx: 'Pendapat ditambahkan! Terima kasih!',
    error: 'Ups, ada yang salah',
  },
  checkout: {
    emailGuessText: 'Apakah Anda ingin mengatakan',
    leftTitle: 'Pilih rencana untuk Anda',
    rightTitle: 'Lengkapi data',
    email: 'Email',
    placeHolderEmail: 'Masukkan email',
    pay: 'BELI DAN BAYAR',
    accept: 'Saya Setuju',
    rules: 'peraturan',
    space: 'dan',
    tos: 'kebijakan privasi',
    payingAccept: 'Dengan membayar, Anda menyetujui',
    paymentRules: '"Aturan Pembayaran PayU"',
    savePayments: 'Pembayaran aman disediakan oleh',
    total: 'Total',
    currency: 'idr',
    currencySign: 'Rp',
    subscribe: 'Beli & Bayar',
  },
  dailyV2: {
    conversation: {
      doYouWantContinueConversation:
        'Apakah Anda ingin melanjutkan percakapan atau memulai yang baru?',
      continueConversation: 'Lanjutkan percakapan',
      newConversation: 'Percakapan baru',
    },
    hint: {
      title: 'Petunjuk',
      pick: 'Pilih',
    },
    feedback: {
      yourMessage: 'Pesan Anda',
      goodMessage: 'Pesan yang benar',
      explanation: 'Penjelasan',
    },
    tutorial: {
      title: 'Cara menggunakan chat?',
      skip: 'Lewati',
      next: 'LANJUT',
      start: 'Mulai',
      screenConf: [ScreenOne, ScreenTwo, ScreenThree, ScreenFour],
      config: [
        [
          {
            title: 'Klik untuk mematikan suara Tutor',
            image: VolumeUp,
          },
          { title: 'Klik untuk memberikan feedback', image: HandsUp },
          { title: 'Klik untuk membuka petunjuk', image: Tutorial },
        ],
        [
          { title: 'Klik untuk memutar ulang', image: VolumeUpBlank },
          {
            title: 'Klik untuk melihat terjemahan',
            image: TranslationBlank,
          },
          { title: 'Klik untuk melihat petunjuk', image: HintBlank },
          { title: 'Klik untuk melaporkan masalah', image: InfoBlank },
        ],
        [
          { title: 'Jawaban Anda benar', image: GreenCircle },
          {
            title: 'Klik untuk melihat jawaban yang benar dan penjelasan',
            image: YellowCircle,
          },
        ],
        [
          { title: 'Klik pada kolom teks untuk mengetik pesan' },
          { title: 'Klik untuk merekam pesan', image: Microphone },
        ],
      ],
    },
  },
  problem: {
    title: 'Laporkan Masalah',
    subtitle: 'Jelaskan masalah terkait pesan AI',
    send: 'Kirim',
    messageSend: 'Pesan terkirim',
    error: 'Ada yang salah, hubungi kami :)',
  },
};
