import React, { useEffect, useRef, useState } from 'react';
import { useMutation } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import Loading from '@/common/components/Loading';
import TopTitleHeader from '@/common/components/TopTitleHeader';
import {
  createFlashcardCategoryByCustomer,
  getWordLessonTopTypeByHobby,
} from '@/api/query/flashcard-category';
import { SOURCES_TYPES } from '@/common/components/customer/Hands';
import Galery from '@/common/images/gallery.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import PopUp from '@/common/components/PopUp';
import { useLanguageData } from '@/common/hooks/useLanguageData';

export const Wrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100 - 120px);
  overflow-y: auto;
  overflow-x: hidden;
  text-align: center;
  margin: 20px;
  gap: 10px;
  position: relative;

  @media (min-width: 600px) {
    flex-grow: 1;
    height: calc(var(--vh, 1vh) * 100 - 160px);
    width: 100%;
    padding: 5px 30px 10px;
    margin: 0;
  }
`;

export const Title = styled.h2`
  color: #05275d;
  text-align: center;
  font-family: Work Sans;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.56px;
  margin: 0px;
  text-align: center;
  margin: 10px 0;
`;

const LessonWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  align-items: center;
  width: 250px;
  height: 70px;
  border-radius: 8px;
  background-color: white;
  box-shadow: 0px 0px 11px 0px rgba(79, 167, 180, 0.2);
  color: #29185e;

  gap: 4px;

  color: #05275d;
  font-family: 'Work Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 19.6px */
  transition: all 0.3s;

  @media (min-width: 600px) {
    width: 240px;
    height: 70px;

    &:hover {
      transform: scale(1.08);
      cursor: pointer;
    }
  }

  @media (max-width: 600px) {
    border-top: ${(props) => (props.index > 0 ? '0.6px solid #d9d9d9' : '0')};
    border-radius: ${(props) => (props.index === 0 ? '8px 8px 0 0' : '0')};
    background: #fff;
    box-shadow: unset;
    width: 90vw;
    height: fit-content;
    padding: 10px 5px;
  }
`;

const WordsWrapper = styled.div`
  display: flex;
  align-content: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  gap: 5px;
  padding: 0px;
  row-gap: 20px;
  column-gap: 40px;

  @media (max-width: 600px) {
    gap: 0px;
  }
`;

const ImageWrapper = styled.div`
  width: 100px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #d9d9d9;

  @media (min-width: 600px) {
    width: 100px;
    height: 100%;
  }

  img {
    width: 100%;
    height: auto;
  }

  @media (max-width: 600px) {
    display: none;
  }
`;

const TitleLesson = styled.h3`
  color: #05275d;
  text-align: center;
  font-family: 'Work Sans';
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 28px */
  margin: 0;
  margin-bottom: 10px;
`;

const TextWrapper = styled.p`
  margin: 0;

  color: #05275d;
  font-family: 'Work Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 19.6px */

  margin-left: 20px;
  text-align: left;
  width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: 600px) {
    width: 200px;
  }
`;

const AbsoluteButton = styled.button`
  position: absolute;
  bottom: 40px;
  right: 60px;
  cursor: pointer;

  background-color: white;
  display: flex;
  height: 40px;
  padding: 0px 15px 0px 15px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 0px 11px 0px rgba(79, 167, 180, 0.2);

  color: #868686;
  font-family: 'Work Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 19.6px */

  @media (max-width: 600px) {
    position: fixed;
    bottom: 70px;
    right: 20px;

    color: #868686;
    font-family: 'Work Sans';
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 16.8px */

    display: inline-flex;
    padding: 10px 20px 10px 20px;
    align-items: flex-end;
    gap: 14px;

    align-items: center;
    height: fit-content;
  }
`;

const PopUpForm = styled.form`
  margin: 30px 80px 20px 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 600px) {
    margin: 0px;
  }
`;

const PopUpTitle = styled.h3`
  color: #05275d;
  text-align: center;
  font-family: 'Work Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 22.4px */
`;

const PopUpDescription = styled.p`
  color: #05275d;
  text-align: center;
  font-family: 'Work Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 16.8px */
  max-width: 340px;
`;

const PopUpInput = styled.input`
  display: inline-flex;
  padding: 5px 0px 5px 10px;
  justify-content: flex-end;
  align-items: center;
  width: 300px;
  border: none;
  outline: none;
  height: 25px;
  border-radius: 10px;
  background: #eef2f5;
`;
const PopUpButton = styled.button`
  display: inline-flex;
  padding: 11px 58px 12px 57px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  background: #650675;
  color: white;
  cursor: pointer;

  color: #fff;
  text-align: center;
  font-family: 'Work Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.64px;
  text-transform: uppercase;
`;

const NewFlashcardCategory = () => {
  const navigate = useNavigate();
  const [wordsTypes, setWordsTypes] = useState();
  const [addCategory, setAddCategory] = useState();
  const [hobby, setHobby] = useState();
  const data = useLanguageData('features.flashcards');

  const { id } = useParams();
  const categoryNameRef = useRef();

  const createCategoryByCustomerMutation = useMutation({
    mutationFn: (values) => createFlashcardCategoryByCustomer(values),
    onSuccess: ({ data }) => {
      setAddCategory(false);
    },
  });

  const handleAddCategory = (e) => {
    e?.preventDefault();
    const payload = {};
    payload.name = categoryNameRef.current.value;
    payload.wordLessonTopType = id;

    createCategoryByCustomerMutation.mutate(payload);
  };

  const getWordLessonTopTypeByHobbyMutation = useMutation({
    mutationFn: (values) => getWordLessonTopTypeByHobby(values),
    onSuccess: ({ data }) => {
      const arr = [...data?.data];
      setHobby(data.hobby);
      const howManyToAdd = 4 - (arr.length % 4);

      for (let i = 0; i < howManyToAdd; i++) {
        arr.push({ dontDisplay: true });
      }

      setWordsTypes(arr);
    },
  });

  useEffect(() => {
    if (!addCategory) {
      const payload = {};
      payload._id = id;
      getWordLessonTopTypeByHobbyMutation.mutate(payload);
    }
  }, [addCategory]);

  useEffect(() => {
    window.document.title = 'Synthi Ai - flashcards';
  }, []);

  return (
    <>
      {(getWordLessonTopTypeByHobbyMutation.isLoading ||
        createCategoryByCustomerMutation.isLoading) && <Loading />}
      <Wrapper height={window.innerHeight}>
        <TopTitleHeader
          navigate="/learn/flashcards"
          source={SOURCES_TYPES.FLASH_CARD_NEW}
          title={data.title}
        />
        <TitleLesson>{hobby?.name}</TitleLesson>
        <WordsWrapper>
          {wordsTypes?.map((l, i) =>
            l.dontDisplay ? (
              <div style={{ width: '240px' }}></div>
            ) : (
              <LessonWrapper
                index={i}
                onClick={() => navigate(`/learn/flashcards/${l._id}`)}
              >
                <TextWrapper>{l.name}</TextWrapper>
                <ImageWrapper>
                  {l?.file?.base64 ? (
                    <img alt="" src={l?.file?.base64} />
                  ) : (
                    <img alt="" src={Galery} style={{ width: '40px' }} />
                  )}
                </ImageWrapper>
              </LessonWrapper>
            ),
          )}
        </WordsWrapper>
        <AbsoluteButton onClick={() => setAddCategory({})}>
          {data.addCategory}
          <FontAwesomeIcon
            icon={faPlus}
            style={{ fontSize: '14px', fontWeight: 'lighter' }}
          />
        </AbsoluteButton>
      </Wrapper>
      {addCategory && (
        <PopUp setClose={setAddCategory}>
          <PopUpForm onSubmit={handleAddCategory}>
            <PopUpTitle>{data.addCategory}</PopUpTitle>
            <PopUpDescription>{data.subtitle}</PopUpDescription>
            <PopUpInput
              ref={categoryNameRef}
              placeholder={data.type}
              required
            />
            <div style={{ marginTop: '30px' }}>
              <PopUpButton>{data.add}</PopUpButton>
            </div>
          </PopUpForm>
        </PopUp>
      )}
    </>
  );
};

export default NewFlashcardCategory;
