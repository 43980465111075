import Api from '../api';
const { axios: api } = Api;

const PREFIX = 'flashcard-category-v2';

export const getFlashcardCategoriesV2 = async (payload) => {
  const data = await api.get(`/${PREFIX}`, payload);
  return { data: data.data };
};

export const getCurrentCategory = async (payload) => {
  const data = await api.get(`/${PREFIX}/${payload._id}`);
  return { data: data.data };
};

export const createCustomerCategory = async (payload) => {
  const data = await api.post(`/${PREFIX}/generate-user-category`, payload);
  return { data: data.data };
};
