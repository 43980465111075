import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  ActionsWrapper,
  Card,
  ErrorMessage,
  EyeIconWrapper,
  Input,
  InputWrapper,
  InputsContainer,
  Label,
  SubmitButton,
  Title,
  Wrapper,
} from './login.styles';
import { Link, useNavigate } from 'react-router-dom';
import { useMutation } from 'react-query';
import { facebookLogin, googleLogin, login } from '@/api/query/auth';
import userManager from '@/api/userManager';
import api from '@/api/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
import PopUp from '@/common/components/PopUp';
import { restartPasswordByMail } from '@/api/query/user';
import Loading from '@/common/components/Loading';
import { MessageContext } from '@/common/contexts/message-context/MessageContext';
import LoginImage from './images/login.png';
import { IpStatsContext } from '@/common/contexts/IpStatsContext';
import { EventService } from '@/common/services/eventService';
import { EventNamesEnum } from '@/common/enums/eventNamesEnum';
import { IP_STATS_ENUM } from '@/common/enums/ipStatsEnum';
import { useLanguageData } from '@/common/hooks/useLanguageData';
import Logo from '@/common/images/Logo.svg';
import GoogleAuth from '@/common/components/GoogleAuth';
import FacebookAuth from '@/common/components/FacebookAuth';
import FirstLoginMoveImage from './images/firsLoginMove.svg';
import {
  LanguageContext,
} from "@/common/contexts/LanguageContext";
import MobileAppBanner from "./components/MobileAppBanner/MobileAppBanner";

const FirstLoginTopText = styled.div`
  color: #fff;
  font-family: 'Work Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 19.6px */
`;

const FirstLoginBottomText = styled.div`
  color: #fff;
  font-family: 'Work Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
`;

const FirstLoginImageWrapper = styled.div`
  width: 30px;
  aspect-ratio: 1;

  img {
    width: 100%;
    height: auto;
  }
`;

const FirstLoginLink = styled(Link)`
  display: flex;
  padding: 8px 12px 8px 10px;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  justify-content: space-between;
  background: #29185e;
  margin-top: 32px;
  width: 260px;
  text-decoration: none;
`;

const PasswordWrapper = styled.div`
  display: flex;
  justify-content: center;
  font-size: 14px;
  cursor: pointer;
  color: #29185e;
  margin-top: 20px;

  font-family: 'Work Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 16.8px */

  @media (max-width: 600px) {
    width: 330px;
  }
`;

const ImageWrapper = styled.div`
  width: 340px;

  img {
    width: 100%;
    height: auto;
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

const Or = styled.div`
  color: #868686;
  font-family: 'Work Sans';
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 14.3px */
  text-align: center;
`;

const { tokenManager } = api;

const Login = () => {
  const data = useLanguageData('login');
  const common = useLanguageData('common');

  const [restartPassword, setRestartPassword] = useState();
  const { addMessage } = useContext(MessageContext);

  const [error, setError] = useState();
  const [labelType, setLabelType] = useState('password');
  const navigate = useNavigate();
  const emailRef = useRef();
  const emailRestartRef = useRef();

  const passwordRef = useRef();

  const loginMutation = useMutation({
    mutationFn: (value) => login(value),
    onSuccess: ({ data }) => {
      const { token, refreshToken } = data;

      tokenManager.setToken(token);
      tokenManager.setRefreshToken(refreshToken);

      const payload = tokenManager.parseJwt(token);
      userManager.setUser(payload);

      handleSendIPstats(IP_STATS_ENUM.LOGIN_TO_SYSTEM);

      const user = userManager.getUser();

      if (user.role === 'Admin') {
        window.location.href = 'https://admin.synthiaiapp.com';
        return;
      }

      return navigate('/');
    },
    onError: () => {
      addMessage(data.errorLogin, 'error');
    },
  });

  const loginGoogleMutation = useMutation({
    mutationFn: (value) => googleLogin(value),
    onSuccess: ({ data }) => {
      const { token, refreshToken } = data;

      tokenManager.setToken(token);
      tokenManager.setRefreshToken(refreshToken);

      handleSendIPstats(IP_STATS_ENUM.LOGIN_TO_SYSTEM);

      const payload = tokenManager.parseJwt(token);
      userManager.setUser(payload);

      return navigate('/');
    },
    onError: () => {
      addMessage(data.errorLogin, 'error');
    },
  });

  const loginFacebookMutation = useMutation({
    mutationFn: (value) => facebookLogin(value),
    onSuccess: ({ data }) => {
      const { token, refreshToken } = data;

      tokenManager.setToken(token);
      tokenManager.setRefreshToken(refreshToken);

      const payload = tokenManager.parseJwt(token);
      userManager.setUser(payload);

      handleSendIPstats(IP_STATS_ENUM.LOGIN_TO_SYSTEM);

      return navigate('/');
    },
    onError: () => {
      addMessage(data.errorLogin, 'error');
    },
  });

  const passwordRestartMutation = useMutation({
    mutationFn: (value) => restartPasswordByMail(value),
    onSuccess: () => {
      setRestartPassword(null);
      addMessage(data.passwordResterted, 'success');
    },
    onError: () => {
      addMessage(data.passwordError, 'error');
    },
  });

  const handleLogin = (e) => {
    e && e.preventDefault();
    const payload = {};
    payload.email = emailRef.current.value;
    payload.password = passwordRef.current.value;

    loginMutation.mutate(payload);
  };

  const handleChangeLabel = () => {
    passwordRef?.current?.focus();

    if (labelType === 'password') {
      return setLabelType(() => 'text');
    }
    setLabelType(() => 'password');
  };

  const handleRestartPassword = (e) => {
    e?.preventDefault();

    const payload = {};
    payload.email = emailRestartRef.current.value;

    passwordRestartMutation.mutate(payload);
  };

  const { handleSendIPstats } = useContext(IpStatsContext);

  useEffect(() => {
    return EventService.subscribeOnce(EventNamesEnum.TRACING_ID_PROCESSED, () =>
      handleSendIPstats(IP_STATS_ENUM.LOGIN_PAGE_VIEW, null),
    );
  }, []);

  const handleLoginGoogle = (credentials) => {
    loginGoogleMutation.mutate(credentials);
  };

  const handleLoginFacebook = (credentials) => {
    loginFacebookMutation.mutate(credentials);
  };

  const { lang } = useContext(LanguageContext);

  return (
    <>
      {loginMutation.isLoading && <Loading />}
      {restartPassword && (
        <PopUp closeIcon={false} setClose={setRestartPassword}>
          <form
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '30px',
              alignItems: 'center',
            }}
            onSubmit={(e) => handleRestartPassword(e)}
          >
            <Title
              style={{
                fontSize: '16px',
                maxWidth: '300px',
                textAlign: 'center',
              }}
            >
              {data.restartPasswordText}
            </Title>
            <Input
              ref={emailRestartRef}
              type="email"
              placeholder={data.placeholder}
              id="pawssword-SynthiAi"
              autoComplete="synthiai-password"
              required
            />
            <ActionsWrapper>
              <SubmitButton
                type="submit"
                style={{ padding: '10px 20px 10px 20px' }}
              >
                {data.resetPassword}
              </SubmitButton>
            </ActionsWrapper>
          </form>
        </PopUp>
      )}
  
      <MobileAppBanner lang={lang} />

      <Wrapper>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <img src={Logo} />
        </div>
        <Card onSubmit={handleLogin}>
          <div>
            <Title>{data.title}</Title>
            <InputsContainer>
              <InputWrapper>
                <Label>{data.email}</Label>
                <Input
                  ref={emailRef}
                  id="email-SynthiAi"
                  type="email"
                  required
                  placeholder="example@synthiaiapp.com"
                />
              </InputWrapper>
              <InputWrapper>
                <Label>{data.password}</Label>
                <Input
                  ref={passwordRef}
                  id="pawssword-SynthiAi"
                  type={labelType}
                  autoComplete="synthiai-password"
                  required
                />
                <EyeIconWrapper onClick={handleChangeLabel}>
                  <FontAwesomeIcon
                    tabIndex="none"
                    icon={faEye}
                    style={{
                      color: labelType === 'text' ? ' #F2F0FF' : '#ABA9A9',
                    }}
                  />
                </EyeIconWrapper>
                {error && <ErrorMessage>{error}</ErrorMessage>}
              </InputWrapper>
            </InputsContainer>
            <ActionsWrapper>
              <PasswordWrapper
                onClick={() => {
                  setRestartPassword(true);
                  window.gtag('event', 'clicked_restart_password');
                }}
              >
                {data.textSub}
                <u style={{ marginLeft: '3px' }}>{data.restart}</u>.
              </PasswordWrapper>
              <SubmitButton type="submit">{data.buttonText}</SubmitButton>
              <Or>{common.or}</Or>
              <GoogleAuth handleMutation={handleLoginGoogle} />
              <FacebookAuth handleCallback={handleLoginFacebook} />
              <FirstLoginLink to={'/first-login'}>
                <div>
                  <FirstLoginTopText>{data.dontHavePassword}</FirstLoginTopText>
                  <FirstLoginBottomText>
                    {data.getPassword}
                  </FirstLoginBottomText>
                </div>
                <FirstLoginImageWrapper>
                  <img src={FirstLoginMoveImage} />
                </FirstLoginImageWrapper>
              </FirstLoginLink>
            </ActionsWrapper>
          </div>
          <ImageWrapper>
            <img alt="" src={LoginImage} />
          </ImageWrapper>
        </Card>
      </Wrapper>
    </>
  );
};

export default Login;
