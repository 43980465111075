import { base64ToBlob } from './base64ToBlob';
import { Howl } from 'howler';

export const playSound = async (data) => {
  const blob = base64ToBlob(data, 'audio/mp3');
  const blobUrl = URL.createObjectURL(blob);

  const audio = new Howl({
    src: [blobUrl],
    format: ['mp3'],
    html5: true,
  });

  audio.play();
};
