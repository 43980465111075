import React, { useContext, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import SynthiAiLogo from '@/common/images/SynthiAi.png';
import {
  getCurrentPriceConfig,
  getCurrentPromoPriceConfig,
} from '@/api/query/price-config';
import { useMutation } from 'react-query';
import { LanguageContext } from '@/common/contexts/LanguageContext';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { useLanguageData } from '@/common/hooks/useLanguageData';
import SubscribtionStripeConf from './components/SubscribtionStripeConf';
import SavePayment from '../SavePayment';

const stripePromise = loadStripe(
  'pk_live_51PIo36IyDClzFDYgRR9ded7bjivzG8lczHc96PoWlzBNlpcWl405Mw1HpQ7i4j9rlShrZdH6D3D79MvBHxFXAHTF00UARA2lEa',
);

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  height: 100vh;

  @media (min-width: 600px) {
    flex-direction: row;
    overflow: hidden;
  }
`;

const LeftInfo = styled.div`
  @media (min-width: 600px) {
    width: 50vw;
    margin-top: 35px;
  }
`;

const Title = styled.h3`
  color: var(--Text-color, #29185e);
  font-family: 'Work Sans';
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: center;

  @media (min-width: 600px) {
    text-align: left;
    width: 320px;
    margin: 20px auto;
  }
`;

const TitleDesktop = styled(Title)`
  display: none;

  @media (min-width: 600px) {
    display: flex;
  }
`;

const PriceWrapper = styled.div`
  input[type='radio'] {
    display: none;
  }

  .custom-radio {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .custom-radio-button {
    width: 10px;
    height: 10px;
    border: 1px solid #c1c1c1;
    border-radius: 50%;
    background: white;
    display: inline-block;
    position: relative;
    transition: background 0.3s;
  }

  input[type='radio']:checked + .custom-radio-button::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #730bdb;
    transform: translate(-50%, -50%);
  }

  input[type='radio']:focus + .custom-radio-button,
  input[type='radio']:hover + .custom-radio-button {
    box-shadow: 0 0 0 2px rgba(115, 11, 219, 0.3);
  }
`;

const SynthiAiLogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 16px 0 0 0;

  @media (min-width: 600px) {
    justify-content: left;
    width: 320px;
    margin: 16px auto;
  }
`;

const PlanName = styled.div`
  color: var(--Highlight-text, #730bdb);
  font-family: 'Work Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 22.4px */
`;

const SaveWrapper = styled.div`
  color: var(--pink, #e94f96);
  font-family: 'Work Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 19.6px */
`;

const PriceInfoWrapper = styled.div`
  border-radius: 8px;
  background: #f4f6f8;
  width: 280px;
  margin: 6px 0px 16px 0;
  padding: 10px;
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

const TextWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Border = styled.div`
  width: 272px;
  height: 1px;
  background: #e0e0e0;
`;

const Text = styled.div`
  color: var(--Text-color, #29185e);
  font-family: 'Work Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 19.6px */
`;

const Text2 = styled(Text)`
  font-size: 10px;
  text-align: right;
  width: 100%;
  margin-top: -8px;
`;

const ActionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin: 0px 0;
  background-color: white;

  @media (max-width: 600px) {
    flex-wrap: wrap;
  }

  @media (min-width: 600px) {
    padding-top: 5vh;
    flex-wrap: wrap;
    width: 50vw;
    background: #f4f6f8;
    justify-content: unset;
  }
`;

const OnePageStripeSubscribtionPayment = () => {
  const [email, setEmail] = useState();
  const { lang } = useContext(LanguageContext);
  const data = useLanguageData('checkout');

  const [pricesData, setPricesData] = useState([]);
  const [selectedOption, setSelectedOption] = useState();

  const handleLoadPricesMutation = useMutation({
    mutationFn: (value) => getCurrentPromoPriceConfig(value),
    onSuccess: ({ data }) => {
      const prices = data.prices.sort((a, b) => a.full_price - b.full_price);
      const plan = localStorage.getItem('plan');
      const selected =
        prices?.find((p) => p?.type?.value === plan) || prices[0];

      setPricesData(() => prices);
      setSelectedOption(() => selected);
    },
  });

  const handleChangeSelect = (pd) => {
    setSelectedOption(() => pd);
  };

  useEffect(() => {
    let country = lang;

    if (lang === 'sv') {
      country = 'se';
    }

    if (lang === 'et') {
      country = 'ee';
    }

    if (lang === 'cs') {
      country = 'cz';
    }

    if (lang === 'el') {
      country = 'gr';
    }

    if (lang === 'sl') {
      country = 'si';
    }

    const payload = {
      country: country,
      type: 'XMAS',
    };

    handleLoadPricesMutation.mutate(payload);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setEmail(() => localStorage.getItem('promo_email') || '');
    }, 100);
  }, []);

  const options = useMemo(() => {
    if (!selectedOption?.full_price) {
      return null;
    }

    const price = selectedOption.full_price;

    return {
      mode: 'subscription',
      amount: Math.floor(price * 100),
      currency: data.currency,
      locale: ['sv'].includes(lang) ? 'en' : lang,
      appearance: {
        theme: 'flat',
        variables: {
          borderRadius: '10px',
          fontSizeBase: '12px',
        },
        rules: {
          '.Input': {
            borderRadius: '10px',
            padding: '8px',
            fontSize: '14px',
          },
          '.Tab': {
            padding: '8px',
          },
          '.Label': {
            fontSize: '12px',
          },
        },
      },
    };
  }, [selectedOption]);

  return (
    <Wrapper>
      <LeftInfo>
        <SynthiAiLogoWrapper>
          <img src={SynthiAiLogo} />
        </SynthiAiLogoWrapper>
        <Title>{data?.leftTitle}</Title>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <div style={{ minWidth: '320px' }}>
            {[...pricesData]
              .sort((a, b) => b.full_price - a.full_price)
              .map((pd) => (
                <PriceWrapper style={{ marginBottom: '12px' }}>
                  <div
                    style={{
                      display: 'flex',
                      gap: '22px',
                      width: 'fit-content',
                    }}
                    onClick={() => handleChangeSelect(pd)}
                  >
                    <input
                      type="radio"
                      id={pd._id}
                      value={pd._id}
                      name="plan"
                      checked={pd._id === selectedOption?._id}
                    />
                    <span
                      class="custom-radio-button"
                      style={{ marginTop: '5px' }}
                    ></span>
                    <label
                      htmlFor={pd._id}
                      style={{
                        display: 'flex',
                        gap: '22px',
                      }}
                    >
                      <PlanName
                        style={{
                          color:
                            pd._id === selectedOption?._id
                              ? '#730BDB'
                              : '#29185E',
                        }}
                      >
                        {pd.inAppPrice.label}
                      </PlanName>{' '}
                      <SaveWrapper>{pd.inAppPrice.saving}</SaveWrapper>{' '}
                    </label>
                  </div>
                  {pd._id === selectedOption?._id && (
                    <PriceInfoWrapper>
                      <TextWrapper>
                        <Text>{pd.inAppPrice.full_label}</Text>
                        <Text>{pd.inAppPrice.orginal_full_price}</Text>
                      </TextWrapper>
                      <TextWrapper>
                        <Text>{pd.inAppPrice.discount_label}</Text>
                        <Text
                          style={{
                            color: `${'var(--pink, #E94F96)'}`,
                          }}
                        >
                          {pd.inAppPrice.discount_value}
                        </Text>
                      </TextWrapper>
                      <Border />
                      <TextWrapper>
                        <Text>{data?.total}</Text>
                        <Text>
                          <b>
                            {' '}
                            {pd.full_price} {data?.currencySign}
                          </b>
                        </Text>
                      </TextWrapper>
                      <TextWrapper>
                        <Text2>{pd.inAppPrice.nowYouWillPay}</Text2>
                      </TextWrapper>
                    </PriceInfoWrapper>
                  )}
                </PriceWrapper>
              ))}
          </div>
        </div>
      </LeftInfo>
      <ActionWrapper>
        <TitleDesktop>{data?.rightTitle}</TitleDesktop>
        {options && (
          <Elements stripe={stripePromise} options={options}>
            <SubscribtionStripeConf
              selectedProduct={selectedOption}
              email={email}
              subscribe={data?.subscribe}
            />
          </Elements>
        )}
        <SavePayment />
      </ActionWrapper>
    </Wrapper>
  );
};

export default OnePageStripeSubscribtionPayment;
