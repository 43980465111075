import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useMutation } from 'react-query';
import { SOURCES_TYPES } from '@/common/components/customer/Hands';
import Loading from '@/common/components/Loading';
import { useLanguageData } from '@/common/hooks/useLanguageData';
import {
  getFlashcardsV2ForCategory,
  markFlahcardAsCustomerDecision,
} from '@/api/query/flashcard-v2-user-progress';
import TopTitleHeaderFlashcard from '@/common/components/customer/TopTitleHeaderFlashcard';
import { getCurrentCategory } from '@/api/query/flashcard-category-v2';
import Pairs from './components/Pairs';
import Scatter from './components/Scatter';
import EndedScreen from './components/EndedScreen';
import Flashcard from './components/Flashcard';
import { KNOWLEDGE_STATUS } from './constants/knowlege-status';
import {
  ActionWrapperMobile,
  CardsWrapper,
  CurrentLevelWrapper,
  MoveLeftAction,
  MoveRightAction,
  PageWrapper,
} from './flashcard-v2.styles';
import FlashcardTutorial from './components/FlashcardTutorial';
import { getTutorialPopUpInfo } from '@/api/query/customer';

const FlashcardV2 = () => {
  const { id } = useParams();
  const translation = useLanguageData('learn.flashcardV2');

  const [translate, setTranslate] = useState({ x: 0, y: 0, deq: 0 });
  const [showPopUpTutorial, setShowPopUpTutorial] = useState(false);
  const [category, setCategory] = useState();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [data, setData] = useState([]);
  const [allKnow, setAllKnow] = useState();

  const handleLoadCurrentFlashcardsMutation = useMutation({
    mutationFn: (value) => getFlashcardsV2ForCategory(value),
    onSuccess: ({ data }) => {
      setCurrentIndex(() => 0);

      if (data.allKnow) {
        setAllKnow(() => true);
        setData(() => []);
        return;
      }

      setData(data.data || []);
    },
  });

  const handleLoadCurrentCategoryMutation = useMutation({
    mutationFn: (value) => getCurrentCategory(value),
    onSuccess: ({ data }) => {
      setCategory(data);
    },
  });

  const handleNextFlashcardMutation = useMutation({
    mutationFn: (value) => markFlahcardAsCustomerDecision(value),
  });

  const tutorialPopUpInfoMutation = useMutation({
    mutationFn: () => getTutorialPopUpInfo(),
    onSuccess: ({ data }) => {
      if (!data?.show_flashcard_at) {
        setShowPopUpTutorial(() => true);
      }
    },
  });

  const handleNext = (status) => {
    const payload = {
      status: status,
      flashcardId: data[data.length - currentIndex - 1]?.flashcard[0]._id,
    };

    handleNextFlashcardMutation.mutate(payload);

    if (status === KNOWLEDGE_STATUS.KNOW) {
      setTranslate(() => ({
        x: window.innerWidth,
        y: -100,
        deq: 30,
      }));
    } else {
      setTranslate(() => ({
        x: -window.innerWidth,
        y: -100,
        deq: 30,
      }));
    }

    setTimeout(() => {
      setCurrentIndex((index) => (index < data.length ? index + 1 : index));
      setTranslate({ x: 0, y: 0, deq: 0 });
    }, 600);
  };

  const handleButtonClick = (status) => {
    handleNext(status);
  };

  const handleNextPart = () => {
    if (handleLoadCurrentFlashcardsMutation.isLoading || currentIndex === 0) {
      return;
    }

    const payload = { _id: id };
    handleLoadCurrentFlashcardsMutation.mutate(payload);
  };

  useEffect(() => {
    if (data.length < 6 && currentIndex === data.length) {
      setCurrentIndex(() => 9);
    }
  }, [currentIndex]);

  useEffect(() => {
    tutorialPopUpInfoMutation.mutate();
    const payload = { _id: id };
    handleLoadCurrentFlashcardsMutation.mutate(payload);
    handleLoadCurrentCategoryMutation.mutate(payload);
  }, [id]);

  return (
    <>
      {(handleLoadCurrentFlashcardsMutation.isLoading ||
        handleLoadCurrentCategoryMutation.isLoading) && <Loading />}
      {showPopUpTutorial && (
        <FlashcardTutorial setClose={setShowPopUpTutorial} />
      )}
      <PageWrapper background={currentIndex > 7 ? 'white' : 'unset'}>
        {allKnow && <EndedScreen category={category} />}
        <TopTitleHeaderFlashcard
          source={SOURCES_TYPES.FLASH_CARD_V2}
          title={category?.translations[0]?.translation}
          navigate={`/learn/flashcards`}
          showHands={true}
          setShowPopUpTutorial={setShowPopUpTutorial}
        />
        {data.length > 0 && currentIndex < 8 && (
          <>
            <CurrentLevelWrapper>{`${currentIndex + 1} / ${
              data?.length
            }`}</CurrentLevelWrapper>
            <CardsWrapper>
              {data
                .filter((a, i) => i <= data.length - currentIndex - 1)
                .map((d, i) => {
                  const f = d?.flashcard[0];
                  const ft = d?.flashcard[0]?.flashcard_v2_translation[0];

                  const result = {
                    _id: f?._id,
                    word: f?.word,
                    word_translation: ft?.word,
                    example_sentence: f?.example_sentence,
                    example_sentence_translation: ft?.example_sentence,
                    definition: f?.definition,
                    definition_translation: ft?.definition,
                  };

                  return (
                    <Flashcard
                      data={result}
                      translate={translate}
                      isTop={i === data.length - currentIndex - 1}
                    />
                  );
                })}
            </CardsWrapper>
            <ActionWrapperMobile>
              <MoveLeftAction
                onClick={() => handleButtonClick(KNOWLEDGE_STATUS.DONT_KNOW)}
              >
                {translation.dontKnow}
              </MoveLeftAction>
              <MoveRightAction
                onClick={() => handleButtonClick(KNOWLEDGE_STATUS.KNOW)}
              >
                {translation.know}
              </MoveRightAction>
            </ActionWrapperMobile>
          </>
        )}
        {currentIndex === 8 && (
          <Pairs
            translation={translation.pickPairs}
            data={data}
            setIndex={setCurrentIndex}
          />
        )}
        {currentIndex === 9 && (
          <Scatter data={data} handleNext={handleNextPart} />
        )}
      </PageWrapper>
    </>
  );
};

export default FlashcardV2;
