import styled from "styled-components";
import Cross from "../../images/cross.svg";
import { useLanguageData } from "@/common/hooks/useLanguageData";
import { useReducer } from "react";
import BadgeBG from "../../images/google-play-badges/GetItOnGooglePlay_Badge_Web_color_Bulgarian.png";
import BadgeCS from "../../images/google-play-badges/GetItOnGooglePlay_Badge_Web_color_Czech.png";
import BadgeDE from "../../images/google-play-badges/GetItOnGooglePlay_Badge_Web_color_German.png";
import BadgeEL from "../../images/google-play-badges/GetItOnGooglePlay_Badge_Web_color_Greek.png";
import BadgeEN from "../../images/google-play-badges/GetItOnGooglePlay_Badge_Web_color_English.png";
import BadgeES from "../../images/google-play-badges/GetItOnGooglePlay_Badge_Web_color_Spanish.png";
import BadgeET from "../../images/google-play-badges/GetItOnGooglePlay_Badge_Web_color_Estonian.png";
import BadgeHR from "../../images/google-play-badges/GetItOnGooglePlay_Badge_Web_color_Croatian.png";
import BadgeHU from "../../images/google-play-badges/GetItOnGooglePlay_Badge_Web_color_Hungarian.png";
import BadgeID from "../../images/google-play-badges/GetItOnGooglePlay_Badge_Web_color_Indonesian.png";
import BadgeIT from "../../images/google-play-badges/GetItOnGooglePlay_Badge_Web_color_Italian.png";
import BadgeRO from "../../images/google-play-badges/GetItOnGooglePlay_Badge_Web_color_Romanian.png";
import BadgeSL from "../../images/google-play-badges/GetItOnGooglePlay_Badge_Web_color_Slovenian.png";
import BadgeSV from "../../images/google-play-badges/GetItOnGooglePlay_Badge_Web_color_Swedish.png";
import BadgeSK from "../../images/google-play-badges/GetItOnGooglePlay_Badge_Web_color_Slovak.png";
import BadgePL from "../../images/google-play-badges/GetItOnGooglePlay_Badge_Web_color_Polish.png";

const Wrapper = styled.div`
  height: 60px;
  box-shadow: 0px 0px 7px 1px rgba(66, 68, 90, 0.3);
  position: fixed;
  z-index: 10;
  display: flex;
  justify-content: space-between;
  background-color: white;
  align-items: center;
  padding: 0px 3vw;
  width: 94vw;

  @media (min-width: 600px) {
    display: none;
  }
`;

const Text = styled.p`
  font-size: 15px;
  font-weight: 500;
  max-width: 180px;
  color: #29185e;
  font-family: "Work Sans";
  text-align: center;
`;

const Badge = styled.img`
  height: 40px;
`;

const LeftWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 55%;
`;

const GOOGLE_PLAY_BADGES_BY_LANG = new Map([
  ["sk", BadgeSK],
  ["pl", BadgePL],
  ["sv", BadgeSV],
  ["sl", BadgeSL],
  ["ro", BadgeRO],
  ["it", BadgeIT],
  ["id", BadgeID],
  ["hu", BadgeHU],
  ["hr", BadgeHR],
  ["et", BadgeET],
  ["es", BadgeES],
  ["el", BadgeEL],
  ["de", BadgeDE],
  ["cs", BadgeCS],
  ["bg", BadgeBG],
  ["en", BadgeEN],
]);

export default function MobileAppBanner({ lang }) {
  const [isVisible, toggleVisibility] = useReducer((s) => !s, true);

  const { mobileAppBannerText } = useLanguageData("login");

  const googlePlayBadge = GOOGLE_PLAY_BADGES_BY_LANG.get(lang);

  if (!isVisible) {
    return null;
  }

  return (
    <Wrapper>
      <LeftWrapper>
        <img src={Cross} onClick={toggleVisibility} />
        <Text>{mobileAppBannerText}</Text>
      </LeftWrapper>
      <a href="https://play.google.com/store/apps/details?id=com.dreamai.synthiai">
        <Badge src={googlePlayBadge} />
      </a>
    </Wrapper>
  );
}
