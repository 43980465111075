import Android1 from './images/Android_1_150_IT.png';
import Android2 from './images/Android_Dodaj_150_IT.png';
import Ios1 from './images/IOS_1_150_IT.png';
import Ios2 from './images/IOS_Dodaj_150_IT.png';
import Last from './images/ios_android_3.png';

import VolumeUp from './images/dailyTutorial/volume-up.svg';
import HandsUp from './images/dailyTutorial/hand-up.svg';
import VolumeUpBlank from './images/dailyTutorial/volume-up-blank.svg';
import TranslationBlank from './images/dailyTutorial/icon-translate-blank.svg';
import HintBlank from './images/dailyTutorial/idea-blank.svg';
import InfoBlank from './images/dailyTutorial/information-blank.svg';
import GreenCircle from './images/dailyTutorial/green-circle.svg';
import YellowCircle from './images/dailyTutorial/yelow-circle.png';
import Microphone from './images/dailyTutorial/icon-microphone.svg';
import ScreenOne from './images/dailyTutorial/samouczek_czat_1.png';
import ScreenTwo from './images/dailyTutorial/samouczek_czat_2.png';
import ScreenThree from './images/dailyTutorial/samouczek_czat_3.png';
import ScreenFour from './images/dailyTutorial/samouczek_czat_4.png';
import Tutorial from './images/dailyTutorial/samouczek-fiolet.svg';

export default {
  passwordRestart: {
    title: 'Reimposta password',
    password: 'Password',
    repeatPassword: 'Ripeti password',
    restartPawssord: 'Reimposta password',
  },

  paywall: {
    title: 'Scegli un piano per continuare a imparare',
    buy: 'Acquista',
    savePayment: 'Pagamenti sicuri gestiti da',
    skip: 'Salta',
    pickPlan: 'Scegli un piano',
    endPlanFirstPart: 'Il tuo accesso terminerà tra',
    endPlanSecondPart: 'giorni.',
    endPlanAditionalPart: 'giorno.',
    paymentTitle: 'Riepilogo',
    product: 'Prodotto:',
    plan: 'Pacchetto SynthiAi',
    forLong: 'Accesso per',
    data: 'dati:',
    nameAndSurname: 'Nome e Cognome:',
    paymentForm: 'Metodo di Pagamento:',
    toPay: 'Da pagare oggi:',
    accept: 'Accetto',
    rules: 'le condizioni',
    payingAccept: 'Effettuando il pagamento accetti',
    paymentRules: '"Termini di Pagamento di PayU"',
    buyAndPay: 'ACQUISTA E PAGA',
  },
  paymentStatus: {
    veryfication: 'Il pagamento è ancora in elaborazione...',
    title: 'Pagamento',
    success: 'completato con successo!',
    failure: 'fallito!',
    textInfo: `Il tuo pagamento è stato elaborato con successo. Ora puoi impostare la tua password accedendo alla nostra pagina di registrazione. Riceverai un'e-mail con un link per impostare la password entro pochi minuti.
Controlla la tua casella di posta, inclusa la cartella spam. Per facilitare la ricerca, digita "synthiai" nel campo di ricerca. In caso di problemi, contattaci all'indirizzo: contact@synthiaiapp.com`,
    login: 'Vai al login',
  },
  register: {
    steps: {
      register: 'Registrazione',
      package: 'Pacchetto',
      summarize: 'Riepilogo',
      step: 'Passo ',
    },
    stepOne: {
      password: 'Password',
      errorGoogle: 'Accedi con la tua e-mail',
      title: 'Registrazione',
      nameAndSurname: 'Nome e Cognome',
      placeholderNameAndSurname: 'es. Mario Rossi',
      email: 'E-mail',
      placeholderEmail: 'es. esempio@synthiaiapp.com',
      password: 'Password',
      start: 'CREA ACCOUNT',
      subtitle:
        'La password deve essere lunga almeno 8 caratteri e contenere lettere, maiuscole e numeri.',
      passwordError1: 'La password deve contenere almeno 8 caratteri.',
      passwordError2: 'La password deve contenere almeno 1 lettera maiuscola.',
      passwordError3: 'La password deve contenere almeno 1 lettera minuscola.',
      passwordError4: 'La password deve contenere almeno 1 numero.',
      passwordError5: 'Le password devono corrispondere.',
    },
    stepTwo: {
      title: 'Seleziona il tuo piano',
      month: 'Mese',
      months: 'Mesi',
      months2: 'Mesi',
      value: '€/Mese',
      pick: 'scegli',
      nowPay: 'Paga ora',
      saveOne: 'Risparmi',
      buy: 'Acquista',
      savePayment: 'Pagamenti sicuri gestiti da',
    },
    stepThree: {
      title: 'Riepilogo',
      product: 'Prodotto:',
      plan: 'Pacchetto Synthi Ai',
      forLong: 'Accesso per',
      data: 'Dati:',
      nameAndSurname: 'Nome e Cognome:',
      paymentForm: 'Metodo di pagamento:',
      toPay: 'Da pagare oggi:',
      accept: 'Accetto i',
      rules: 'Termini',
      payingAccept: 'Effettuando il pagamento accetti le',
      paymentRules: '"Condizioni di pagamento PayU"',
      buyAndPay: 'ACQUISTA E PAGA',
    },
  },
  firstLogin: {
    createPasswordForAcoount: 'Crea una password per il tuo account',
    typeEmail: 'Inserisci il tuo indirizzo email',
    useSameEmail:
      "Utilizza lo stesso indirizzo fornito al momento dell'acquisto",
    typePassword: 'Inserisci la tua password',
    confirmPassword: 'Conferma la tua password',
    createPassword: 'Crea password',
    errorLogin: 'Controlla se la tua password o email sono corrette',
    createdPassword: 'Hai già creato una password?',
    clickToLogin: 'Clicca qui per accedere',
  },
  login: {
    dontHavePassword: 'Non hai ancora una password?',
    getPassword: 'Clicca qui per ottenere l’accesso',
    title: 'Accesso',
    email: 'E-mail',
    password: 'Password',
    textSub: 'Password dimenticata? Reimposta',
    restart: 'qui',
    restartPasswordText:
      'Inserisci il tuo indirizzo e-mail per reimpostare la password. La password verrà inviata al tuo indirizzo e-mail.',
    placeholder: 'Inserisci il tuo indirizzo e-mail...',
    resetPassword: 'Reimposta la password',
    buttonText: 'Accedi',
    errorLogin:
      'Controlla che la tua password e il tuo indirizzo email siano corretti.',
    passwordResterted:
      'La tua password è stata reimpostata, controlla la tua e-mail.',
    passwordError: 'Purtroppo non abbiamo trovato il tuo indirizzo e-mail.',
    mobileAppBannerText: 'Scopri la nostra app!',
  },
  navBar: {
    home: 'Start',
    learn: 'Impara',
    profile: 'Profilo',
  },
  home: {
    hello: 'Ciao',
    question: 'Di cosa vuoi parlare oggi?',
    answer: 'Inizia una conversazione',
    forYou: 'Consigliato per te',
    popularNow: 'Popolare adesso',
    doYouKnow: 'Lo sapevi che...?',
    funFact: 'Curiosità del giorno',
    new: 'Nuovo',
    newTitle: 'Flashcard',
    newSubtitle: 'Nuova edizione',
    check: 'Verifica',
    yourFavorite: 'I tuoi preferiti',
  },
  learn: {
    flashcardV2: {
      new: 'Nuovo',
      sport: 'Sport',
      continue: 'continua',
      nice: 'Ottimo!',
      congratulation: 'Congratulazioni!',
      category: 'Categoria',
      textAfterCategory: 'non ha segreti per te!',
      pickNewCategory: 'Scegli una nuova categoria',
      showResult: 'Mostra la soluzione',
      createWordWithLetters: 'Crea una parola dalle lettere sparse',
      know: 'So',
      dontKnow: 'Non so',
      metchWords: 'Abbina le parole',
      addNewCategory: 'Aggiungi una nuova categoria',
      personalizeLearning:
        'Personalizza il tuo apprendimento creando la tua categoria.',
      typeNewCategory: 'Inserisci il nome della tua categoria',
      save: 'Salva',
      weAreCreatingCategoryForYou: 'Stiamo creando una nuova categoria per te:',
      start: 'INIZIA',
      howToUseFlashcard: 'Come usare le flashcard?',
      knowFlashcard:
        'Clicca su "Non so" o "So" per valutare le tue conoscenze.',
      dontKnowFlashcard:
        'Le flashcard contrassegnate come "So" non verranno ripetute.',
      clickToHear: 'Clicca per ascoltare la parola.',
      clickForDefinition: 'Clicca per vedere la definizione',
      clickForTranslation: 'Clicca per vedere la traduzione e gli esempi',
      close: 'Chiudi',
      clickCategoryToStart:
        'Clicca sulla categoria selezionata per iniziare a imparare.',
      lookAsProgressBar:
        'La barra di avanzamento sotto la categoria mostra quante parole hai già padroneggiato.',
      clickToSetupFavaroiteFlashcard:
        'Clicca per contrassegnare una categoria come preferita.',
      clickToCreateOwnCategory:
        'Clicca per creare la tua categoria di flashcard.',
      howLearningLookLike: "Come appare l'apprendimento?",
      restText:
        " L'apprendimento consiste nel rivedere le flashcard e valutare le tue conoscenze. Inoltre, risolverai compiti semplici.",
    },
    chat: {
      title: 'Chat',
      subtitle: 'Parla di qualsiasi argomento.',
    },
    fotoMode: {
      title: 'Foto-enigma',
      subtitle: "Descrivi l'immagine nel modo più dettagliato possibile.",
    },
    scene: {
      title: 'Scene',
      subtitle: 'Recita un ruolo con un tutor AI.',
    },
    word: {
      title: 'Parole',
      subtitle: 'Espandi il tuo vocabolario.',
    },
    flashcard: {
      title: 'Flashcard',
      subtitle: 'Ripeti e memorizza le parole.',
    },
    seeMore: 'Vedi tutto',
  },
  common: {
    description:
      "Synthi AI - Il tuo assistente intelligente per imparare l'inglese. Impara a parlare fluentemente in inglese con l'aiuto dell'intelligenza artificiale. Scopri un nuovo e efficace approccio all'apprendimento delle lingue. Inizia oggi stesso!",
    deletedFromFavorite: 'Rimosso dai preferiti',
    addedToFavorite: 'Aggiunto ai preferiti',
    flashcards: 'Flashcard',
    flashcards_v2: 'Flashcard',
    scenes: 'Scene',
    words: 'Parole',
    or: 'o',
    continueWithFB: 'Continua con Facebook',
    privacyLink: 'privacy',
    termsLink: 'tos',
  },
  profile: {
    days: ['Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab', 'Dom'],
    top: {
      profile: 'Profilo',
      accessTo: 'Accesso a:',
      profileImage: 'Immagine del profilo',
      nameAndSurname: 'Nome e Cognome',
      password: 'Password',
      change: 'Modifica',
      changeProfileImage: 'Modifica immagine del profilo',
      changeNameAndSurname: 'Modifica il tuo nome e cognome',
      changeNameAndSurnamePlaceHolder: 'Inserisci il tuo nome e cognome',
      save: 'Salva',
      changePassword: 'Modifica la tua password',
      passwordInfo:
        'La password deve essere lunga almeno 8 caratteri e contenere lettere, maiuscole e numeri.',
      repeatPassword: 'Ripeti password',
      passwordChanged: 'Password cambiata',
      error: 'Ops, qualcosa è andato storto',
      nameChanged: 'Nome cambiato',
      profileChanged: 'Profilo cambiato',
    },
    plan: {
      subInfo:
        "per annullare l'abbonamento invia un'e-mail a contact@synthiaiapp.com",
      yourPlan: 'Il tuo piano',
      extend: 'Estendi il tuo piano',
      short_extend: 'Estendi',
      currentPlan: 'Piano attuale:',
      activeToPlan: 'Attivo fino a:',
      currentPlanLong: 'Il tuo piano attuale',
    },
    middle: {
      yourMedals: 'I tuoi riconoscimenti',
      oneDay: 'Giorno',
      twoDays: 'Giorni',
      words: 'Parole',
      learningTime: 'Tempo di apprendimento',
      streak: 'Streak',
      streakRecord: 'Record di streak',
      yourRecord: 'Il tuo record:',
      currentRecord: 'Attuale:',
      words: 'Parole',
      wordsInWeek: 'Numero di parole a settimana:',
    },
    bottom: {
      yourTutor: 'Il tuo tutor AI',
      change: 'Cambia',
      logout: 'Esci',
      FAQ: 'FAQ',
      shareOpinion: 'Condividi opinione',
      yourPlan: 'Il tuo piano',
      hobby: 'Interessi',
      learningSetting: 'Impostazioni di apprendimento',
      back: 'Indietro',
      dayTarget: 'Obiettivo giornaliero',
      languageLevel: 'Livello di lingua',
      changeYourLangLevel: 'Cambia il tuo livello di lingua.',
      pickNewTutor: 'Scegli un nuovo tutor',
      yourDecision:
        "La tua decisione influenzerà lo stile e l'atmosfera delle lezioni.",
      tutroChanged: 'Tutor cambiato',
      error: 'Ops, qualcosa è andato storto',
      englishLevelChanged: 'Il tuo livello è stato cambiato',
      levelsOptions: [
        {
          name: 'Principiante A1',
          description: 'Inizia la tua avventura con la lingua inglese',
          value: 'basic',
          borderRadius: '8px 8px 0 0',
        },
        {
          name: 'Principiante A2',
          description:
            'Puoi comprendere espressioni di base in conversazioni semplici.',
          borderRadius: '0',
          value: 'beginner',
        },
        {
          name: 'Intermedio B1',
          description:
            'Conduci conversazioni semplici ma comprensibili su argomenti familiari.',
          borderRadius: '0',
          value: 'intermediate',
        },
        {
          name: 'Avanzato B2',
          description:
            'Comunichi liberamente nella maggior parte delle situazioni',
          borderRadius: '0',
          value: 'advanced',
        },
        {
          name: 'Esperto C1+',
          description:
            'Padroneggi la lingua efficacemente in contesti complessi.',
          value: 'expert',
          borderRadius: '0 0 8px 8px ',
        },
      ],
      changeDailyTarget:
        'Cambia il tuo obiettivo di apprendimento giornaliero.',
      deletedFromFavorite: 'Rimosso dai preferiti',
      addedToFavorite: 'Aggiunto ai preferiti',
      lessonTime: [
        {
          name: 'Lezione veloce ',
          dsecription: 'Opzione perfetta per un apprendimento veloce',
          time: '5 minuti',
          value: 'five_minutes',
          borderRadius: '8px 8px 0 0',
        },
        {
          name: 'Sessione standard',
          dsecription: 'Ottima scelta per un apprendimento equilibrato',
          time: '10 minuti',
          value: 'ten_minutes',
          borderRadius: '0',
        },
        {
          name: 'Allenamento intensivo',
          dsecription: 'La migliore opzione per gli appassionati',
          time: '15 minuti',
          value: 'fifteen_minutes',
          borderRadius: '0 0 8px 8px ',
        },
      ],
      dailyTargetChanged: 'Obiettivo giornaliero cambiato!',
      pickIntresets: 'Scegli almeno 3 interessi.',
      activeTo: 'Piano attivo fino a:',
      daysLeft: 'Giorni rimanenti nel piano:',
      opinionSubtitle:
        "Stiamo migliorando costantemente la nostra app. Dicci cosa possiamo migliorare o cosa ti piace di più dell'app.",
      startTyping: 'Scrivi la tua opinione',
      faq: 'FAQ',
      send: 'Invia',
      withProblems: 'Per domande/problemi, contattaci a:',
      contactMail: 'contact@synthiaiapp.com',
      faqConfig: [
        {
          id: 1,
          question: 'Il mio audio non funziona.',
          answer: `Se hai problemi con l'audio, considera questi suggerimenti:
      
              Controlla le autorizzazioni audio nel browser.
      
              Assicurati che il volume del tuo dispositivo sia adeguato.
      
              Verifica la corretta connessione degli altoparlanti/cuffie.
      
              Controlla le impostazioni audio del sistema per assicurarti che le app non siano silenziate.
      
              Prova un altro dispositivo o contatta l'assistenza tecnica.
          `,
        },
        {
          id: 2,
          question: 'Non riesco a registrare la mia voce.',
          answer: `Se hai problemi a registrare l'audio, considera questi suggerimenti:
              
              Controlla le autorizzazioni per il microfono nel browser.
      
              Assicurati che il browser mobile abbia accesso al microfono.
      
              Verifica la corretta connessione del microfono.
      
              Usa un altro dispositivo o contatta l'assistenza tecnica.
          `,
        },
        {
          id: 3,
          question: "Posso usare l'app su più dispositivi?",
          answer: `Certo, accedi semplicemente su ogni dispositivo compatibile per godere di un'esperienza di apprendimento senza interruzioni.`,
        },
        {
          id: 4,
          question: 'Posso usare Synthi Ai offline?',
          answer: `No, Synthi Ai richiede un browser web con accesso a internet.`,
        },
        {
          id: 5,
          question: 'Come cambio il tutor AI?',
          answer: `Vai su "Tutor" nelle impostazioni di Synthi AI. Scopri e scegli il tutor che preferisci.`,
        },
        {
          id: 6,
          question: "Come posso estendere l'accesso all'app dopo la scadenza?",
          answer: `Puoi rinnovare il tuo piano dopo la scadenza, effettuando l'accesso.`,
        },
        {
          id: 7,
          question: 'Posso ricevere una fattura?',
          answer:
            'Contattaci a contact@synthiaiapp.com e fornisci la tua partita IVA e i dati aziendali.',
        },
        {
          id: 8,
          question: 'Come posso contattare il supporto tecnico?',
          answer:
            "Invia un'email a contact@synthiaiapp.com fornendo dettagli sul problema e informazioni sul dispositivo e browser utilizzati.",
        },
      ],
    },
    rules: 'Regole',
    privacy: 'Informativa sulla privacy',
    span: ' e ',
  },
  welcome: {
    languagePicker: {
      title: 'Scegli la lingua che vuoi imparare',
      next: 'Avanti',
      langToLearn: 'Lingua da imparare',
      successTitle: 'La lingua da imparare è stata cambiata',
      config: [
        {
          label: 'Inglese',
          label2: 'Inglese',
          labelInside: 'inglese',
          value: 'en',
        },
        {
          label: 'Tedesco',
          label2: 'Tedesco',
          labelInside: 'tedesca',
          value: 'de',
        },
        {
          label: 'Spagnolo',
          label2: 'Spagnolo',
          labelInside: 'spagnola',
          value: 'es',
        },
        {
          label: 'Francese',
          label2: 'Francese',
          labelInside: 'francese',
          value: 'fr',
        },
        {
          label: 'Italiano',
          label2: 'Italiano',
          labelInside: 'italiana',
          value: 'it',
        },
      ],
    },
    zeroScreen: {
      welcomeInApp: "Benvenuto nell'app Synthi AI!",
      start: 'INIZIAMO',
      welcomeMobile: 'Benvenuto',
      config: {
        chrome: {
          title: "Installa l'app sul tuo dispositivo Android.",
          text1: 'Clicca su',
          text1bold: ' Impostazioni',
          image1: Android1,
          text2: 'Poi clicca su',
          text2bold: ' Aggiungi alla schermata principale',
          image2: Android2,
          text3: 'Fatto! Synthi AI è ora sulla tua schermata principale.',
          image3: Last,
        },
        safari: {
          title: "Installa l'app sul tuo dispositivo iOS.",
          text1: 'Clicca su',
          text1bold: ' Condividi',
          image1: Ios1,
          text2: 'Poi clicca su',
          text2bold: ' Aggiungi alla schermata principale',
          image2: Ios2,
          text3: 'Fatto! Synthi AI è ora sulla tua schermata principale.',
          image3: Last,
        },
      },
    },
    firstScreen: {
      title: 'Scegli il tuo tutor!',
      subtitle:
        "La tua decisione influenzerà lo stile e l'atmosfera delle lezioni.",
      next: 'AVANTI',
    },
    secondScreen: {
      title: 'Qual è il tuo livello attuale di {language}?',
      config: [
        {
          name: 'Principiante A1',
          description: 'Inizia la tua avventura con la lingua {language}.',
          value: 'basic',
        },
        {
          name: 'Principiante A2',
          description:
            'Puoi comprendere informazioni di base in conversazioni semplici e quotidiane.',
          value: 'beginner',
        },
        {
          name: 'Intermedio B1',
          description:
            'Conduci conversazioni semplici ma comprensibili su argomenti familiari.',
          value: 'intermediate',
        },
        {
          name: 'Avanzato B2',
          description:
            'Comunichi liberamente nella maggior parte delle situazioni',
          value: 'advanced',
        },
        {
          name: 'Esperto C1+',
          description:
            'Padroneggi la lingua efficacemente in contesti complessi.',
          value: 'expert',
        },
      ],
      next: 'AVANTI',
    },
    thirdScreen: {
      title: 'Quali sono i tuoi interessi?',
      subtitle: 'Seleziona almeno 3 interessi.',
      next: 'AVANTI',
    },
    fourthScreen: {
      title: 'Quanti minuti al giorno vuoi studiare?',
      subtitle: 'Scegli il tuo obiettivo di apprendimento giornaliero.',
      next: 'INIZIAMO!',
      config: [
        {
          name: 'Lezione veloce',
          dsecription: 'Opzione perfetta per un apprendimento veloce',
          time: '5 minuti',
          value: 'five_minutes',
        },
        {
          name: 'Sessione standard',
          dsecription: 'Ottima scelta per un apprendimento equilibrato',
          time: '10 minuti',
          value: 'ten_minutes',
        },
        {
          name: 'Allenamento intensivo',
          dsecription: 'La migliore opzione per gli appassionati',
          time: '15 minuti',
          value: 'fifteen_minutes',
        },
      ],
    },
  },
  features: {
    topbar: {
      back: 'Indietro',
    },
    fotoMode: {
      welcome: {
        title: 'Foto-enigma',
        subtitle:
          "Descrivi ciò che vedi nell'immagine con il maggior numero di dettagli possibile su un argomento scelto.",
        start: 'Inizia!',
      },
      info: {
        startTyping: 'Scrivi...',
        newTask: 'Nuovo compito',
        send: 'Invia',
      },
    },
    flashcards: {
      title: 'Flashcard',
      subtitleTop:
        'Impara e memorizza nuove parole grazie ai metodi di apprendimento interattivi delle flashcard.',
      addCategory: 'Aggiungi la tua categoria',
      subtitle: 'Inserisci il nome della tua categoria',
      type: 'inserisci la categoria...',
      add: 'Aggiungi',
      previous: 'Carta precedente',
      task: 'Compito',
      changeLevel: 'Cambia livello',
      dontKnow: 'Non lo so',
      know: 'Lo so',
      pickPairs: 'Abbina le coppie',
      goodJob: 'Ottimo lavoro!',
      titleEnd: 'Il tuo impegno è impressionante',
      subtitleEnd: 'Sei pronto per ulteriori sfide?',
      repeat: 'Ripeti categoria',
      other: "Scegli un'altra categoria",
      level: {
        title: 'Cambia livello delle carte',
        beginner: 'Principiante A1-A2',
        intermediate: 'Intermedio B1-B2',
        advance: 'Esperto C1-C2',
        change: 'Cambia',
      },
    },
    scene: {
      title: 'Scene',
      subtitle:
        'Entra nel ruolo e recita una scena con un tutor AI su un argomento scelto.',
      suggest: 'Suggerisci nuova scena',
      talk: 'Parla',
      deletedFromFavorite: 'Rimosso dai preferiti',
      addedToFavorite: 'Aggiunto ai preferiti',
    },
    word: {
      title: 'Parole',
      subtitle:
        'Espandi il tuo vocabolario in aree chiave della conversazione con un tutor AI.',
      suggest: 'Suggerisci nuovo tema per le parole',
      deletedFromFavorite: 'Rimosso dai preferiti',
      addedToFavorite: 'Aggiunto ai preferiti',
    },
  },
  cookie: {
    title: 'Avviso sui cookie',
    subtitle:
      'Questo sito utilizza i cookie per offrirti una migliore esperienza utente e fornire contenuti personalizzati. Se desideri saperne di più, leggi la nostra ',
    link: 'Politica sulla privacy',
    accept: 'ACCETTA',
    denied: 'RIFIUTA',
  },
  talkingBar: {
    allowMicrofon: "Si prega di consentire l'accesso al microfono",
    typeMessage: 'Scrivi un messaggio...',
    record: 'Registra',
    recording: 'Registrazione',
  },
  hands: {
    giveUsInfo: 'Condividi la tua opinione con noi',
    thanksForGrading: 'Grazie per la tua valutazione!',
    subtitle: 'La tua opinione è importante per noi!',
    subtitle2: 'Dicci cosa ne pensi di questa lezione:',
    skip: 'SALTA',
    send: 'INVIA',
  },
  suggestConfig: {
    profile: {
      title: 'Condividi la tua opinione!',
      subtitle:
        'Miglioriamo costantemente la nostra app. Dicci cosa possiamo migliorare o cosa ti piace di più.',
    },
    word: {
      title: 'Suggerisci nuovo tema per le parole',
      subtitle: '',
    },
    scene: {
      title: 'Suggerisci nuova scena',
      subtitle: '',
    },
    startTyping: 'Inizia a digitare',
    send: 'INVIA',
    thx: 'Opinione aggiunta! Grazie!',
    error: 'Ops, qualcosa è andato storto',
  },
  checkout: {
    emailGuessText: 'Intendevi dire',
    leftTitle: 'Scegli un piano per te',
    rightTitle: 'Completa i dettagli',
    subscribe: 'iscriviti',
    currency: 'eur',
    savePayments: 'I pagamenti sicuri sono gestiti da',
  },
  dailyV2: {
    conversation: {
      doYouWantContinueConversation:
        'Vuoi continuare la conversazione o iniziarne una nuova?',
      continueConversation: 'Continua conversazione',
      newConversation: 'Nuova conversazione',
    },
    hint: {
      title: 'Suggerimento',
      pick: 'Scegli',
    },
    feedback: {
      yourMessage: 'Il tuo messaggio',
      goodMessage: 'Messaggio corretto',
      explanation: 'Spiegazione',
    },
    tutorial: {
      title: 'Come usare la chat?',
      skip: 'Salta',
      next: 'AVANTI',
      start: 'Inizia',
      screenConf: [ScreenOne, ScreenTwo, ScreenThree, ScreenFour],
      config: [
        [
          {
            title: "Clicca per disattivare l'audio del Tutor",
            image: VolumeUp,
          },
          { title: 'Clicca per dare un feedback', image: HandsUp },
          { title: 'Clicca per aprire le istruzioni', image: Tutorial },
        ],
        [
          { title: 'Clicca per riascoltare', image: VolumeUpBlank },
          {
            title: 'Clicca per vedere la traduzione',
            image: TranslationBlank,
          },
          { title: 'Clicca per vedere il suggerimento', image: HintBlank },
          { title: 'Clicca per segnalare un problema', image: InfoBlank },
        ],
        [
          { title: 'La tua risposta è corretta', image: GreenCircle },
          {
            title: 'Clicca per vedere la risposta corretta e la spiegazione',
            image: YellowCircle,
          },
        ],
        [
          { title: 'Clicca nel campo di testo per scrivere un messaggio' },
          { title: 'Clicca per registrare un messaggio', image: Microphone },
        ],
      ],
    },
  },
  problem: {
    title: 'Segnala un problema',
    subtitle: 'Descrivi il problema relativo al messaggio AI',
    send: 'Invia',
    messageSend: 'Messaggio inviato',
    error: 'Qualcosa è andato storto, scrivici :)',
  },
};
