import React, { createContext, useMemo } from 'react';
import { getNavigationPathApp } from '../functions/getNavigationPathApp';

export const LanguageContext = createContext();

export const LANG_OPTIONS = {
  polish: 'pl',
  english: 'en',
  spanish: 'es',
  german: 'de',
  croatian: 'hr',
  italian: 'it',
  romanian: 'ro',
  slovak: 'sk',
  czech: 'cs',
  hungarian: 'hu',
  bulgarian: 'bg',
  swedish: 'sv',
  estonian: 'et',
  greek: 'el',
  indonesian: 'id',
  slovenia: 'sl',
};

const getLangFromSubdomain = () => {
  const host = window.location.host;
  let parts = host.split('.');

  if (Object.values(LANG_OPTIONS).includes(parts[0])) {
    return parts[0];
  }

  const userLang = navigator.language || navigator.userLanguage;
  const langCode = userLang.split("-")[0];

  const pathname = window.location.pathname;
  const serach = window.location.search;

  if (Object.values(LANG_OPTIONS).includes(langCode)) {
    window.location.replace(
      `https://${langCode}.app.synthiaiapp.com${pathname}${serach}`
    );

    return langCode;
  } else {
    window.location.replace(
      getNavigationPathApp(`pl.app.synthiaiapp.com${pathname}`)
    );
    return "pl";
  }
};

const LanguageContextProvider = ({ children }) => {
  const lang = useMemo(() => getLangFromSubdomain(), []);

  return (
    <LanguageContext.Provider value={{ lang: lang }}>
      {children}
    </LanguageContext.Provider>
  );
};

export default LanguageContextProvider;
