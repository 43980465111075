import Api from '../api';
const { axios: api } = Api;
const PREFIX = 'hands';

export const createHands = async (payload) => {
  const data = await api.post(`/${PREFIX}`, payload, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return { data: data.data };
};
