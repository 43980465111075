import {
  createStrpiePaymentShort,
  createStrpieSubscriptionCheckout,
} from '@/api/query/stripe';
import { LanguageContext } from '@/common/contexts/LanguageContext';
import { useLanguageData } from '@/common/hooks/useLanguageData';
import {
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import React, { useContext, useRef, useState } from 'react';
import { useMutation } from 'react-query';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { MessageContext } from '@/common/contexts/message-context/MessageContext';
import useQueryMemo from '@/common/hooks/urlMemo';
import { handleEmailChange } from '@/pages/checkout/helper/handleGuessEmail';
import { generateLinkToProductPage } from '@/common/functions/generateLinkToProductPage';
import { IpStatsContext } from '@/common/contexts/IpStatsContext';

const Label = styled.label`
  color: #868686;
  font-family: 'Work Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
`;

const Input = styled.input`
  margin-top: 4px;
  display: flex;
  padding: 8px 10px 8px 10px;
  width: 260px;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #29185e;
  background: #fff;

  &:hover {
    outline: none;
  }
  &:focus {
    outline: none;
  }
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const LinkWrapper = styled.div`
  color: #05275d;
  text-align: center;
  font-family: 'Work Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 10px auto;

  @media (min-width: 600px) {
    margin-top: 8px;
  }

  input[type='checkbox'] {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 15px;
    height: 15px;
    border: 2px solid #29185e;
    border-radius: 4px;
    outline: none;
    cursor: pointer;
    position: relative;
    display: inline-block;
    vertical-align: middle;
  }

  input[type='checkbox']:checked {
    background-color: #29185e;
  }

  input[type='checkbox']:checked::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 4px;
    height: 8px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: translate(-50%, -50%) rotate(45deg);
  }

  input[type='checkbox']:focus {
    box-shadow: 0 0 3px #29185e;
  }
`;

const LetMailButton = styled.button`
  display: flex;
  gap: 10px;
  padding: 9px 82px 10px 82px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #fff;
  text-align: center;
  font-family: 'Work Sans';
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.8px;
  text-transform: uppercase;
  background: linear-gradient(91deg, #4f17aa 1.48%, #ac02f1 99.24%);
  margin: 5px auto;

  @media (min-width: 600px) {
    margin-top: 20px;
  }
`;

const EmailBox = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background: #f4f6f8;
  border-radius: 12px;
  width: 320px;
  min-height: 80px;
  margin: -20px auto 20px auto;

  @media (max-width: 600px) {
    margin-top: 20px;
    margin-bottom: 20px;
  }
`;

const StyledInput = styled(Input)`
  @media (min-width: 600px) {
    border: 1px solid #29185e;
    color: #29185e;
    width: 300px;
  }
`;

const HintMail = styled.div`
  float: right;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: left;

  margin-top: -5px;
  padding-top: 5px;
  padding-left: 10px;
  min-height: 30px;
  height: fit-content;
  width: 302px;
  flex-shrink: 0;
  border-radius: 0px 0px 16px 16px;
  background: #730bdb;
  cursor: pointer;
  gap: 5px;
  text-align: left;

  color: #fff;
  font-family: 'Work Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: left;
  z-index: 3;

  @media (max-width: 600px) {
    width: 263px;
  }
`;

const FormatMailCorrection = styled.div`
  color: #fff;
  font-family: 'Work Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-decoration-line: underline;
`;

const SubscribtionStripeConf = ({ selectedProduct, email, subscribe }) => {
  const queryMemo = useQueryMemo();
  const stripe = useStripe();
  const elements = useElements();
  const data = useLanguageData('register.stepThree');
  const common = useLanguageData('common');
  const checkoutData = useLanguageData('checkout');
  const { handleSendIPstats } = useContext(IpStatsContext);

  const [errorMessage, setErrorMessage] = useState();
  const [correctionEmail, setCorrectionEmail] = useState();

  const emailRef = useRef();

  const { lang } = useContext(LanguageContext);

  const { addMessage } = useContext(MessageContext);

  const handleSubmitStripePaymentMutation = useMutation({
    mutationFn: (payload) => createStrpieSubscriptionCheckout(payload),
    onSuccess: async ({ data }) => {
      const { type, clientSecret } = data;

      const confirmIntent =
        type === 'setup' ? stripe.confirmSetup : stripe.confirmPayment;

      const { error } = await confirmIntent({
        elements,
        clientSecret,
        confirmParams: {
          return_url: `https://${window.location.host}/success-payment`,
        },
      });

      if (error) {
        handleError(error);
      } else {
      }
    },
    onError: () => {
      addMessage(
        'Sth went wrong. Please contact contact@synthiaiapp.com',
        'error',
      );
    },
  });

  const handleError = (error) => {
    setErrorMessage(error.message);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const utm_campaign =
      queryMemo.get('utm_campaign') || localStorage.getItem('utm_campaign');
    const utm_medium =
      queryMemo.get('utm_medium') || localStorage.getItem('utm_medium');
    const utm_source =
      queryMemo.get('utm_source') || localStorage.getItem('utm_source');
    const utm_content =
      queryMemo.get('utm_content') || localStorage.getItem('utm_content');
    const utm_fbclid =
      queryMemo.get('utm_fbclid') || localStorage.getItem('utm_fbclid');
    const utm_term =
      queryMemo.get('utm_term') || localStorage.getItem('utm_term');

    const utm = {
      utm_campaign: utm_campaign,
      utm_medium: utm_medium,
      utm_source: utm_source,
      utm_content: utm_content,
      utm_fbclid: utm_fbclid,
      utm_term: utm_term,
      payment_option: localStorage.getItem('payment_option'),
    };

    if (!stripe || handleSubmitStripePaymentMutation?.isLoading) {
      return;
    }
    const { error: submitError } = await elements.submit();

    if (submitError) {
      handleError(submitError);
      return;
    }

    if (selectedProduct.type.value === 'PREMIUM') {
      handleSendIPstats('AD_XMAS_CHECKOUT_6MO');
    }
    if (selectedProduct.type.value === 'PRO') {
      handleSendIPstats('AD_XMAS_CHECKOUT_3MO');
    }
    if (selectedProduct.type.value === 'BASIC') {
      handleSendIPstats('AD_XMAS_CHECKOUT_1MO');
    }

    const payload = {
      lang: lang,
      priceId: selectedProduct.stripe_product_id,
      email: emailRef?.current?.value?.toLowerCase(),
      utm: utm,
      pageUrl: window.location.pathname,
      plan_type: selectedProduct.type.value,
      price: selectedProduct.full_price,
    };

    handleSubmitStripePaymentMutation.mutate(payload);
  };

  return (
    <form onSubmit={handleSubmit}>
      <EmailBox>
        <InputWrapper>
          <Label>{'Email'}</Label>
          <StyledInput
            ref={emailRef}
            id="email-SynthiAi"
            type="email"
            required
            defaultValue={email}
            placeholder={'type email'}
            style={{ border: 'none', padding: '8px 0px 8px 14px' }}
            onChange={(e) =>
              handleEmailChange(e.target.value, lang, setCorrectionEmail)
            }
          />
          {correctionEmail && (
            <HintMail
              onClick={() => {
                emailRef.current.value = correctionEmail;
                setCorrectionEmail(() => null);
              }}
            >
              {checkoutData.emailGuessText}{' '}
              <FormatMailCorrection>{correctionEmail}</FormatMailCorrection> ?
            </HintMail>
          )}
        </InputWrapper>
      </EmailBox>
      <PaymentElement />
      {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
      <LinkWrapper style={{ display: 'flex', alignItems: 'center' }}>
        <input type="checkbox" id="rules_2" required defaultChecked />
        <label
          htmlFor="rules_2"
          style={{
            cursor: 'pointer',
            display: 'flex',
            gap: '3px',
            padding: '0 0 0 5px ',
          }}
        >
          {data.accept}{' '}
          <div
            onClick={() =>
              window.open(generateLinkToProductPage(lang, common.termsLink))
            }
            style={{ textDecoration: 'underline' }}
          >
            {' '}
            {data.rules}
          </div>
          SynthiAi
        </label>
      </LinkWrapper>
      <LetMailButton
        type="submit"
        disabled={handleSubmitStripePaymentMutation?.isLoading}
      >
        {handleSubmitStripePaymentMutation?.isLoading && (
          <FontAwesomeIcon
            icon={faSpinner}
            spin={true}
            style={{ color: 'white' }}
          />
        )}{' '}
        {subscribe}
      </LetMailButton>
    </form>
  );
};

export default SubscribtionStripeConf;
