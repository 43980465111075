import Login from '@/pages/login/Login';
import Home from '@/pages/customer/home/Home';
import ProductLayout from '../components/layout/ProductLayout';
import Lesson from '@/pages/customer/lessons/Lesson';
import Lessons from '@/pages/customer/lessons/Lessons';
import Profile from '@/pages/customer/profile/Profile';
import WordLessonType from '@/pages/customer/word-lesson-type/WordLessonType';
import WordLessonTypeList from '@/pages/customer/word-lesson-type/WordLessonTypeList';
import RestartPassword from '@/pages/restartPassword/RestartPassword';
import Success from '@/pages/common/Success';
import Learn from '@/pages/customer/learn/Learn';
import FotoMode from '@/pages/customer/fotoMode/FotoMode';
import NewFlashcardList from '@/pages/customer/new-flashcard/NewFlashcardList';
import NewFlashcardCategory from '@/pages/customer/new-flashcard/NewFlashcardCategory';
import NewFlashcard from '@/pages/customer/new-flashcard/NewFlashcard';
import ProfileLearning from '@/pages/customer/profile/subpages/ProfileLearning';
import ProfileTutor from '@/pages/customer/profile/subpages/ProfileTutor';
import ProfileHobbies from '@/pages/customer/profile/subpages/ProfileHobbies';
import ProfileHelp from '@/pages/customer/profile/subpages/ProfileHelp';
import Paywall from '@/pages/customer/paywall/Paywall';
import ProfileEdit from '@/pages/customer/profile/subpages/ProfileEdit';
import ProfileLevel from '@/pages/customer/profile/subpages/ProfileLevel';
import ProfileDailyMinutes from '@/pages/customer/profile/subpages/ProfileDailyMinutes';
import CallMode from '@/pages/customer/callMode/CallMode';
import ProfilePlan from '@/pages/customer/profile/subpages/ProfilePlan';
import Checkout from '@/pages/checkout/Checkout';
import ProfileLearningLanguage from '@/pages/customer/profile/subpages/ProfileLearningLanguage';
import DailyWrapper from '@/pages/customer/daily/DailyWrapper';
import FirstLogin from '@/pages/first-login/FirstLogin';
import PromoCheckout from '@/pages/promoCheckout/PromoCheckout';
import FlashcardV2List from '@/pages/customer/flashcard-v2/FlashcardV2List';
import FlashcardV2 from '@/pages/customer/flashcard-v2/FlashcardV2';

const routes = [
  {
    path: '/restart-password/:id',
    element: <RestartPassword />,
  },
  {
    path: 'success-payment',
    element: <Success />,
  },
  {
    path: 'success-payment/:id',
    element: <Success />,
  },
  {
    path: 'learn/call-mode',
    element: (
      <ProductLayout>
        <CallMode />
      </ProductLayout>
    ),
  },
  {
    path: 'learn/flashcards',
    element: (
      <ProductLayout>
        <FlashcardV2List />
      </ProductLayout>
    ),
  },
  {
    path: 'learn/flashcards/:id',
    element: (
      <ProductLayout>
        <FlashcardV2 />
      </ProductLayout>
    ),
  },
  {
    path: 'learn/flashcards',
    element: (
      <ProductLayout>
        <NewFlashcardList />
      </ProductLayout>
    ),
  },
  {
    path: '/',
    element: (
      <ProductLayout>
        <Home />
      </ProductLayout>
    ),
  },
  {
    path: '/learn/chat',
    element: (
      <ProductLayout>
        <DailyWrapper />
      </ProductLayout>
    ),
  },
  {
    path: '/learn/chat/:id',
    element: (
      <ProductLayout>
        <DailyWrapper />
      </ProductLayout>
    ),
  },
  {
    path: '/learn/scenes',
    element: (
      <ProductLayout>
        <Lessons />
      </ProductLayout>
    ),
  },

  {
    path: '/learn/scenes/:id',
    element: (
      <ProductLayout>
        <Lesson />
      </ProductLayout>
    ),
  },
  {
    path: '/profile',
    element: (
      <ProductLayout>
        <Profile />
      </ProductLayout>
    ),
  },
  {
    path: '/profile/edit',
    element: (
      <ProductLayout>
        <ProfileEdit />
      </ProductLayout>
    ),
  },
  {
    path: '/profile/interests',
    element: (
      <ProductLayout>
        <ProfileHobbies />
      </ProductLayout>
    ),
  },
  {
    path: '/profile/your-plan',
    element: (
      <ProductLayout>
        <ProfilePlan />
      </ProductLayout>
    ),
  },
  {
    path: '/profile/help',
    element: (
      <ProductLayout>
        <ProfileHelp />
      </ProductLayout>
    ),
  },
  {
    path: '/profile/learning/daily-minutes',
    element: (
      <ProductLayout>
        <ProfileDailyMinutes />
      </ProductLayout>
    ),
  },
  {
    path: '/profile/learning/learning-language',
    element: (
      <ProductLayout>
        <ProfileLearningLanguage />
      </ProductLayout>
    ),
  },
  {
    path: '/profile/learning/level',
    element: (
      <ProductLayout>
        <ProfileLevel />
      </ProductLayout>
    ),
  },
  {
    path: '/profile/tutor',
    element: (
      <ProductLayout>
        <ProfileTutor />
      </ProductLayout>
    ),
  },
  {
    path: '/profile/learning',
    element: (
      <ProductLayout>
        <ProfileLearning />
      </ProductLayout>
    ),
  },
  {
    path: '/learn/words/:id',
    element: (
      <ProductLayout>
        <WordLessonType />
      </ProductLayout>
    ),
  },
  {
    path: '/learn/words',
    element: (
      <ProductLayout>
        <WordLessonTypeList />
      </ProductLayout>
    ),
  },
  {
    path: '/paywall',
    element: <Paywall />,
  },
  {
    path: '/login',
    element: <Login />,
  },
  {
    path: '/first-login',
    element: <FirstLogin />,
  },
  {
    path: '/checkout',
    element: <Checkout />,
  },
  {
    path: '/promo-checkout',
    element: <PromoCheckout />,
  },
  {
    path: '/learn',
    element: (
      <ProductLayout>
        <Learn />
      </ProductLayout>
    ),
  },
  {
    path: '/learn/foto-mode',
    element: (
      <ProductLayout>
        <FotoMode />
      </ProductLayout>
    ),
  },
];

export default routes;
