import React from 'react';
import styled from 'styled-components';
import IconClose from '@/common/images/group.svg';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  position: fixed;
  height: calc(var(--vh, 1vh) * 100);
  width: 100vw;
  top: 0;
  left: 0;
  background: rgba(41, 24, 94, 0.41);
  backdrop-filter: blur(8px);
  z-index: 90;
`;

const WhiteBox = styled.div`
  height: fit-content;
  width: 100%;
  border-radius: 16px 16px 0px 0px;
  background: #f4f6f8;
`;

const TopContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 12px 0 12px;
`;

const MiddleContent = styled.div`
  padding: 12px;
  margin: 10px 12px;
  text-align: left;
  background: #fff;
  border-radius: 8px 8px 0px 0px;
`;

const Text = styled.div`
  color: #29185e;
  font-size: 14px;
  font-weight: 600;
  line-height: 123.5%; /* 19.76px */
  letter-spacing: 0.32px;
`;

const TranslationText = styled.div`
  margin-top: 5px;
  color: #29185e;
  font-family: 'Work Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 112%; /* 15.68px */
`;

const CloseIcon = styled.img`
  width: 12px;
  height: 12px;
  cursor: pointer;
`;

const DictionaryPopup = ({ flashcard, isFlipped, setClose }) => {
  const transformText = (text) => text.charAt(0).toUpperCase() + text.slice(1);

  return (
    <>
      <Wrapper>
        <div style={{ flex: 1 }} />
        <WhiteBox>
          <TopContent>
            <div style={{ width: '12px' }} />
            <div />
            <CloseIcon src={IconClose} onClick={() => setClose(() => null)} />
          </TopContent>
          <MiddleContent>
            <Text>
              {isFlipped
                ? transformText(flashcard?.word_translation)
                : transformText(flashcard?.word)}
            </Text>
            <TranslationText>
              {isFlipped
                ? transformText(flashcard?.definition_translation)
                : transformText(flashcard?.definition)}
            </TranslationText>
          </MiddleContent>
        </WhiteBox>
      </Wrapper>
    </>
  );
};

export default DictionaryPopup;
