import React, { useContext } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useLanguageData } from '@/common/hooks/useLanguageData';
import { LanguageContext } from '@/common/contexts/LanguageContext';
import CallModeInactive from '../images/call-mode-inactive.png';
import Lock from '../images/lock.svg';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100vw;
  margin-top: 18px;
`;

const Box = styled.div`
  position: relative;
  background-image: url(${(props) => props.image});
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90vw;
  margin-left: 5vw;
  height: 140px;
  flex-direction: column;
  gap: 14px;
  margin-top: 12px;
  border-radius: 8px;
  overflow: hidden;
  text-decoration: none;
`;

const Subtitle = styled.div`
  color: #29185e;
  font-family: 'Work Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 123.5%; /* 17.29px */
  letter-spacing: 0.28px;
  text-align: left;
  margin-left: 5vw;
  z-index: 1;
  text-decoration: none;
`;

const TitleTop = styled.div`
  color: #29185e;
  font-family: 'Work Sans';
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 25.2px */
  text-align: left;
  width: 360px;
  margin-left: 5vw;
  text-decoration: none;

  display: flex;
  align-items: center;
  justify-content: left;
  gap: 12px;
`;

const InfoWrapper = styled.div`
  top: 8px;
  right: 5px;
  position: absolute;

  display: inline-flex;
  padding: 6px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 21px;
  background: #29185e;

  color: #fff;
  text-align: right;
  font-family: 'Work Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 16.8px */
`;

const CallMode = () => {
  const data = useLanguageData('learn.callMode');
  const { lang } = useContext(LanguageContext);

  return (
    <>
      {lang === 'pl' && (
        <Wrapper>
          <TitleTop>{data?.title}</TitleTop>
          <Subtitle>{data?.subtitle}</Subtitle>
          <Box image={CallModeInactive}>
            <InfoWrapper>
              <div>
                <img src={Lock} />
              </div>
              <div>{data?.mobileInfo}</div>
            </InfoWrapper>
          </Box>
        </Wrapper>
      )}
    </>
  );
};

export default CallMode;
