import React, { useContext, useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Loading from '@/common/components/Loading';
import ArrLeft from '@/common/images/arr-left.svg';
import { useLanguageData } from '@/common/hooks/useLanguageData';
import { getFlashcardCategoriesV2 } from '@/api/query/flashcard-category-v2';
import ProgressBar from './components/ProgressBar';
import BackgroundDefault from './images/background-default.svg';
import NewCategory from './components/NewCategory';
import Plus from './images/plus.svg';
import BackgroundPlus from './images/background-plus.svg';
import FlashcardCategoryTutorial from './components/FlashcardCategoryTutorial';
import TutorialImage from '@/assets/images/tutorial_color.svg';
import { getTutorialPopUpInfo } from '@/api/query/customer';
import FilledHeart from './images/filled-heart.svg';
import BlankHeart from './images/blank-heart.svg';
import { CustomerDataContext } from '@/common/components/layout/productLayout/context/CustomerDataContext';

export const Wrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100 - 60px);
  overflow-y: auto;
  text-align: center;
  padding: 0px 10px 0 10px;
  gap: 10px;

  @media (min-width: 600px) {
    flex-grow: 1;
    height: calc(var(--vh, 1vh) * 100 - 160px);
    width: 100%;
    padding: 30px 30px 10px;
    margin: 0;
  }
`;

export const Title = styled.h2`
  color: #05275d;
  text-align: center;
  font-family: Work Sans;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.56px;
  margin: 0px;
  text-align: center;
  margin: 10px 0;
`;

const TopTitle = styled.div`
  color: #fff;
  text-align: center;
  font-family: 'Work Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 123.5%; /* 24.7px */
  letter-spacing: 0.4px;
  position: absolute;
  color: white;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;

  display: flex;
  flex-direction: column;
  gap: 12px;

  img {
    width: 30px !important;
    height: 30px !important;
  }

  div {
    max-width: 130px;
  }
`;

const SubTitle = styled.div`
  color: #29185e;
  text-align: center;
  font-family: 'Work Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%; /* 17.6px */
  max-width: 400px;
`;

const BoxWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 16px;
  max-width: 800px;
`;

const ImageOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(41, 24, 94, 0.5); /* Kolor nakładki */
  border-radius: 8px; /* Zaokrąglenie krawędzi */
`;

const Heart = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
  width: 25px;
  z-index: 2;

  img {
    width: 100%;
    height: auto;
  }
`;

const Box = styled(Link)`
  position: relative;
  width: 160px;
  height: 160px;
  border-radius: 8px;
  background-color: white !important;
  box-shadow: 0px 0px 11px 0px rgba(79, 167, 180, 0.2);
`;

const ImageWrapper = styled.div`
  width: 160px;
  height: 160px;
  position: relative;
  overflow: hidden;

  img {
    border-radius: 8px;
    width: 100%;
    height: auto;
  }
`;

const Line = styled.div`
  background: #8477af;
  height: 2px;
  width: 95%;
  max-width: 690px;
`;

const FlashcardV2List = () => {
  const [wordsTypes, setWordsTypes] = useState([]);
  const [showNewCategoryPopUp, setShowNewCategoryPopUp] = useState();
  const [showPopUpTutorial, setShowPopUpTutorial] = useState(false);
  const { title, subtitleTop, addCategory } = useLanguageData(
    'features.flashcards',
  );

  const tutorialPopUpInfoMutation = useMutation({
    mutationFn: () => getTutorialPopUpInfo(),
    onSuccess: ({ data }) => {
      if (!data?.show_flashcard_category_at) {
        setShowPopUpTutorial(() => true);
      }
    },
  });

  const handleGetHobbyMutation = useMutation({
    mutationFn: () => getFlashcardCategoriesV2(),
    onSuccess: ({ data }) => {
      setWordsTypes(data);
    },
  });

  const { manageFavoriteMutation, favoriteArr } =
    useContext(CustomerDataContext);

  const handleAddToFavorite = (id, type) => {
    const payload = {
      _id: id,
      type,
      shouldAdd: favoriteArr?.includes(id) ? false : true,
    };

    manageFavoriteMutation.mutate(payload);
  };

  useEffect(() => {
    tutorialPopUpInfoMutation.mutate();

    if (!showNewCategoryPopUp) {
      handleGetHobbyMutation.mutate();
    }
  }, [showNewCategoryPopUp]);

  useEffect(() => {
    window.document.title = 'Synthi Ai - flashcards';
  }, []);

  return (
    <>
      {handleGetHobbyMutation.isLoading && <Loading />}
      {showPopUpTutorial && (
        <FlashcardCategoryTutorial setClose={setShowPopUpTutorial} />
      )}
      {showNewCategoryPopUp && (
        <NewCategory setShow={setShowNewCategoryPopUp} />
      )}
      <Wrapper height={window.innerHeight}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <Link to={'/learn'} style={{ width: '28px' }}>
            <img
              src={ArrLeft}
              style={{
                height: '15px',
              }}
            />
          </Link>
          <Title>{title}</Title>
          <div>
            <div>
              <img
                src={TutorialImage}
                onClick={() => setShowPopUpTutorial(true)}
              />
            </div>
          </div>
        </div>
        <SubTitle>{subtitleTop}</SubTitle>
        <BoxWrapper>
          {wordsTypes
            .filter((p) => p.position < 100)
            .map((d) => (
              <Box to={`/learn/flashcards/${d._id}`}>
                <ImageWrapper>
                  <TopTitle>
                    <div>{d.name}</div>
                  </TopTitle>
                  <img
                    src={d?.image?.base64 || BackgroundDefault}
                    alt={d.name}
                  />
                  <ProgressBar progress={d.progress} maxProgress={d.max} />
                  <ImageOverlay />
                  <Heart
                    onClick={(e) => {
                      e?.preventDefault();
                      e?.stopPropagation();
                      handleAddToFavorite(d._id, 'flashcards_v2');
                    }}
                  >
                    <img
                      src={
                        favoriteArr.includes(d._id) ? FilledHeart : BlankHeart
                      }
                    />
                  </Heart>
                </ImageWrapper>
              </Box>
            ))}
          <Line />
          {wordsTypes
            .filter((p) => p.position >= 100)
            .map((d) => (
              <Box to={`/learn/flashcards/${d._id}`}>
                <ImageWrapper>
                  <TopTitle>
                    <div>{d.name}</div>
                  </TopTitle>
                  <img
                    src={d?.image?.base64 || BackgroundDefault}
                    alt={d.name}
                  />
                  <ProgressBar progress={d.progress} maxProgress={d.max} />
                  <ImageOverlay />
                </ImageWrapper>
              </Box>
            ))}
          <BoxWrapper>
            <Box onClick={() => setShowNewCategoryPopUp(() => true)}>
              <ImageWrapper>
                <TopTitle>
                  <img src={Plus} />
                  <div>{addCategory} </div>
                </TopTitle>
                <img src={BackgroundPlus} />
                <ImageOverlay />
              </ImageWrapper>
            </Box>
          </BoxWrapper>
          {Array.from(Array((wordsTypes.length + 3) % 4).keys()).map((b) => (
            <BoxWrapper>
              <ImageWrapper></ImageWrapper>
            </BoxWrapper>
          ))}
        </BoxWrapper>
      </Wrapper>
    </>
  );
};

export default FlashcardV2List;
