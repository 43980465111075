import Api from '../api';
const { axios: api } = Api;

const PREFIX = 'flashcard-v2-user-progress';

export const getFlashcardsV2ForCategory = async (payload) => {
  const data = await api.get(`/${PREFIX}/${payload._id}`);
  return { data: data.data };
};

export const handleGetVoiceForFlashcard = async (payload) => {
  const data = await api.post(`/${PREFIX}/generate-voice`, payload);
  return { data: data.data };
};

export const markFlahcardAsCustomerDecision = async (payload) => {
  const data = await api.post(`/${PREFIX}/mark-as-customer-decision`, payload);
  return { data: data.data };
};
